import {useRoot} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import LinkToTelegramScreen from '../../../screens/LinkToTelegramScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type PromptAccountDeletionBindingProps =
  SmallHomeStackBindingProps<'PromptLinkToTelegram'>;

export default observer(function PromptAccountDeletionBinding({
  navigation,
}: PromptAccountDeletionBindingProps) {
  const {
    configuration: {
      values: {telegramBotId, telegramOauthOrigin},
    },
  } = useRoot();
  const onClose = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
      return;
    }
    navigation.reset({
      index: 1,
      routes: [{name: 'Root'}, {name: 'ShowProfile'}],
    });
  }, [navigation]);

  return (
    <LinkToTelegramScreen
      testID="telegram_list_btn"
      telegramBotId={telegramBotId}
      telegramOauthOrigin={telegramOauthOrigin}
      redirectUriForTelegramLinking={telegramOauthOrigin}
      onClose={onClose}
    />
  );
});
