import type {LocaleKeys} from '@ncwallet-app/localization/locale/LocaleStrings';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';

import type {GlobalError} from '../Error';
import {TIMEOUT_ERROR} from '../Error';
import type {ErrorParser} from '../ErrorParser';
import type {ErrorRepository} from '../ErrorRepository';
import type {JsonRpcClient} from '../JsonRpc';
import type {Localization} from '../Localization';
import type {
  NCWalletCallScheme,
  NCWalletNotificationScheme,
} from '../NCWalletServer';
import type {SentryLog} from '../SentryLog';
import {createJsonErrorMessage} from '../SentryLog';
import type {
  MultipleTransactionReportsOptions,
  SingleTransactionReportOptions,
  TransactionReportHelper,
} from './TransactionReport';
import {ReportFileType} from './TransactionReport';

enum GenerateReportError {
  TransactionsNotFound = 4006,
  NoTransaction = 4007,
}

const BUY_LINK_ERROR_TO_LOCALE: Partial<
  Record<GenerateReportError, LocaleKeys>
> = {
  [GenerateReportError.TransactionsNotFound]:
    'walletStatement.errorNoTransactions',
  [GenerateReportError.NoTransaction]: 'walletStatement.errorNotrans',
};

export default class TransactionReportHelperImpl
  implements TransactionReportHelper
{
  @observable private _token: string | undefined = undefined;
  @observable private _isLoading = false;
  @observable private _error: string | undefined = undefined;

  constructor(
    readonly _root: {
      readonly errorRepository: ErrorRepository;
      readonly ncWalletJsonRpcClient: JsonRpcClient<
        NCWalletCallScheme,
        NCWalletNotificationScheme
      >;
      readonly errorParser: ErrorParser;
      readonly localization: Localization;
      readonly sentryLog: SentryLog;
    },
  ) {
    makeObservable(this);
  }

  @computed get isLoading() {
    return this._isLoading;
  }

  @computed get error() {
    return this._error;
  }

  @computed get token() {
    return this._token;
  }

  @action.bound
  async generateMultipleTransactionReports(
    options: MultipleTransactionReportsOptions,
  ): Promise<void> {
    runInAction(() => {
      this._isLoading = true;
      this._error = undefined;
    });
    const res = await this._root.ncWalletJsonRpcClient.call(
      'reports.transactions',
      {
        ...options,
      },
    );
    if (!res.success) {
      if (res.left.kind === TIMEOUT_ERROR) {
        this._root.sentryLog.write(
          createJsonErrorMessage('[reports.transactions]', options),
        );
      }
      this._handleError(res.left);
      this._isLoading = false;
      return;
    }

    runInAction(() => {
      this._token = res.right.token;
      this._isLoading = false;
    });
  }

  @action.bound
  async generateSingleTransactionReport(
    options: SingleTransactionReportOptions,
  ): Promise<void> {
    const {transactionId, fileType = ReportFileType.PDF} = options;

    runInAction(() => {
      this._isLoading = true;
      this._error = undefined;
    });

    const res = await this._root.ncWalletJsonRpcClient.call(
      'reports.transaction',
      {
        transaction_id: transactionId,
        file_type: fileType,
      },
    );

    if (!res.success) {
      if (res.left.kind === TIMEOUT_ERROR) {
        this._root.sentryLog.write(
          createJsonErrorMessage('[reports.transactions]', {
            transactionId,
            fileType,
          }),
        );
      }
      this._handleError(res.left);
      this._isLoading = false;
      return;
    }

    runInAction(() => {
      this._token = res.right.token;
      this._isLoading = false;
    });
  }

  @action.bound
  private _handleError(e: GlobalError) {
    const error = this._root.errorParser.describe(e);
    const localeKey =
      BUY_LINK_ERROR_TO_LOCALE[error.code as GenerateReportError];

    if (localeKey) {
      this._error = this._root.localization.getTranslation(localeKey);
    } else {
      this._error = this._root.localization.getTranslation(
        'walletStatement.errorCreate',
      );
    }
  }
}
