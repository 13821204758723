import {TouchableOpacity} from '@ncwallet-app/ui';
import {CloseEye} from '@ncwallet-app/ui/src/assets/svg/colored';
import React from 'react';
import {View} from 'react-native';

import useHideActionStyles from './useHideActionStyles';

export type HideActionProps = {
  onPress?: () => void;
};

export default function HideAction(props: HideActionProps) {
  const {onPress} = props;
  const styles = useHideActionStyles();
  return (
    <View style={styles.root}>
      <TouchableOpacity style={styles.touchable} onPress={onPress}>
        <CloseEye />
      </TouchableOpacity>
    </View>
  );
}
