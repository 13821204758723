import {useTheme} from '@ncwallet-app/core';
import {
  LIST_MENU_CATEGORIES_ROUTE,
  LIST_NOTIFICATIONS_ROUTE,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {
  BurgerSvg,
  CrossSvg,
  RoundShape,
} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo} from 'react';
import {StyleSheet} from 'react-native';

import {useIsHeaderModalsFocused} from '../hooks/useIsHeaderModalsFocused';
import type {ScreenHeaderWideProps} from '../hooks/useScreenHeaderWideProps';
import BurgerBottomView from './BurgerBottomView';
import BurgerMenuTouchable from './BurgerMenuTouchable';
import HeaderFrame from './HeaderFrame';

export type MainHeaderProps = ScreenHeaderWideProps;

export default observer(function MainHeader(props: MainHeaderProps) {
  const {focusedMenu, toggleMenu} = props;
  const theme = useTheme();

  const {areNotificationsVisible, isMenuVisible} =
    useIsHeaderModalsFocused(focusedMenu);
  const toggleNotifications = useCallback(() => {
    toggleMenu(LIST_NOTIFICATIONS_ROUTE);
  }, [toggleMenu]);
  const toggleMenuCategories = useCallback(() => {
    toggleMenu(LIST_MENU_CATEGORIES_ROUTE);
  }, [toggleMenu]);

  const tail = useMemo(
    () => (
      <BurgerMenuTouchable
        onPress={toggleMenuCategories}
        active={isMenuVisible}
        testID="main-burger-btn">
        {isMenuVisible ? (
          <CrossSvg color={theme.palette.uiMain} />
        ) : (
          <BurgerSvg color={theme.palette.uiMain} />
        )}
        {isMenuVisible && (
          <BurgerBottomView>
            <RoundShape
              color={theme.palette.uiPrimary}
              style={styles.roundIcon}
            />
          </BurgerBottomView>
        )}
      </BurgerMenuTouchable>
    ),
    [isMenuVisible, theme, toggleMenuCategories],
  );

  return (
    <HeaderFrame
      areNotificationsVisible={areNotificationsVisible}
      tail={tail}
      toggleNotifications={toggleNotifications}
      {...props}
    />
  );
});

const styles = StyleSheet.create({
  roundIcon: {
    position: 'absolute',
    top: 5,
    left: -5,
    bottom: 0,
  },
});
