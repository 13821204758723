import type {Millisecond} from '@ncwallet-app/core';
import {useStrings, useTheme, variance} from '@ncwallet-app/core';
import {
  LG_BREAKPOINT,
  Pressable,
  TouchableListItem,
  useIsDimensions,
} from '@ncwallet-app/ui/src';
import {
  ArrowDownWideSvg,
  ArrowRightSvg,
  CalendarSvg,
} from '@ncwallet-app/ui/src/assets/svg/colorless';
import dayjs from 'dayjs';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {Text, View} from 'react-native';

export type HistoryCalendarItemProps = {
  goToDateSelection: () => void;
  from?: Millisecond;
  to?: Millisecond;
  testID?: string;
};

export default observer(function HistoryCalendarItem({
  goToDateSelection,
  from,
  to,
  testID,
}: HistoryCalendarItemProps) {
  const isLg = useIsDimensions('lg');
  const theme = useTheme();
  const strings = useStrings();
  const CalendarIcon = useCallback(() => {
    return (
      <Container>
        <CalendarSvg width={40} height={40} color={theme.palette.icon3} />
      </Container>
    );
  }, [theme]);
  return (
    <>
      <Pressable dataSet={{['app']: 'history-filter-item'}} testID={testID}>
        <Item
          Icon={isLg ? ArrowDownWideImg : ArrowRightSvg}
          onPress={goToDateSelection}>
          <CalendarIcon />
          <Block>
            <Title>
              {from !== undefined && to !== undefined
                ? `${formatDate(from)}-${formatDate(to)}`
                : from !== undefined
                  ? `>${formatDate(from)}`
                  : to !== undefined
                    ? `<${formatDate(to)}`
                    : strings['historyPeriodFilterScreen.default']}
            </Title>
          </Block>
        </Item>
      </Pressable>
    </>
  );
});

const formatDate = (_: Millisecond) => dayjs(_).format('DD.MM.YYYY');

const Item = variance(TouchableListItem)(theme => ({
  root: {
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.uiSecondary,
    paddingHorizontal: 15,
    paddingVertical: 15,

    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        borderWidth: 1,
        borderColor: theme.palette.uiSecondary,
        borderRadius: 8,
        paddingVertical: 10,
      },
    }),
  },

  expand: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
}));

const Block = variance(View)(() => ({
  root: {
    marginLeft: 15,
    marginRight: 15,
  },
}));

const Title = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    fontSize: 14,
    lineHeight: 17,
  },
}));

const Container = variance(View)(theme => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
    borderWidth: 1,
    borderColor: theme.palette.uiSecondary,
    borderRadius: 50,
    backgroundColor: theme.palette.background,
  },
  flat: {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
  },
}));

const ArrowDownWideImg = variance(ArrowDownWideSvg)(
  () => ({
    root: {},
  }),
  () => ({
    width: 22,
    height: 22,
  }),
);
