import {useRoot, useStrings} from '@ncwallet-app/core';
import type {SmallBottomTabParamList} from '@ncwallet-app/core/src/SmallNavigationScheme/SmallHomeStack/SmallBottomTab/SmallBottomTabParamList';
import {RootScreenHeader} from '@ncwallet-app/ui';
import {
  DebugTabSvg,
  ExchangeTabSvg,
  HistoryTabSvg,
  ReceiveTabSvg,
  SendTabSvg,
  WalletsTabSvg,
} from '@ncwallet-app/ui/src/assets/svg/colorless';
import UserStateErrorSm from '@ncwallet-app/ui/src/components/organisms/UserStateError/UserStateErrorSm';
import type {
  BottomTabBarProps,
  BottomTabHeaderProps,
} from '@react-navigation/bottom-tabs';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import {useIsFocused} from '@react-navigation/native';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useMemo} from 'react';
import type {SvgProps} from 'react-native-svg';

import {useTabBarContext} from '../../../../components';
import {isChromeExtension} from '../../../../Navigation/hooks/isChromeExtension';
import {useIsExchangeAllowed} from '../../../../Navigation/hooks/useIsExchangeAllowed';
import type {SmallHomeStackBindingProps} from '../../SmallHomeStackBindingProps';
import DebugBinding from './bindings/DebugBinding';
import ListHistoryBinding from './bindings/ListHistoryBinding';
import ListWalletsBinding from './bindings/ListWalletsBinding';
import PromptCryptoToReceiveBinding from './bindings/PromptCryptoToReceiveBinding';
import PromptCryptoToSendBinding from './bindings/PromptCryptoToSendBinding';
import PromptSourceCryptoToExchangeBinding from './bindings/PromptSourceCryptoToExchangeBinding';

const {Navigator, Screen} = createBottomTabNavigator<SmallBottomTabParamList>();

export type SmallBottomTabsBindingProps = SmallHomeStackBindingProps<'Root'>;

export default observer(function SmallBottomTabsBinding(
  props: SmallBottomTabsBindingProps,
) {
  const {navigation} = props;
  const root = useRoot();
  const isFocused = useIsFocused();
  const {open, visible} = useTabBarContext();
  useEffect(() => {
    if (isFocused && !visible) {
      open();
    }
  }, [isFocused, open, visible]);
  const goToUserSettings = useCallback(() => {
    navigation.navigate('ListMenuCategories');
  }, [navigation]);
  const goToNotifications = useCallback(() => {
    navigation.navigate('ListNotifications');
  }, [navigation]);
  const showQrCodeButton = !isChromeExtension() || root.appViewport.isPopup;
  const goToScanQrCode = useCallback(async () => {
    await root.qrCodeScanner.open();
  }, [root]);
  const onTitlePress = useCallback(() => {
    navigation.navigate('ListPersonalData');
  }, [navigation]);
  const expandExtension = useCallback(async () => {
    await root.appViewport.expand();
  }, [root]);
  const strings = useStrings();
  const {debug, userStatusErrorDetector, platformSpecificComponents} = root;

  const {AnimatedBottomTabBar} = platformSpecificComponents;

  const {isExchangeAllowed} = useIsExchangeAllowed();

  const screenOptions = useMemo(
    () => ({
      headerShown: true,
      header: ({options, route}: BottomTabHeaderProps) => {
        const routeName = route.name;
        const customTitle = routeName === 'ListWallets' &&
          userStatusErrorDetector.userStatusError && (
            <UserStateErrorSm onTitlePress={onTitlePress} />
          );
        return (
          <RootScreenHeader
            title={
              typeof options.tabBarLabel === 'string' ? options.tabBarLabel : ''
            }
            customTitle={customTitle}
            unreadNotificationsTotal={0}
            onBurgerPress={goToUserSettings}
            onNotificationsPress={goToNotifications}
            showQrCodeButton={showQrCodeButton}
            onQrCodePress={goToScanQrCode}
            onExpandPress={expandExtension}
          />
        );
      },
    }),
    [
      expandExtension,
      goToNotifications,
      goToScanQrCode,
      goToUserSettings,
      onTitlePress,
      showQrCodeButton,
      userStatusErrorDetector.userStatusError,
    ],
  );
  const tabBar = useCallback(
    (_: BottomTabBarProps) => <AnimatedBottomTabBar {..._} />,
    [AnimatedBottomTabBar],
  );

  return (
    <Navigator
      screenOptions={screenOptions}
      tabBar={tabBar}
      initialRouteName="ListWallets"
      backBehavior="history">
      <Screen
        name="ListHistory"
        component={ListHistoryBinding}
        options={{
          tabBarIcon: tabBarIcon(HistoryTabSvg),
          tabBarLabel: strings['bottomTab.history'],
          title: strings['screenBaseTitle.history'],
        }}
      />
      <Screen
        name="PromptCryptoToReceive"
        component={PromptCryptoToReceiveBinding}
        options={{
          tabBarIcon: tabBarIcon(ReceiveTabSvg),
          tabBarLabel: strings['bottomTab.receive'],
          title: strings['screenBaseTitle.receive'],
        }}
      />
      {isExchangeAllowed && (
        <Screen
          name="PromptSourceCryptoToExchange"
          component={PromptSourceCryptoToExchangeBinding}
          options={{
            tabBarIcon: tabBarIcon(ExchangeTabSvg),
            tabBarLabel: strings['bottomTab.exchange'],
            title: strings['screenBaseTitle.exchange'],
          }}
        />
      )}
      <Screen
        name="PromptCryptoToSend"
        component={PromptCryptoToSendBinding}
        options={{
          tabBarIcon: tabBarIcon(SendTabSvg),
          tabBarLabel: strings['bottomTab.send'],
          title: strings['screenBaseTitle.sendCrypto'],
        }}
      />
      <Screen
        name="ListWallets"
        component={ListWalletsBinding}
        options={{
          tabBarIcon: tabBarIcon(WalletsTabSvg),
          tabBarLabel: strings['bottomTab.wallets'],
          title: `${strings['bottomTab.wallets']} | NC Wallet`,
        }}
      />
      {debug.debugEnabled && (
        <Screen
          name="Debug"
          component={DebugBinding}
          options={{tabBarIcon: tabBarIcon(DebugTabSvg)}}
        />
      )}
    </Navigator>
  );
});

const tabBarIcon =
  (Icon: React.ComponentType<SvgProps>) =>
  (props: {focused: boolean; color: string; size: number}) => (
    <Icon width={props.size} color={props.color} />
  );
