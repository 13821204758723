import {useRoot, useStrings} from '@ncwallet-app/core';
import {useAccountState} from '@ncwallet-app/core/src/AccountStore';
import type {ThemeKind} from '@ncwallet-app/core/src/Appearance';
import type {AvailableLanguageCode} from '@ncwallet-app/core/src/Localization/constant';
import {TouchableWithoutFeedback} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import {
  LanguageSettingsList,
  LgUserSettingsList,
} from '../../../../../components';
import HeaderDropdown from './WalletLayout/HeaderDropdown';
import HeaderDropdownBack from './WalletLayout/HeaderDropdownBack';
import HeaderDropdownTop from './WalletLayout/HeaderDropdownTop';
import ThemeSelect from './WalletLayout/ThemeSelect';

export type HeaderProfileMenuProps = {
  isThemeModalFocused: boolean;
  isLanguageModalFocused: boolean;
  toggleThemeMenu: () => void;
  toggleLanguageMenu: () => void;
  onNotificationSettings?: () => void;
  onProfileSettings: () => void;
  onLogoutPress: () => void;
  onRatePress: () => void;
  onHelpPress: () => void;
  onAffiliatePress: () => void;
  onSecurityPress: () => void;
  onLimitPress: () => void;
};

export default observer(function HeaderProfileMenu(
  props: HeaderProfileMenuProps,
) {
  const {
    isThemeModalFocused,
    isLanguageModalFocused,
    toggleThemeMenu,
    toggleLanguageMenu,
    onAffiliatePress,
    onNotificationSettings,
    onProfileSettings,
    onLogoutPress,
    onRatePress,
    onHelpPress,
    onSecurityPress,
    onLimitPress,
  } = props;
  const strings = useStrings();
  const {appearance, userPreferenceState} = useRoot();
  const {account} = useAccountState();

  const handleSetTheme = useCallback(
    async (theme: ThemeKind) => {
      await appearance.setThemeMode(theme);
      toggleThemeMenu();
    },
    [appearance, toggleThemeMenu],
  );

  const onLanguageSelect = useCallback(
    (language: AvailableLanguageCode) => {
      void userPreferenceState.setLanguageCode(language);
      toggleLanguageMenu();
    },
    [toggleLanguageMenu, userPreferenceState],
  );

  return (
    <HeaderDropdown isVisible>
      {isThemeModalFocused ? (
        <>
          <HeaderDropdownBack
            onPress={toggleThemeMenu}
            title={strings['themeSelect.title']}
          />
          <ThemeSelect
            currentTheme={appearance.preferredThemeKind}
            onChangeTheme={handleSetTheme}
          />
        </>
      ) : isLanguageModalFocused ? (
        <>
          <HeaderDropdownBack
            onPress={toggleLanguageMenu}
            title={strings['screenTitle.languageSettings']}
          />
          <LanguageSettingsList
            activeItem={userPreferenceState.languageCode}
            onLanguagePress={onLanguageSelect}
          />
        </>
      ) : (
        <>
          <TouchableWithoutFeedback onPress={onProfileSettings}>
            <HeaderDropdownTop text={account?.name || strings['noNickname']} />
          </TouchableWithoutFeedback>
          <LgUserSettingsList
            onAffiliatePress={onAffiliatePress}
            goToStyle={toggleThemeMenu}
            onLanguagePress={toggleLanguageMenu}
            onProfilePress={onProfileSettings}
            onSecurityPress={onSecurityPress}
            onLimitsPress={onLimitPress}
            onHelpPress={onHelpPress}
            onRatePress={onRatePress}
            onNotificationPress={onNotificationSettings}
            onLogoutPress={onLogoutPress}
            showRate
          />
        </>
      )}
    </HeaderDropdown>
  );
});
