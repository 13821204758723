import type {Palette} from './Coloring';

const palette: Palette = {
  background: '#ffffff',
  foreground: '#000000',

  primary: '#F7931A',
  info: '#4285F4',
  error: '#F74249',
  success: '#37CC33',
  attention: '#F7C21A',
  blackout: '#000000',
  whitey: '#ffffff',

  textMain: '#000000',
  textPrimary: '#171717',
  textSecondary: '#212121',
  textAdditional1: '#494949',
  textAdditional2: '#757575',
  textAdditional3: '#B3B3B3',

  uiSystem: '#404040',
  uiMain: '#757575',
  uiAdditional1: '#B3B3B3',
  uiSecondary: '#D6D6D6',
  uiPrimary: '#F7F7F7',
  uiAdditional2: '#FFFFFF',

  mainGradientStart: '#ffffff',
  mainGradientEnd: '#efefef',

  orangeGradientEnd: '#EC880F',

  blueGradientStart: '#085BE4',
  blueGradientEnd: '#4285F4',

  mainCardGradientStart: '#FFFFFF',
  mainCardGradientEnd: '#EFEFEF',
  mainCardBackgroundStart: '#000000',
  mainCardBackgroundEnd: '#494949',
  mainCardAdditional1: '#4285F4',
  mainCardAdditional2: '#F7931A',
  mainCardPrimary: '#FFFFFF',
  mainCardSecondary: '#EBEBEB',

  additional1: '#aacaff',
  additional2: '#ffdfB8',
  additional3: '#E1EDFF',
  additional4: '#fffaf3',
  additional5: '#fff3f4',
  additional6: '#E0FDDF',

  icon1: '#757575',
  icon2: '#000000',
  icon3: '#171717',
};

export default palette;
