import {useRoot, useStrings, variance} from '@ncwallet-app/core';
import {
  Button,
  ButtonColor,
  ButtonVariant,
  LG_BREAKPOINT,
  TouchableOpacity,
  useIsDimensions,
} from '@ncwallet-app/ui';
import {
  AddWalletDarkSvg,
  AddWalletSvg,
} from '@ncwallet-app/ui/src/assets/svg/colored';
import {CoinSvg, PlusSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {StyleSheet, Text, View} from 'react-native';

export type EmptyWalletListProps = {
  title?: string;
  description?: string;
  addButtonText?: string;
  onAddPress: () => void;
  onBuyCryptoPress?: () => void;
  onReceivePress?: () => void;
};

export default observer(function EmptyWalletList(props: EmptyWalletListProps) {
  const {
    title,
    description,
    addButtonText,
    onAddPress,
    onReceivePress,
    onBuyCryptoPress,
  } = props;
  const {appearance} = useRoot();
  const strings = useStrings();
  const isLg = useIsDimensions('lg');
  const iconStyles = StyleSheet.create({
    small: {
      width: 70,
      height: 70,
    },
    large: {
      width: 100,
      height: 100,
    },
  });
  const iconStyle = isLg ? iconStyles.small : iconStyles.large;

  const defaultAddButtonText = onBuyCryptoPress
    ? strings['welcomeScreen.createWallet']
    : strings['emptyWallets.button'];

  return (
    <Container>
      <TouchableOpacity onPress={onAddPress}>
        {appearance.isDark ? (
          <AddWalletDarkSvg style={iconStyle} testID="add_coin_icon" />
        ) : (
          <AddWalletSvg style={iconStyle} />
        )}
      </TouchableOpacity>
      <Title>{title || strings['welcome.title']}</Title>
      <Description>{description || strings['welcome.desc']}</Description>
      {onReceivePress && (
        <ReceiveBtn
          title={strings['currencyScreen.walletAction.receiveTitle']}
          onPress={onReceivePress}
          Icon={PlusSvg}
          color={ButtonColor.Success}
          variant={ButtonVariant.Highlighted}
        />
      )}
      {onBuyCryptoPress && (
        <Btn
          testID="buy_currency_btn"
          title={addButtonText || strings['welcome.buyCryptoButton']}
          onPress={onBuyCryptoPress}
          Icon={CoinSvg}
          variant={ButtonVariant.Primary}
        />
      )}

      <Btn
        testID="create_wallet_btn"
        title={addButtonText || defaultAddButtonText}
        onPress={onAddPress}
        Icon={PlusSvg}
        variant={
          onBuyCryptoPress ? ButtonVariant.PrimaryLight : ButtonVariant.Primary
        }
      />
    </Container>
  );
});

const Container = variance(View)(() => ({
  root: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 15,
  },
}));

const Title = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 20,
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 15,
    color: theme.palette.textMain,
  },
}));

const Description = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    textAlign: 'center',
    color: theme.palette.textAdditional2,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingBottom: 10,
      },
    }),
  },
}));

const Btn = variance(Button)(theme => ({
  root: {
    width: '100%',
    marginTop: 20,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        marginTop: 10,
      },
    }),
  },
}));

const ReceiveBtn = variance(Btn)(theme => ({
  root: {
    marginTop: 20,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        marginTop: 10,
      },
    }),
  },
}));
