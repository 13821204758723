import {createErrorClass} from '../Error';

export const MaintenanceError = createErrorClass('MaintenanceError', {
  message: 'The server is under maintenance',
});

export const InternalError = createErrorClass('InternalError', {
  message: 'An internal server error occurred',
});

export const UnknownServerError = createErrorClass('UnknownServerError', {
  message: 'An unknown server error occurred',
});
