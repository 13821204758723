import type {GeneralRestClientError, TimeoutError} from '../Error';
import {GENERAL_REST_CLIENT_ERROR, TIMEOUT_ERROR} from '../Error';
import type {ErrorRepository} from '../ErrorRepository';
import {failure, success} from '../fp';
import type {Http} from '../Http';
import type {Json, JsonSerializable} from '../Json';
import type {Maybe} from '../Maybe';
import type {Millisecond} from '../Time';
import type {Url} from '../units';
import delayResolve from '../util/delayResolve';
import type {RestMethod, RestOptions} from './RestClient';

export default abstract class BaseRestClientImpl {
  protected constructor(
    protected readonly _root: {
      readonly errorRepository: ErrorRepository;
      readonly json: Json;
      readonly http: Http;
    },
  ) {}

  protected abstract get _base(): Url;

  protected abstract get _timeout(): Millisecond;

  protected async _call<
    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    R extends JsonSerializable | void = JsonSerializable | void,
  >(
    method: RestMethod,
    endpoint: Url,
    params?: JsonSerializable,
    options?: RestOptions,
  ): Promise<Maybe<R>> {
    let body;
    if (params) {
      const body_ = this._root.json.stringify(params);
      if (!body_.success) {
        return body_;
      }
      body = body_.right;
    }
    const fetchPromise = this._root.http.fetch(`${this._base}${endpoint}`, {
      method,
      body,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...(options && options.headers ? options.headers : {}),
      },
    });
    const response_ = !isFinite(this._timeout)
      ? await fetchPromise
      : await Promise.race([
          fetchPromise,
          delayResolve(this._timeout, () =>
            failure(
              this._root.errorRepository.create<TimeoutError>({
                kind: TIMEOUT_ERROR,
                description: `REST ${method} ${endpoint} failed with timeout`,
              }),
            ),
          ),
        ]);
    if (!response_.success) {
      return response_;
    }
    const response = response_.right;
    let responseBody;
    try {
      responseBody = (await response.json()) as JsonSerializable;
    } catch {
      /* empty */
    }
    if (response.ok) {
      return success(responseBody as R);
    }
    return failure(
      this._root.errorRepository.create<GeneralRestClientError>({
        kind: GENERAL_REST_CLIENT_ERROR,
        description: `The REST method ${endpoint} failed with the code ${response.status}`,
        statusCode: response.status,
        body: responseBody,
      }),
    );
  }
}
