import type {LocaleKeys} from '@ncwallet-app/localization/locale/LocaleStrings';
import type {ValueOf} from 'type-fest';

export type GroupMessageDescription = {
  auth: {
    title: LocaleKeys;
    messages: Record<
      | '2fa_enabled'
      | '2fa_disabled'
      | '2fa_settings_updated'
      | 'login_success'
      | 'login_new_ip',
      LocaleKeys
    >;
  };
  settings: {
    title: LocaleKeys;
    messages: Record<
      'withdrawal_limit' | 'blocked_ip_manage' | 'allowed_ip_manage',
      LocaleKeys
    >;
  };
  transactions: {
    title: LocaleKeys;
    messages: Record<
      | 'wallet_deposit'
      | 'withdrawal_request'
      | 'withdrawal_completed'
      | 'exchange_completed',
      LocaleKeys
    >;
  };
};

export type GroupMessageIdsMap = {
  [P in keyof GroupMessageDescription]: keyof GroupMessageDescription[P]['messages'];
};

export type GroupId = keyof GroupMessageIdsMap;

export type MessageId = ValueOf<GroupMessageIdsMap>;

export enum LogLevel {
  CriticalL = 50,
  Error = 40,
  Warning = 30,
  Info = 20,
  Debug = 10,
  NotSet = 0,
}
