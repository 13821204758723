import type {CurrencyDescription} from '@ncwallet-app/core';
import type {
  RouteParams,
  ShowProfileRoute,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import type {AccountVerificationProgress} from '@ncwallet-app/core/src/NCWalletServer/AccountsData';
import type {BaseSafeAreaProps} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';

import ProfileSettingsList from './ProfileSettingsList';

export type ProfileSettingsScreenProps = BaseSafeAreaProps & {
  getUser: () => ProfileUser | undefined;
  onDisplayNamePress: (value: string) => void;
  onUserIdPress: (value: string) => void;
  onEmailPress: (value: string) => void;
  onLinkToEmailPress: () => void;
  onLinkToTelegramPress: () => void;
  onIdentityPress: () => void;
  onBaseCryptoPress: () => void;
  onBaseFiatPress: () => void;
  onDeletePress: () => void;
  verificationProgress: AccountVerificationProgress | undefined;
  params: RouteParams<ShowProfileRoute>;
  testID?: string;
};

export default observer(function ProfileSettingsScreen(
  props: ProfileSettingsScreenProps,
) {
  const {
    getUser,
    onDisplayNamePress,
    onUserIdPress,
    onEmailPress,
    onLinkToEmailPress,
    onBaseCryptoPress,
    onBaseFiatPress,
    testID,
    ...rest
  } = props;

  return (
    <ProfileSettingsList
      testID={testID}
      getUser={getUser}
      onDisplayNamePress={onDisplayNamePress}
      onUserIdPress={onUserIdPress}
      onEmailPress={onEmailPress}
      onLinkToEmailPress={onLinkToEmailPress}
      onBaseCryptoPress={onBaseCryptoPress}
      onBaseFiatPress={onBaseFiatPress}
      {...rest}
    />
  );
});

export type ProfileUser = {
  userId: string;
  displayName: string;
  email: string;
  telegramUsername: string;
  identify: string;
  baseCryptoCurrency: CurrencyDescription;
  defaultFiatCurrency: CurrencyDescription;
  isEmailConfirmed: boolean;
  isIdentityVerify: boolean;
  isVerified?: boolean;
  isVerificationRequired?: boolean;
};
