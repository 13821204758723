import {action, makeObservable, observable} from 'mobx';

import type {CancellablePromiseEither} from '../CancellablePromise';
import type {CancellationError, GlobalError, TimeoutError} from '../Error';
import {CANCELLATION_ERROR, TIMEOUT_ERROR} from '../Error';
import type {ErrorRepository} from '../ErrorRepository';
import type {EventLoopHelper} from '../EventLoop';
import type {Either} from '../fp';
import {error, success} from '../fp';
import {RouterHelperImpl, RouterImpl} from '../structure';
import type {Millisecond} from '../Time';
import type {
  AttemptProvider,
  AttemptResult,
  RetryStrategy,
  RetryStrategyResult,
  RetryStrategyState,
  RetryStrategyStateMap,
  RetryStrategyStatus,
} from './RetryStrategy';
import {
  ATTEMPT_RESULT_DONE,
  RETRY_STRATEGY_CANCELED,
  RETRY_STRATEGY_GAVE_UP,
  RETRY_STRATEGY_IDLE,
  RETRY_STRATEGY_PENDING,
  RETRY_STRATEGY_SUCCEEDED,
} from './RetryStrategy';

export default class IntervalRetryStrategyImpl
  implements RetryStrategy, RetryStrategyState
{
  private readonly _state = new RouterImpl<RetryStrategyStateMap>();
  private readonly _stateHelper;
  @observable private _latestStatus: RetryStrategyStatus = RETRY_STRATEGY_IDLE;
  @observable private _latestResult?: RetryStrategyResult;
  @observable private _previousResult?: RetryStrategyResult;

  constructor(
    private readonly _root: {
      readonly errorRepository: ErrorRepository;
      readonly eventLoopHelper: EventLoopHelper;
    },
    private readonly _attemptProvider: AttemptProvider,
    public readonly attemptTimeout = Infinity as Millisecond,
    public readonly attemptInterval = 0 as Millisecond,
    public readonly attemptCount = Infinity,
  ) {
    makeObservable(this);
    this._stateHelper = new RouterHelperImpl(this._root, this._state);
  }

  get state(): RetryStrategy['state'] {
    return this._state;
  }

  get latestStatus() {
    return this._latestStatus;
  }

  get latestResult() {
    return this._latestResult;
  }

  get previousResult() {
    return this._previousResult;
  }

  @action private _setPending() {
    this._latestStatus = RETRY_STRATEGY_PENDING;
    this._state.send(RETRY_STRATEGY_PENDING);
  }

  @action private _setIdle(result: RetryStrategyResult) {
    this._latestStatus = RETRY_STRATEGY_IDLE;
    this._previousResult = this._latestResult;
    this._latestResult = result;
    this._state.send(RETRY_STRATEGY_IDLE, result);
  }

  private _createCancellationError() {
    return error(
      this._root.errorRepository.create<CancellationError>({
        kind: CANCELLATION_ERROR,
      }),
    );
  }

  private _createTimeoutError() {
    return error(
      this._root.errorRepository.create<TimeoutError>({
        kind: TIMEOUT_ERROR,
      }),
    );
  }

  private async _scheduleAttempt(
    timeout: Millisecond,
    attempt?: Promise<AttemptResult>,
  ): Promise<Either<AttemptResult, GlobalError>> {
    const promises: Promise<Either<AttemptResult, GlobalError>>[] = [];
    const idle = this._stateHelper.when(RETRY_STRATEGY_IDLE);
    promises.push(idle.then(() => this._createCancellationError()));
    if (attempt) {
      promises.push(attempt.then(_ => success(_)));
    }
    let timeoutPromise: CancellablePromiseEither<void, GlobalError> | undefined;
    if (isFinite(timeout)) {
      timeoutPromise = this._root.eventLoopHelper.delay(timeout);
      promises.push(
        timeoutPromise.then(_ => (_.success ? this._createTimeoutError() : _)),
      );
    }
    const result_ = await Promise.race(promises);
    idle.cancel();
    timeoutPromise?.cancel();
    return result_;
  }

  private async _scheduleTimeout(
    timeout: Millisecond,
  ): Promise<Either<void, GlobalError>> {
    if (timeout <= 0) {
      return success();
    }
    const idle = this._stateHelper.when(RETRY_STRATEGY_IDLE);
    const promises: Promise<Either<void, GlobalError>>[] = [
      idle.then(() => this._createCancellationError()),
      this._root.eventLoopHelper.delay(timeout),
    ];
    const result_ = await Promise.race(promises);
    idle.cancel();
    return result_;
  }

  private async _start() {
    if (this._latestStatus === RETRY_STRATEGY_PENDING) {
      return this._stateHelper.when(RETRY_STRATEGY_IDLE);
    }
    this._setPending();
    for (let i = 0; i < this.attemptCount; ++i) {
      const attempt_ = await this._scheduleAttempt(
        this.attemptTimeout,
        this._attemptProvider.attempt(),
      );
      if (attempt_.success && attempt_.right === ATTEMPT_RESULT_DONE) {
        this._setIdle(RETRY_STRATEGY_SUCCEEDED);
        return;
      }
      if (!attempt_.success && attempt_.left.kind === CANCELLATION_ERROR) {
        return;
      }
      if (this.attemptInterval > 0 && i < this.attemptCount - 1) {
        const timeout_ = await this._scheduleTimeout(this.attemptInterval);
        if (!timeout_.success && timeout_.left.kind === CANCELLATION_ERROR) {
          return;
        }
      }
    }
    this._setIdle(RETRY_STRATEGY_GAVE_UP);
  }

  start() {
    void this._start();
  }

  stop() {
    this._setIdle(RETRY_STRATEGY_CANCELED);
  }

  @action
  revertPending() {
    if (this._latestStatus !== RETRY_STRATEGY_PENDING) {
      return;
    }
    this._latestStatus = RETRY_STRATEGY_IDLE;
    this._state.send(RETRY_STRATEGY_IDLE, this._latestResult);
  }

  @action reset() {
    this._latestStatus = RETRY_STRATEGY_IDLE;
    this._latestResult = undefined;
    this._previousResult = undefined;
  }
}
