import type {ReadonlyNavigationContainerState} from '../..';
import type {AnalyticsNotification} from '../AnalyticsNotification';
import {EventType} from '../AnalyticsNotification';
import type {AuthStackNavigation} from '../AuthStackNavigation';
import {stateToPath} from '../CommonNavigationScheme';
import {
  RETRY_STRATEGY_GAVE_UP,
  RETRY_STRATEGY_IDLE,
  type RetryStrategy,
} from '../JsonRpc';
import type {Service} from '../structure';
import type {Url} from '../units';
import {last} from '../util';

class RetryStrategyGaveUpAnalyticsServiceImpl implements Service {
  constructor(
    private readonly _root: {
      readonly analyticsNotificationService: AnalyticsNotification;
      readonly retryStrategy: RetryStrategy;
      readonly navigationContainerState: ReadonlyNavigationContainerState;
      readonly authStackNavigation: AuthStackNavigation;
    },
  ) {}

  subscribe() {
    return this._root.retryStrategy.state.domain.listen(async event => {
      if (
        event.theme === RETRY_STRATEGY_IDLE &&
        last(event.args) === RETRY_STRATEGY_GAVE_UP
      ) {
        const authStackRoute =
          this._root.authStackNavigation.getAuthStackRoute();
        const navigationRoute = this._root.navigationContainerState.latestState
          ? stateToPath(
              this._root.navigationContainerState.latestState,
            ).toString()
          : undefined;
        const _url = (authStackRoute ?? navigationRoute ?? '') as Url;

        this._root.analyticsNotificationService.addEvent({
          name: EventType.SocketUnreachable,
          meta: {url: _url},
        });
      }
    });
  }
}

export default RetryStrategyGaveUpAnalyticsServiceImpl;
