import {variance} from '@ncwallet-app/core';
import {Button, Input, LG_BREAKPOINT, Pressable} from '@ncwallet-app/ui/src';
import {ArrowLeftSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {ScrollView, StyleSheet, Text, View} from 'react-native';

export default function useCardStyles() {
  return styles;
}

export const LgProfileCardContainer = variance(ScrollView)(
  theme => ({
    root: {
      flex: 1,
      backgroundColor: theme.palette.background,
      borderRadius: 8,
      maxWidth: 380,
      maxHeight: 565,
      minHeight: 320,
      ...theme.bar(10),
    },

    horizontalOffset: {
      marginHorizontal: 20,
      marginVertical: 0,
    },

    lightning: {
      borderWidth: 1,
      borderColor: theme.palette.primary,
      shadowColor: theme.palette.primary,
      shadowOffset: {
        width: 0,
        height: 4,
      },
      shadowOpacity: 0.3,
      shadowRadius: 16,
    },
  }),
  () => ({
    contentContainerStyle: {
      flex: 1,
    },
  }),
);

export const HeaderTag = variance(View)(theme => ({
  root: {
    minHeight: 60,
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.uiSecondary,
    flexDirection: 'row',
    alignItems: 'center',
  },
  smPadding: {
    paddingVertical: 12,
  },
}));

export const HeaderTitleTag = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 16,
    lineHeight: 19,
    color: theme.palette.textPrimary,
  },
  disabled: {
    color: theme.palette.uiMain,
  },
  offsetRight: {
    marginLeft: 10,
  },
  offsetLeft: {
    marginRight: 10,
  },
}));

export const BodyTag = variance(View)(theme => ({
  root: {
    padding: 20,
    flex: 1,
  },
  noPadding: {
    padding: 0,
  },
  smHidePadding: {
    padding: 0,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        padding: 20,
      },
    }),
  },
}));

export const CardButtonTag = variance(Button)(() => ({
  root: {
    marginTop: 25,
    height: 48,
  },
}));

const styles = StyleSheet.create({
  offsetTopLg: {
    marginTop: 25,
  },

  offsetTopSm: {
    marginTop: 10,
  },
});

export const LabelTag = variance(Text)(theme => ({
  root: {
    marginBottom: 10,
    marginTop: 25,
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    fontSize: 14,
    lineHeight: 16,
  },
  disabled: {
    color: theme.palette.uiMain,
  },
}));

export const InfoTextTag = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    fontSize: 14,
    lineHeight: 18,
    color: theme.palette.textAdditional2,
  },

  disabled: {
    color: theme.palette.uiMain,
  },
  danger: {
    color: theme.palette.error,
  },
  noTop: {
    marginTop: 0,
  },
  withTop: {
    marginTop: 15,
  },
  withBottom: {
    marginBottom: 15,
  },
}));

export const StepText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    color: theme.palette.textAdditional1,
    fontSize: 14,
    lineHeight: 17,
    marginLeft: 'auto',
  },
  disabled: {
    color: theme.palette.uiMain,
  },
}));

export const DisabledInput = variance(Input)(theme => ({
  root: {
    backgroundColor: theme.palette.uiPrimary,
    color: theme.palette.uiMain,
  },
}));

export const TagContainer = variance(View)(theme => ({
  root: {
    backgroundColor: theme.palette.error,
    borderRadius: 4,
    marginLeft: 10,
    justifyContent: 'center',
    paddingHorizontal: 5,
    paddingVertical: 2,
  },
  success: {
    backgroundColor: theme.palette.success,
  },
  error: {
    backgroundColor: theme.palette.blackout,
  },
}));

export const Tag = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.whitey,
    fontSize: 12,
    lineHeight: 15,
  },
  disabled: {
    opacity: 0.5,
  },
}));

export const Column = variance(View)(() => ({
  root: {
    alignItems: 'flex-start',
  },
}));

export const Row = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

export const Divider = variance(View)(theme => ({
  root: {
    height: 1,
    backgroundColor: theme.palette.uiSecondary,
  },
}));

export const ClickableTag = variance(Pressable)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

export const ClickableIcon = variance(ArrowLeftSvg)(theme => ({
  root: {
    marginRight: 10,
    color: theme.palette.uiMain,
  },
}));

export const DeleteIpText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    fontSize: 14,
    color: theme.palette.textPrimary,
  },
}));

export const DeleteIpActionContainer = variance(View)(() => ({
  root: {
    marginTop: 'auto',
  },
}));
