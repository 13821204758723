import {nanoid} from 'nanoid';

import type {
  Disposer,
  ErrorRepository,
  Http,
  JsonKeyValueMap,
  JsonKeyValueStore,
  Url,
} from '../..';
import {type AccountStore, getAccountFromState} from '../AccountStore';
import type {AnalyticRestParamsProvider} from '../AnalyticRestParamsProvider';
import {BaseRestClientImpl} from '../BaseRestClient';
import type {Configuration} from '../Configuration';
import {unwrap} from '../EitherAdapter';
import type {Json} from '../Json';
import type {Connection, JsonRpcClient} from '../JsonRpc';
import {ConnectionStatus} from '../JsonRpc';
import type {
  LauidProvider,
  LaunchApplicationUniqueIdResponse,
} from '../LauidProvider';
import type {
  NCWalletCallScheme,
  NCWalletNotificationScheme,
} from '../NCWalletServer';
import type {Service} from '../structure';
import type {
  AnalyticsNotification,
  Event,
  EventToSend,
  SavedEvent,
} from './AnalyticsNotification';

const ANALYTIC_EVENTS_COLLECTION = 'analyticEventsCollection';
const DEBOUNCE_TIME_MS = 1000;
const RETRY_SECONDS = 60;

class AnalyticsNotificationService
  extends BaseRestClientImpl
  implements AnalyticsNotification, Service
{
  private _notificationQueue: SavedEvent[] = [];
  private _isInProgress = false;
  private _storageTimeoutRef: ReturnType<typeof setTimeout> | null = null;
  private _lauid: string | null = null;
  private _timeDiff: number = 0;

  constructor(
    protected readonly _root: {
      readonly configuration: Configuration;
      readonly connection: Connection;
      readonly ncWalletJsonRpcClient: JsonRpcClient<
        NCWalletCallScheme,
        NCWalletNotificationScheme
      >;
      readonly accountStore: AccountStore;
      readonly http: Http;
      readonly jsonKeyValueStore: JsonKeyValueStore<JsonKeyValueMap>;
      readonly launchApplicationUniqueIdProvider: LauidProvider;
      readonly analyticRestParamsProvider: AnalyticRestParamsProvider;
      readonly json: Json;
      readonly errorRepository: ErrorRepository;
    },
  ) {
    super(_root);
  }

  addEvent(notification: Event) {
    if (!this._lauid) {
      return;
    }
    this._notificationQueue.push({
      id: nanoid(),
      name: notification.name,
      meta: notification.meta,
      client_created_at: AnalyticsNotificationService._timeStampInSecond(),
    });
    this._debouncedPutToStoreAndSend();
  }

  private _debouncedPutToStoreAndSend() {
    if (this._storageTimeoutRef) {
      clearTimeout(this._storageTimeoutRef);
    }
    this._storageTimeoutRef = setTimeout(
      () => this._putToStoreAndSend(),
      DEBOUNCE_TIME_MS,
    );
  }

  private async _putToStoreAndSend() {
    if (this._isInProgress) {
      return;
    }
    this._isInProgress = true;
    while (this._notificationQueue.length > 0) {
      const notificationsToStore = this._notificationQueue;
      const idsToStore = notificationsToStore.map(({id}) => id);
      this._notificationQueue = this._notificationQueue.filter(
        ({id}) => !idsToStore.includes(id),
      );
      const events = await this._getEvents();
      notificationsToStore.forEach(event => events.push(event));
      await this._setEvents(events);

      const notificationsToSend =
        AnalyticsNotificationService._filterByFailedAt(events);
      const idsToSend = notificationsToSend.map(({id}) => id);

      const result = await this._sendToServer(notificationsToSend);
      if (result) {
        const newEvents = (await this._getEvents()).filter(
          ({id}) => !idsToSend.includes(id),
        );
        await this._setEvents(newEvents);
      } else {
        const newEvents = (await this._getEvents()).map(notification => {
          if (!notification.failedAt && idsToSend.includes(notification.id)) {
            return {
              ...notification,
              failedAt: AnalyticsNotificationService._timeStampInSecond(),
            };
          }
          return notification;
        });
        await this._setEvents(newEvents);
      }
    }
    this._isInProgress = false;
  }

  private async _sendToServer(notifications: SavedEvent[]): Promise<boolean> {
    const accountId = getAccountFromState(this._root.accountStore.state)?.id;
    const dataToSend = {
      events: this._normalizeEvents(notifications),
      ...(accountId ? {account_id: accountId} : {}),
    };

    if (this._root.connection.getStatus() === ConnectionStatus.Open) {
      const result = await this._root.ncWalletJsonRpcClient.call(
        'app_events.send',
        dataToSend,
      );
      return result.success;
    } else {
      const params =
        await this._root.analyticRestParamsProvider.getRestParams();
      if (!params) {
        return false;
      }
      const searchParams = new URLSearchParams(params);
      const callParams = this._lauid
        ? {headers: {'x-lauid': this._lauid}}
        : undefined;
      const response = await this._call<LaunchApplicationUniqueIdResponse>(
        'POST',
        `api/v1/app_stats/events?${searchParams.toString()}` as Url,
        dataToSend,
        callParams,
      );
      return response.success;
    }
  }

  private _normalizeEvents(notifications: SavedEvent[]): EventToSend[] {
    return notifications.map(notification => {
      const metaData = {
        event_id: notification.id,
        ...notification.meta,
        ...(notification.failedAt ? {delayed: true} : {}),
      };
      return {
        lauid: this._lauid,
        name: notification.name,
        meta: metaData,
        client_created_at: notification.client_created_at + this._timeDiff,
      } as EventToSend;
    });
  }

  private static _filterByFailedAt(events: SavedEvent[]): SavedEvent[] {
    const currentTime = AnalyticsNotificationService._timeStampInSecond();
    return events.filter(
      notification =>
        !notification.failedAt ||
        currentTime - notification.failedAt > RETRY_SECONDS,
    );
  }

  private static _timeStampInSecond(): number {
    return Math.floor(Date.now() / 1000);
  }

  private async _getEvents(): Promise<SavedEvent[]> {
    return (
      (await unwrap(
        this._root.jsonKeyValueStore.get(ANALYTIC_EVENTS_COLLECTION),
      )) ?? []
    );
  }

  private async _setEvents(events: SavedEvent[]) {
    await this._root.jsonKeyValueStore.set(ANALYTIC_EVENTS_COLLECTION, events);
  }

  private async _loadLauid() {
    const currentTime = AnalyticsNotificationService._timeStampInSecond();

    const response =
      await this._root.launchApplicationUniqueIdProvider.getLauid();

    if (response && response.lauid) {
      this._timeDiff = response.timestamp
        ? response.timestamp - currentTime
        : 0;
      this._lauid = response.lauid;
    } else {
      this._lauid = null;
    }
  }

  protected get _base() {
    return this._root.configuration.current.values.ncWalletRestApiUrl;
  }

  protected get _timeout() {
    return this._root.configuration.current.values.ncWalletRestApiTimeout;
  }

  subscribe(): Disposer | undefined {
    void this._loadLauid();
    return undefined;
  }
}

export default AnalyticsNotificationService;
