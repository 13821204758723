import {useStrings, variance} from '@ncwallet-app/core';
import {
  Button,
  ButtonVariant,
  LG_BREAKPOINT,
  ModalContainer,
  TouchableOpacity,
} from '@ncwallet-app/ui';
import {
  CrossSvg,
  GlobeErrorSvg,
  ReloadSvg,
} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

export type RPCTimeoutErrorModalScreenProps = {
  onClose: () => void;
};

export default observer(function RPCTimeoutErrorModalScreen(
  props: RPCTimeoutErrorModalScreenProps,
) {
  const {onClose} = props;
  const strings = useStrings();

  return (
    <ModalContainer>
      <ModalBody>
        <ModalClose onPress={onClose} testID="close">
          <CloseIcon />
        </ModalClose>
        <ModalCenter>
          <CenterAppIcon>
            <GlobeErrorIcon />
          </CenterAppIcon>
          <CenterTitle>{strings['modalRefresh.title']}</CenterTitle>
          <CenterDescription>{strings['modalRefresh.desc']}</CenterDescription>
          <ModalBottom>
            <BottomButton
              title={strings['modalRefresh.button']}
              onPress={onClose}
              Icon={ReloadSvg}
              variant={ButtonVariant.Primary}
            />
          </ModalBottom>
        </ModalCenter>
      </ModalBody>
    </ModalContainer>
  );
});

const GlobeErrorIcon = variance(GlobeErrorSvg)(
  () => ({
    root: {},
  }),
  theme => ({
    width: 98,
    height: 98,
    color: theme.palette.textMain,
  }),
);

export const ModalBody = variance(View)(theme => ({
  root: {
    borderRadius: 6,
    minWidth: 315,
    backgroundColor: theme.palette.background,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        minWidth: 640,
        ...theme.bar(11),
      },
    }),
  },
}));

export const ModalClose = variance(TouchableOpacity)(
  theme => ({
    root: {},
    container: {
      marginLeft: 0,
      position: 'absolute',
      alignItems: 'center',
      justifyContent: 'center',
      top: 15,
      right: 15,
      zIndex: 1,
      borderRadius: 50,
      width: 32,
      height: 32,
      backgroundColor: theme.palette.uiSecondary,
      color: theme.palette.textPrimary,
    },
  }),
  (_, styles) => ({
    containerStyle: styles.container,
  }),
);

export const CloseIcon = variance(CrossSvg)(theme => ({
  root: {
    color: theme.palette.uiMain,
    padding: 5,
  },
}));

export const ModalCenter = variance(View)(theme => ({
  root: {
    paddingHorizontal: 15,
    paddingVertical: 20,
    flexDirection: 'column',
    alignItems: 'center',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingHorizontal: 40,
        paddingVertical: 40,
      },
    }),
  },
}));

export const CenterTitle = variance(Text)(theme => ({
  root: {
    textAlign: 'center',
    marginBottom: 15,
    ...theme.fontByWeight('700'),
    fontSize: 26,
    color: theme.palette.textPrimary,
  },
}));

export const CenterDescription = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    fontSize: 16,
    color: theme.palette.textAdditional1,
    textAlign: 'center',
  },
}));

export const CenterAppIcon = variance(View)(() => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 74,
    height: 74,
    marginBottom: 20,
  },
}));

export const ModalBottom = variance(View)(theme => ({
  root: {
    width: '100%',
    marginTop: 20,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        marginTop: 40,
      },
    }),
  },
}));

export const BottomButton = variance(Button)(theme => ({
  root: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        maxWidth: 480,
        marginHorizontal: 'auto',
        paddingHorizontal: 150,
      },
    }),
  },
}));
