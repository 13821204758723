import {useRoot, WalletLimitPeriod} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import {SafeAreaInset} from '@ncwallet-app/ui';
import {autorun, reaction, runInAction} from 'mobx';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useState} from 'react';

import {ListLimitsBindingState} from '../../../CommonNavigationContainers/hooks/walletLimits/ListLimitsBindingState';
import {useNavigationGetIsFocused} from '../../../Navigation/hooks';
import {LimitsSettingsScreen} from '../../../screens/LimitsSettingsScreen';
import type {WalletItemData} from '../../../shared/WalletItem';
import type {WalletLimitsItemData} from '../../../shared/WalletLimitsItemData';
import {getDefaultForSelectionActiveLimit} from '../../../shared/WalletLimitsItemData';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type ListLimitsBindingProps = SmallHomeStackBindingProps<'ListLimits'>;

export default observer(function ListLimitsBinding(
  props: ListLimitsBindingProps,
) {
  const {navigation} = props;
  const root = useRoot();
  const [state] = useState(() => new ListLimitsBindingState(root));
  const getIsFocused = useNavigationGetIsFocused();
  const getIsReady = useGetIsReadyToMakeRequests();
  useEffect(
    () =>
      autorun(() => {
        if (getIsReady() && getIsFocused()) {
          runInAction(() => {
            void state.refresh();
          });
        }
      }),
    [getIsFocused, getIsReady, state],
  );

  useEffect(
    () =>
      reaction(
        () => state.isTimeoutError,
        shouldRequest => {
          if (shouldRequest) {
            // eslint-disable-next-line @typescript-eslint/unbound-method
            root.rpcTimeoutErrorVisibility.registerAction(state.refresh);
          }
        },
      ),
    [state, root],
  );

  const handleWalletPress = useCallback(
    (w: WalletItemData) => {
      navigation.navigate('PromptNewLimit', {
        walletId: w.walletId,
        currency: w.currency.code,
        period: WalletLimitPeriod.Daily,
      });
    },
    [navigation],
  );

  const handleLimitPress = useCallback(
    (l: WalletLimitsItemData) => {
      navigation.navigate('ShowLimit', {
        walletId: l.walletId,
        period: getDefaultForSelectionActiveLimit(l).period,
      });
    },
    [navigation],
  );

  const handleAddPress = useCallback(() => {
    navigation.navigate('PromptNewWallet');
  }, [navigation]);

  return (
    <LimitsSettingsScreen
      getWallets={state.getWalletItems}
      getLimits={state.getLimitItems}
      onWalletPress={handleWalletPress}
      onLimitPress={handleLimitPress}
      onFilter={value => {
        state.setSearch(value, {searchLimits: true, searchWallets: true});
      }}
      onAddPress={handleAddPress}
      getValue={state.getSearch}
      insets={SafeAreaInset.BOTTOM}
      keyboardAvoiding
    />
  );
});
