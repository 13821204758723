import {
  type CryptoAddress,
  type CryptoCurrencyCode,
  type CurrencyStore,
  type DecimalString,
  type JsonRpcClient,
  type NCWalletCallScheme,
  type NCWalletNotificationScheme,
  type OnTimeoutError,
  TIMEOUT_ERROR,
} from '@ncwallet-app/core';
import type {
  ErrorDetails,
  ErrorParser,
} from '@ncwallet-app/core/src/ErrorParser';
import type {FlashMessage} from '@ncwallet-app/core/src/FlashMessage';
// eslint-disable-next-line import-x/no-deprecated
import type {LegacyLocalization} from '@ncwallet-app/core/src/Localization';
import type {PayLink} from '@ncwallet-app/core/src/NCWalletServer/PayLink';
import type {SentryLog} from '@ncwallet-app/core/src/SentryLog';
import createJsonRpcTimeoutErrorMessage from '@ncwallet-app/core/src/SentryLog/createJsonRpcTimeoutErrorMessage';
import {BigNumber} from 'bignumber.js';
import {action, computed, makeObservable, observable} from 'mobx';

const VALID_LINK_REGEXP = /^[a-zA-Z0-9]*$/;
const VALID_AMOUNT_REGEXP = /^[0-9]*[,.]?[0-9]{0,8}$/;

// eslint-disable-next-line import-x/prefer-default-export
export class GeneratePayMeLinkBindingState implements OnTimeoutError {
  constructor(
    private readonly _root: {
      readonly ncWalletJsonRpcClient: JsonRpcClient<
        NCWalletCallScheme,
        NCWalletNotificationScheme
      >;
      readonly errorParser: ErrorParser;
      readonly flashMessage: FlashMessage;
      readonly currencyStore: CurrencyStore;
      // eslint-disable-next-line @typescript-eslint/no-deprecated,import-x/no-deprecated
      readonly translation: LegacyLocalization;
      readonly sentryLog: SentryLog;
    },
  ) {
    makeObservable(this);
  }

  @observable private _id: string | undefined;
  @observable private _link: string | undefined;
  @observable private _savedLink: string | undefined;
  @observable private _amount: DecimalString | undefined;
  @observable private _currency: CryptoCurrencyCode | undefined;
  @observable private _address: CryptoAddress | undefined;
  @observable private _error: ErrorDetails | undefined;
  @observable private _isAvailableEdit: boolean = false;
  @observable private _isTimeoutError = false;

  @computed get isTimeoutError() {
    return this._isTimeoutError;
  }

  @computed get link() {
    return this._link || '';
  }

  @computed get isAvailableEdit() {
    return this._isAvailableEdit;
  }

  @computed get amount() {
    return this._amount;
  }

  @computed get currencyCode() {
    return this._currency;
  }

  @computed get currency() {
    return (
      this._currency &&
      this._root.currencyStore.getCryptoCurrency(this._currency)
    );
  }

  @computed get savedLink() {
    return this._savedLink;
  }

  @computed get error() {
    return this._error;
  }

  @action.bound
  private _checkMinAmount(): boolean {
    if (!this._amount || !this.currency) {
      return false;
    }
    if (
      BigNumber(this._amount).isGreaterThan(0) &&
      Number(this._amount) < Number(this.currency.options.min_withdrawal_amount)
    ) {
      this._error = {
        // eslint-disable-next-line @typescript-eslint/no-deprecated
        summary: this._root.translation.templates[
          'sendCrypto.validation.amountShouldBeMoreThanMin'
        ]({
          min: this.currency.options.min_withdrawal_amount,
        }),
      };
      return true;
    }

    return false;
  }

  @action.bound
  setLink(link: string): void {
    const isValid = VALID_LINK_REGEXP.test(link);

    if (isValid) {
      this._link = link.trim();
    }

    this._error = undefined;
  }

  @action.bound
  setAmount(amount: DecimalString | undefined): void {
    if (!amount) {
      return;
    }

    const isValid = VALID_AMOUNT_REGEXP.test(amount);
    if (!isValid) {
      return;
    }
    this._isAvailableEdit = true;
    this._amount = amount.replace(',', '.');
  }

  @action.bound
  setRequiredData(address: CryptoAddress, currency: CryptoCurrencyCode): void {
    this._address = address;
    this._currency = currency;
  }

  @action.bound
  async onSaveName(): Promise<void> {
    const id = this._id;
    const name = this._link;
    const currency = this._currency;
    const address = this._address;
    const amount = this._amount;

    if (!name) {
      this._error = {
        summary:
          // eslint-disable-next-line @typescript-eslint/no-deprecated
          this._root.translation.strings[
            'sendCrypto.validation.addressShouldBeNonEmpty'
          ],
      };
      return;
    }

    if (!(name && currency && address) || this._checkMinAmount()) {
      return;
    }

    let callback = async () =>
      this._root.ncWalletJsonRpcClient.call('addresses.pay_links.create', {
        code: name,
        address,
        currency,
        ...((Number(amount) === 0 || Number(amount) > 0) && {amount}),
      });

    if (id) {
      callback = async () =>
        this._root.ncWalletJsonRpcClient.call('addresses.pay_links.update', {
          id,
          code: this._link,
          ...(this._amount && {amount: this._amount}),
        });
    }

    const result = await callback();

    if (result.success) {
      this.setPayLinkInfo(result.right.link);
      this._isAvailableEdit = false;
      this._error = undefined;
    } else {
      this._error = this._root.errorParser.describe(result.left);
    }
  }

  @action.bound
  async generateLink(): Promise<void> {
    const result = await this._root.ncWalletJsonRpcClient.call(
      'addresses.pay_links.code.generate',
      {},
    );

    if (result.success) {
      this._link = result.right.code;
      this._error = undefined;
    } else {
      const error = this._root.errorParser.describe(result.left);
      this._root.flashMessage.showMessage({
        title: error.summary,
        variant: 'danger',
      });
    }
  }

  @action.bound
  async refresh(): Promise<void> {
    const result = await this._root.ncWalletJsonRpcClient.call(
      'addresses.pay_links.get',
      {
        currency: this._currency,
        address: this._address,
      },
    );

    if (!result.success) {
      if (result.left.kind === TIMEOUT_ERROR) {
        this._isTimeoutError = true;
        this._root.sentryLog.write(
          createJsonRpcTimeoutErrorMessage('addresses.pay_links.get', {
            currency: this._currency,
            address: this._address,
          }),
        );
      }
    }

    if (result.success) {
      this.setPayLinkInfo(result.right.link);
    }

    this._isAvailableEdit = !this._id;
  }

  @action.bound
  onMakeAvailableEdit(): void {
    this._isAvailableEdit = true;
  }

  @action.bound
  setPayLinkInfo(link: PayLink): void {
    this._id = link.id;
    this._savedLink = link.code;
    this._link = link.code;
    this.setRequiredData(link.address, link.currency);
    if (link.amount) {
      this.setAmount(link.amount);
    }
  }
}
