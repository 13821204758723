import type {Service} from '../..';
import type {AnalyticsNotification} from '../AnalyticsNotification';
import {EventType} from '../AnalyticsNotification';
import type {Appearance} from '../Appearance';

class AppearanceAnalyticsServiceImpl implements Service {
  constructor(
    private readonly _root: {
      readonly analyticsNotificationService: AnalyticsNotification;
      readonly appearance: Appearance;
    },
  ) {}

  subscribe() {
    return this._root.appearance.updates.listen(event => {
      this._root.analyticsNotificationService.addEvent({
        name: EventType.Preference,
        meta: event,
      });
    });
  }
}

export default AppearanceAnalyticsServiceImpl;
