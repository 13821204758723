import type {Millisecond} from '@ncwallet-app/core';
import {
  TransactionFilterKind,
  useStrings,
  useTheme,
  variance,
} from '@ncwallet-app/core';
import {
  CurrencyCircleLogo,
  LG_BREAKPOINT,
  TouchableOpacity,
  TransactionFilterIcon,
  useIsDimensions,
} from '@ncwallet-app/ui';
import {FilterSvg, HistorySvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import dayjs from 'dayjs';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Platform, Text, View} from 'react-native';

import {useGetHistoryFilterKindStrings} from '../HistoryFilterScreen/useGetHistoryFilterKindStrings';

export type HistoryHeaderProps = {
  currencyCode?: string;
  from?: Millisecond;
  to?: Millisecond;
  onFiltersPress: () => void;
  transactionFilterKind?: TransactionFilterKind;
};

export default observer(function HistoryFilterHeader(
  props: HistoryHeaderProps,
) {
  const {
    to,
    from,
    onFiltersPress,
    currencyCode,
    transactionFilterKind = TransactionFilterKind.All,
  } = props;
  const theme = useTheme();
  const isLg = useIsDimensions('lg');
  const strings = useStrings();

  const filterStrings = useGetHistoryFilterKindStrings();
  const isShortView = Boolean(from && currencyCode);

  return (
    <Top>
      {isLg && (
        <BlockMdTitle>
          <HistorySvg
            color={theme.palette.primary}
            secondColor={theme.palette.background}
          />
          <MdTitle>{strings['screenTitle.history']}</MdTitle>
        </BlockMdTitle>
      )}
      <Row>
        <BlockFilter>
          {currencyCode && (
            <Block rightBorder>
              <CurrencyCircleLogo code={currencyCode} size={22} />
              <Title hide gutter>
                {currencyCode}
              </Title>
            </Block>
          )}
          <Block wide>
            <TransactionFilterIcon kind={transactionFilterKind} size={22} />
            <Title gutter hideText hideMobile={isShortView}>
              {strings[filterStrings[transactionFilterKind]]}
            </Title>
          </Block>
          {from !== undefined && to !== undefined && (
            <Block leftBorder wide>
              <Title>
                {formatDate(from)}-{formatDate(to)}
              </Title>
            </Block>
          )}
        </BlockFilter>
        <FilterButton onPress={onFiltersPress} testID="filter_trans_btn">
          <FilterSvg color={theme.palette.info} />
        </FilterButton>
      </Row>
    </Top>
  );
});

const formatDate = (_: Millisecond) => dayjs(_).format('DD/MM/YY');

const Row = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'flex-end',
    width: '100%',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        width: 'auto',
      },
    }),
  },
}));

const BlockFilter = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: theme.palette.uiPrimary,
    paddingVertical: 3,
    borderRightWidth: 1,
    borderRightColor: theme.palette.uiSecondary,
    width: '100%',
    flexShrink: 1,
    paddingHorizontal: 15,
    gap: 8,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        borderWidth: 1,
        width: 'auto',
        borderColor: theme.palette.uiSecondary,
        paddingHorizontal: 15,
        paddingVertical: 15,
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
        marginLeft: 20,
        flexGrow: 0,
        flex: -1,
      },

      [1100]: {
        minWidth: 380,
      },
    }),
  },
}));

const BlockMdTitle = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 'auto',
  },
}));

const MdTitle = variance(Text)(theme => ({
  root: {
    marginLeft: 9,
    color: theme.palette.textPrimary,
    ...theme.fontByWeight('700'),
    fontSize: 18,
    lineHeight: 22,
  },
}));

const Block = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',

    marginVertical: 5,
    height: 30,
    flexGrow: 1,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        marginHorizontal: 0,
        height: 'auto',
        flexBasis: 'auto',
        marginVertical: 0,
      },
    }),
  },

  leftBorder: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        borderLeftColor: '#C4C4C4',
        borderLeftWidth: 1,
        paddingLeft: 22,
        marginLeft: 22,
      },
    }),
  },

  wide: {
    flexGrow: 1,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        justifyContent: 'flex-start',
      },
    }),
  },

  rightBorder: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        borderRightColor: '#C4C4C4',
        borderRightWidth: 1,
        paddingRight: 22,
        marginRight: 22,
      },
    }),
  },
}));

const FilterButton = variance(TouchableOpacity)(theme => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 15,
    paddingVertical: 13,
    width: 50,

    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        borderWidth: 1,
        borderColor: theme.palette.uiSecondary,
        borderLeftWidth: 0,
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
        flex: 1,
      },
    }),
  },
}));

const Top = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    maxWidth: '100%',
    borderColor: theme.palette.uiSecondary,

    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        borderBottomWidth: 0,
        borderTopWidth: 0,
        marginBottom: 20,
      },
    }),
  },
}));

const Title = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    fontSize: 14,
    lineHeight: 17,
  },
  gutter: {
    marginLeft: 5,
  },
  hide: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        display: 'none',
      },
      [1100]: {
        display: 'flex',
      },
    }),
  },
  hideMobile: {
    display: 'none',
    ...theme.mediaQuery({
      [500]: {
        display: 'flex',
      },
    }),
  },
  hideText: {
    overflow: 'hidden',
    ...Platform.select({
      web: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
    }),
  },
}));
