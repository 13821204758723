import LottieView, {type LottieViewProps} from 'lottie-react-native';
import {observer} from 'mobx-react-lite';
import React from 'react';

import JumpDark from './jump_dark.json';
import JumpLight from './jump_light.json';

export type LottieLoaderProps = Omit<LottieViewProps, 'source'> & {
  isDark?: boolean;
};

export default observer(function LottieLoader(props: LottieLoaderProps) {
  const {isDark = false, ...rest} = props;
  return <LottieView source={isDark ? JumpLight : JumpDark} loop {...rest} />;
});
