import {createStylesHook} from '@ncwallet-app/core';

export default createStylesHook(theme => ({
  root: {
    width: 96,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary,
  },
  touchable: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
