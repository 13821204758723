import queryString from 'query-string';

import type {Configuration} from '../Configuration';
import type {DeviceIdentification} from '../DeviceIdentification';
import type {DeviceInfo} from '../DeviceInfo';
import type {GlobalError} from '../Error';
import type {Either} from '../fp';
import type {Http} from '../Http';
import type {LauidProvider} from '../LauidProvider';
import type {SessionContextProvider} from '../SessionContext/SessionContextProvider';
import type {WebSocketFactory} from './WebSocketFactory';

const {stringify} = queryString;

export default class WebSocketFactoryImpl implements WebSocketFactory {
  constructor(
    private readonly _root: {
      readonly configuration: Configuration;
      readonly http: Http;
      readonly deviceInfo: DeviceInfo;
      readonly deviceIdentification: DeviceIdentification;
      readonly sessionContextProvider: SessionContextProvider;
      readonly launchApplicationUniqueIdProvider: LauidProvider;
    },
  ) {}

  async create(): Promise<Either<WebSocket, GlobalError>> {
    const deviceId_ = await this._root.deviceIdentification.getDeviceId();
    if (!deviceId_.success) {
      return deviceId_;
    }

    const launchApplicationUniqueIdResponse =
      await this._root.launchApplicationUniqueIdProvider.getLauid();

    const launchApplicationUniqueIdParams =
      launchApplicationUniqueIdResponse &&
      launchApplicationUniqueIdResponse.lauid
        ? {lauid: launchApplicationUniqueIdResponse.lauid}
        : {};

    const base = this._root.configuration.values.ncWalletJsonRpcUrl;
    const platform = this._root.sessionContextProvider
      .getContext()
      .platform.toLowerCase();
    const searchParams = stringify({
      app_id: this._root.deviceInfo.getBundleId(),
      app_ver: this._root.deviceInfo.getAppVersionWithBuildNumber(),
      device_id: deviceId_.right,
      platform,
      ...launchApplicationUniqueIdParams,
    });

    const locator = `${base}?${searchParams}`;
    return this._root.http.createWebSocket(locator);
  }
}
