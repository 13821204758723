import type {GlobalError} from '@ncwallet-app/core';
import {
  compact,
  ErrorRecognizerStatic,
  GENERAL_JSON_RPC_ERROR,
  GENERAL_REST_CLIENT_ERROR,
  NETWORK_ERROR,
  urlLinks,
  useLogButton,
  useRoot,
  useStrings,
} from '@ncwallet-app/core';
import {
  InternalError,
  MaintenanceError,
  UnknownServerError,
} from '@ncwallet-app/core/src/CriticalErrorState/errors';
import type {ErrorParser} from '@ncwallet-app/core/src/ErrorParser';
import type {Localization} from '@ncwallet-app/core/src/Localization';
import isTimeRelatedIssue from '@ncwallet-app/core/src/util/isTimeRelatedIssue';
import type {LocaleKeys} from '@ncwallet-app/localization/locale/LocaleStrings';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useRef} from 'react';

import type {AuthLoginErrorScreenProps} from '../../screens/AuthErrorScreen';
import {AuthErrorScreen} from '../../screens/AuthErrorScreen';
import type {AuthStackBindingProps} from './AuthStackBindingProps';

export type CommonErrorBindingProps =
  AuthStackBindingProps<CommonErrorBindingRouteName>;

export type CommonErrorBindingRouteName =
  | 'CriticalError'
  | 'AuthError'
  | 'ConnectionError'
  | 'AccountStoreError';

export default observer(function CommonErrorBinding(
  props: CommonErrorBindingProps,
) {
  const {route} = props;
  const routeName = route.name;
  const {error} = route.params;

  const strings = useStrings();
  const root = useRoot();
  const appVersion = root.deviceInfo.getAppVersionWithBuildNumber();

  const tryConnection = useCallback(
    () => root.connectionSpawner.tryConnection(),
    [root],
  );
  const resetAuth = useCallback(() => root.authHelper.reset(), [root]);
  const clearCriticalError = useCallback(() => {
    root.criticalErrorState.clear();
  }, [root]);
  const signOutReasonRef = useRef(SignOutReason.Unknown);
  signOutReasonRef.current = SignOutReason.Unknown;
  const signOut = useCallback(
    async () =>
      root.authHelper.signOut({
        reason: `CommonErrorBinding - ${
          SignOutReason[signOutReasonRef.current]
        }`,
      }),
    [root],
  );
  const signOutAndClose = useCallback(async () => {
    await signOut();
    root.telegramMiniApp.closeThisInstance();
  }, [root, signOut]);

  const goToHelp = useCallback(
    () => root.location.open(urlLinks.support),
    [root.location],
  );
  const logButtonComponent = useLogButton();

  const screenProps: AuthLoginErrorScreenProps = {
    appVersion,
    errorMessage: strings['authErrorScreen.title'],
    errorDescription: strings['authErrorScreen.caption'],
    cancelText: strings['authErrorScreen.tryAgain'],
    onCancel: resetAuth,
    onBack: signOut,
  };

  if (
    ErrorRecognizerStatic.isTokenExpiredInRest(error) ||
    ErrorRecognizerStatic.isTokenExpiredInJsonRpc(error)
  ) {
    signOutReasonRef.current = SignOutReason.AccountsAuthRejected;
    screenProps.errorMessage = strings['error.sessionExpired.title'];
    screenProps.errorDescription = strings['error.sessionExpired.desc'];
    screenProps.cancelText = strings['error.sessionExpired.btn'];
    screenProps.onCancel = signOut;
  } else if (
    error.kind === NETWORK_ERROR &&
    isTimeRelatedIssue(error.description)
  ) {
    signOutReasonRef.current = SignOutReason.WrongDateTimeSettings;
    screenProps.errorMessage = strings.Chain_Validation_Faild_Title;
    screenProps.errorDescription = strings.Chain_Validation_Faild_Description;
    screenProps.onCancel = tryConnection;
  } else if (
    ErrorRecognizerStatic.isMaintenance(error) ||
    error.raw instanceof MaintenanceError
  ) {
    signOutReasonRef.current = SignOutReason.Maintenance;
    screenProps.errorMessage = strings['Error.underMaintenance'];
    screenProps.errorDescription = strings['Error.checkBackSoon'];
    screenProps.cancelText = strings['authErrorScreen.contactSupport'];
    screenProps.isMaintenance = true;
    screenProps.onCancel = goToHelp;
  } else if (
    error.raw instanceof InternalError ||
    error.raw instanceof UnknownServerError
  ) {
    if (error.raw instanceof InternalError) {
      screenProps.errorContent = '-32603 InternalError';
    } else {
      screenProps.errorContent = '9999 UnknownServerError';
    }
    screenProps.cancelText = strings['GoBack.text'];
    screenProps.onCancel = clearCriticalError;
  } else if (
    ErrorRecognizerStatic.isTokenInvalidInRest(error) ||
    ErrorRecognizerStatic.isTokenInvalidInJsonRpc(error)
  ) {
    signOutReasonRef.current = SignOutReason.TokenInvalid;
    if (root.telegramMiniApp.isAvailable) {
      signOutReasonRef.current = SignOutReason.AccountsAuthRejected;
      screenProps.errorMessage = strings['error.sessionExpired.title'];
      screenProps.errorDescription = strings.restartTheAppAndRetry;
      screenProps.cancelText = strings['error.sessionExpired.btn'];
      screenProps.onCancel = signOutAndClose;
    } else {
      screenProps.errorMessage = strings['SignIn.failed.title'];
      screenProps.errorDescription = strings['SignIn.failed.description'];
      screenProps.cancelText = strings['SignIn.failed.button'];
      screenProps.onCancel = signOut;
    }
  } else if (error.kind === NETWORK_ERROR) {
    signOutReasonRef.current = SignOutReason.NetworkUnavailable;
    screenProps.errorMessage = strings['Error.no.internet.title'];
    screenProps.errorDescription = strings['Error.no.internet.description'];
    screenProps.onCancel = tryConnection;
  } else if (ErrorRecognizerStatic.isCodeMismatchInRest(error)) {
    signOutReasonRef.current = SignOutReason.TwoFaCodeMismatchInRest;
    screenProps.errorMessage = strings['errors.2faMismatch'];
    screenProps.errorDescription = undefined;
  } else if (
    ErrorRecognizerStatic.isUserIpInBlockedList(error) ||
    ErrorRecognizerStatic.isUserIpInBlockedListRest(error)
  ) {
    signOutReasonRef.current = SignOutReason.UserIpInBlockedList;
    screenProps.errorMessage = strings['SignIn.failed.title'];
    screenProps.errorDescription = strings['errors.userIpIsInBlockedList'];
    screenProps.cancelText = strings['SignIn.failed.button'];
    screenProps.onCancel = signOut;
  } else if (
    ErrorRecognizerStatic.isUserIpNotInAllowedList(error) ||
    ErrorRecognizerStatic.isUserIpNotInAllowedListRest(error)
  ) {
    signOutReasonRef.current = SignOutReason.UserIpNotInAllowedList;
    screenProps.errorMessage = strings['SignIn.failed.title'];
    screenProps.errorDescription = strings['errors.userIpIsNotInAllowedList'];
    screenProps.cancelText = strings['SignIn.failed.button'];
    screenProps.onCancel = signOut;
  } else if (ErrorRecognizerStatic.isUserBannedInRest(error)) {
    signOutReasonRef.current = SignOutReason.UserBanned;
    screenProps.errorMessage = strings['SignIn.failed.title'];
    screenProps.errorDescription = strings['errors.userBanned'];
    screenProps.cancelText = strings['authErrorScreen.contactSupport'];
    screenProps.onCancel = goToHelp;
  } else if (ErrorRecognizerStatic.isUserBannedInJsonRpc(error)) {
    signOutReasonRef.current = SignOutReason.UserBanned;
    screenProps.errorMessage = strings['userBlocked.errorTItle'];
    screenProps.errorDescription = strings['userBlocked.errorDescription'];
    screenProps.cancelText = strings['userBlocked.goBack'];
    screenProps.onCancel = signOut;
    screenProps.onNext = goToHelp;
    screenProps.nextText = strings['userBlocked.contactSupport'];
  } else {
    screenProps.errorContent = getErrorContent(root, error, routeName);
  }

  return <AuthErrorScreen {...screenProps} LogButton={logButtonComponent} />;
});

enum SignOutReason {
  Unknown,
  WrongDateTimeSettings,
  Maintenance,
  TokenInvalid,
  TwoFaCodeMismatchInRest,
  NetworkUnavailable,
  UserIpNotInAllowedList,
  UserIpInBlockedList,
  UserBanned,
  AccountsAuthRejected,
}

function getErrorContent(
  root: {
    readonly errorParser: ErrorParser;
    readonly localization: Localization;
  },
  error: GlobalError,
  routeName: CommonErrorBindingRouteName,
): string {
  const errorContentHeader = root.localization.getTranslation(
    errorContentHeaderMap[routeName],
  );
  const details = root.errorParser.describe(error, {
    ignoreUnknownProperties:
      error.kind === GENERAL_REST_CLIENT_ERROR ||
      error.kind === GENERAL_JSON_RPC_ERROR,
  });
  return compact([
    errorContentHeader,
    details.summary,
    details.code,
    details.details,
  ]).join('\n');
}

const errorContentHeaderMap = Object.freeze({
  AuthError: 'exception.stage.auth01',
  AccountStoreError: 'exception.stage.auth02',
  ConnectionError: 'exception.stage.auth03',
  CriticalError: 'exception.stage.auth04',
} as const satisfies Record<CommonErrorBindingRouteName, LocaleKeys>);
