import ProfileSettingsItem from '@ncwallet-app/navigation/src/screens/ProfileSettingsScreen/ProfileSettingsItem';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {type SvgProps} from 'react-native-svg';

import {useStrings} from '../../Root';

export type ProfileEmailButtonProps = {
  getTitle: () => string | undefined;
  getError: () => boolean | undefined;
  onEmailPress: () => void;
  onLinkToEmailPress: () => void;
  selected?: boolean;
  testID?: string;
  Icon?: React.ComponentType<SvgProps>;
  last?: boolean;
};

const ProfileEmailButton = observer(
  ({getTitle, getError, onEmailPress, Icon}: ProfileEmailButtonProps) => {
    const strings = useStrings();
    return (
      <ProfileSettingsItem
        last
        Icon={Icon}
        disabled
        label={strings['profileSettingsScreen.emailLabel']}
        getTitle={getTitle}
        onPress={onEmailPress}
        getError={getError}
      />
    );
  },
);

export default ProfileEmailButton;
