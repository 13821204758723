import {IpType} from '../NCWalletServer/ipSettings/IpType';
import type {Url} from '../units';

const baseLandLink =
  process.env.NODE_ENV === 'production'
    ? 'https://ncwallet.net/'
    : 'https://ncwallet-landing.dvlt.net/';

export const urlLinks = {
  whatIs2FaAuthentication:
    'https://ncwallet.net/faq/what-is-two-factor-authentication-(2fa)-and-how-is-it-used-in-nc-wallet/',
  whatIsWalletLimitLink:
    'https://ncwallet.net/faq/what-is-a-limit%3F-how-to-set-it/',
  changeWalletLimitLink:
    'https://ncwallet.net/faq/how-to-change-or-disable-a-limit/',
  addAllowedIpFaq:
    'https://ncwallet.net/faq/how-to-add-an-ip-address-to-the-allowed-ips-list/',
  addBlockedIpFaq:
    'https://ncwallet.net/faq/how-to-add-an-ip-address-to-the-blocked-ips-list/',
  ipListsFaq:
    'https://ncwallet.net/faq/what-are-blocked-ips-and-allowed-ips-lists/',
  payMeLink: 'https://ncwallet.net/pay/',
  support: 'https://ncwallet.net/feedback/' as Url,
  supportPin: 'https://ncwallet.net/feedback/?kind=pin',
  biometryNotSetFaq:
    'https://ncwallet.net/faq/i-am-getting-an-error-message:-%22biometrics-for-this-device-could-not-be-updated-from-this-nc-wallet-account/',
  accountVerification: `https://ncwallet.net/faq/what-is-identity-verification%3F-when-is-it-required/`,
  transactionStatusFaq:
    'https://ncwallet.net/faq/how-can-i-check-the-transaction-status%3F-what-do-the-statuses-mean/',
  biometricsLogFaq: `${baseLandLink}faq/can-i-use-biometrics-to-sign-in-to-my-wallet/`,
  biometricsTransactionFaq: `${baseLandLink}faq/can-i-use-biometrics-to-confirm-transactions/`,
  share: 'https://ncwallet.net/share',
  affiliate: 'https://ncwallet.net/affiliate/',
  inviteLand: 'https://ncwallet.net/invite/',
  inviteApp: 'https://ncwallet.net/invite-app/',
  inviteTelegram: 'https://ncwallet.net/invite-telegram/',
  internalComment:
    'https://ncwallet.net/faq/what-is-an-internal-comment,-and-how-does-it-work/' as Url,
  kycFaq: `https://ncwallet.net/faq/what-is-identity-verification%3F-when-is-it-required/`,
};

export const getAddIpFaqLink = (t: IpType) =>
  t === IpType.Allowed ? urlLinks.addAllowedIpFaq : urlLinks.addBlockedIpFaq;
