import {useStrings, variance} from '@ncwallet-app/core';
import {ArrowRightSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';

import {BodyTag, Divider, HeaderTag, HeaderTitleTag} from '../useCardStyles';
import BackButton from './BackButton';
import VerificationTouchableListItem from './VerificationTouchableListItem';

export type AddDocumentKindProps = {
  onBack: () => void;
  onDocumentPress: () => void;
};

export default observer(function AddDocumentKind(props: AddDocumentKindProps) {
  const {onBack, onDocumentPress} = props;
  const strings = useStrings();

  return (
    <>
      <HeaderTag>
        <BackButton onPress={onBack} testID="back_scrn_btn" />
        <HeaderTitleTag>
          {strings['screenTitle.identityAddDocuments']}
        </HeaderTitleTag>
      </HeaderTag>
      <BodyTag>
        <BorderedView>
          <VerificationTouchableListItem
            label={strings['accountDocumentType.passport']}
            isLabelLarge
            large
            Icon={ArrowRightSvg}
            onPress={onDocumentPress}
          />
          <Divider />
          <VerificationTouchableListItem
            label={strings['accountDocumentType.id_card']}
            isLabelLarge
            large
            Icon={ArrowRightSvg}
            onPress={onDocumentPress}
          />
          <Divider />
          <VerificationTouchableListItem
            label={
              strings[
                'profileSettingsScreen.verification.identityDocument.idSelfie'
              ]
            }
            isLabelLarge
            large
            Icon={ArrowRightSvg}
            onPress={onDocumentPress}
          />
          <Divider />
          <VerificationTouchableListItem
            label={
              strings[
                'profileSettingsScreen.verification.identityDocument.bankDetails'
              ]
            }
            isLabelLarge
            large
            Icon={ArrowRightSvg}
            onPress={onDocumentPress}
          />
          <Divider />
          <VerificationTouchableListItem
            label={
              strings[
                'profileSettingsScreen.verification.identityDocument.traderLicense'
              ]
            }
            isLabelLarge
            large
            Icon={ArrowRightSvg}
            onPress={onDocumentPress}
          />
        </BorderedView>
      </BodyTag>
    </>
  );
});

const BorderedView = variance(View)(theme => ({
  root: {
    borderRadius: 12,
    borderWidth: 1,
    borderColor: theme.palette.uiSecondary,
    overflow: 'hidden',
  },
}));
