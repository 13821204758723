import {TouchableOpacity} from '@ncwallet-app/ui';
import {CloseEye} from '@ncwallet-app/ui/src/assets/svg/colored';
import React from 'react';
import type {SharedValue} from 'react-native-reanimated';
import Animated, {interpolate, useAnimatedStyle} from 'react-native-reanimated';

import type {HideActionProps} from './HideAction';
import useHideActionStyles from './useHideActionStyles';

export type NativeHideActionProps = {
  progress: SharedValue<number>;
} & HideActionProps;

export default function NativeHideAction(props: NativeHideActionProps) {
  const {progress, onPress} = props;
  const styles = useHideActionStyles();
  const animatedStyles = useAnimatedStyle(
    () => ({
      opacity: interpolate(progress.value, [-1, 1], [0, 1]),
    }),
    [progress],
  );
  return (
    <Animated.View style={[styles.root, animatedStyles]}>
      <TouchableOpacity style={styles.touchable} onPress={onPress}>
        <CloseEye />
      </TouchableOpacity>
    </Animated.View>
  );
}
