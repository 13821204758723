import {useStrings, variance} from '@ncwallet-app/core/src';
import type {FiatCurrency} from '@ncwallet-app/core/src/NCWalletServer/FiatCurrency';
import type {BaseSafeAreaProps} from '@ncwallet-app/ui/src';
import {LG_BREAKPOINT, SearchHeader} from '@ncwallet-app/ui/src';
import {noop} from 'lodash';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';

import {FiatSectionList} from './FiatSectionList';

export type BaseFiatSettingScreenProps = BaseSafeAreaProps & {
  baseFiat: () => FiatCurrency | undefined;
  otherFiat: () => FiatCurrency[] | undefined;
  filter: string;
  onFilterChange: (filter: string) => void;
  onFilterSubmit?: (filter: string) => void;
  onFiatSelect: (fiat: FiatCurrency) => void;
};

export const BaseFiatSettingScreen = observer(
  (props: BaseFiatSettingScreenProps) => {
    const strings = useStrings();
    const {
      baseFiat,
      otherFiat,
      filter,
      onFilterChange,
      onFilterSubmit,
      onFiatSelect,
      ...rest
    } = props;

    return (
      <Root>
        <ProfileSearchHeader
          value={filter}
          onValueChange={onFilterChange}
          onSubmit={onFilterSubmit || noop}
          placeholder={
            strings['profileSettingsScreen.searchFiatCurrencyPlaceholder']
          }
        />
        <FiatSectionList
          otherFiat={otherFiat}
          baseFiat={baseFiat}
          onFiatSelect={onFiatSelect}
          {...rest}
        />
      </Root>
    );
  },
);

const Root = variance(View)(() => ({
  root: {
    flex: 1,
  },
}));

const ProfileSearchHeader = variance(SearchHeader)(theme => ({
  root: {
    paddingHorizontal: 15,
    paddingBottom: 13,
    paddingTop: 0,
    backgroundColor: theme.palette.background,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.uiSecondary,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingHorizontal: 0,
        paddingVertical: 0,
        paddingBottom: 0,
        backgroundColor: 'transparent',
        borderBottomWidth: 0,
      },
    }),
  },
}));
