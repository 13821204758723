import {useBoolean, useStrings, variance} from '@ncwallet-app/core';
import {Button, ButtonVariant} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {View} from 'react-native';

import BuyAmountInput from '../BuyAmountInput';
import FinalBuyMessage from '../FinalBuyMessage/FinalBuyMessage';
import ReceiveAddressType from '../ReceiveAddressType';
import {useReceiveScreenActions} from '../useReceiveScreenActions';
import {WalletInfo} from '../WalletInfo';
import type {BuyTabViewProps} from './BuyTabView';

export default observer(function LgBuyTabView(props: BuyTabViewProps) {
  const {
    walletHeader,
    amount,
    cryptoCurrency,
    addressName,
    networks,
    addressNameShown,
    contractType,
    addressType,
    walletAddress,
    isBalanceHidden,
    onShare,
    onAddresses,
    onAddressTypePress,
    currencyCode,
    error,
    setAmount,
    onSubmit,
    disabled,
    goToRoot,
  } = props;
  const strings = useStrings();

  const {copyAddressThrottled, shareThrottled} = useReceiveScreenActions(
    onShare,
    walletAddress,
  );

  const [isFinalModalShown, showFinalModalShown, hideFinalModalShown] =
    useBoolean(false);

  const handleBuySubmit = useCallback(async () => {
    const isSubmitSuccessful = await onSubmit();
    if (isSubmitSuccessful) {
      showFinalModalShown();
    }
  }, [onSubmit, showFinalModalShown]);

  const handleConfirmModal = () => {
    hideFinalModalShown();
    goToRoot();
  };

  return (
    <Root>
      {!isBalanceHidden && (
        <BuyTabHeader>
          {walletHeader}
          <ReceiveAddressType
            addressName={addressName}
            onAddressTypePress={onAddressTypePress}
            addressNameShown={addressNameShown}
            networks={networks}
            contractType={contractType}
          />
        </BuyTabHeader>
      )}
      <BuyTabContent>
        <BuyAmountInput
          wideStyle
          amount={amount}
          fractionalDigits={cryptoCurrency?.fractionDigits}
          onChangeAmount={setAmount}
          currency={currencyCode}
          onCurrencyPress={props.onCurrencyPress}
          errorText={error.amount?.description}
        />
        <WalletInfo
          goToAddresses={onAddresses}
          copyShown={false}
          shareShown={false}
          addressType={addressType}
          label={strings['receiveCryptoScreen.walletAddressLabel']}
          info={walletAddress}
          onCopy={copyAddressThrottled}
          onShare={shareThrottled}
        />
      </BuyTabContent>

      <BottomContainer>
        <SubmitButton
          title={strings['nextButton']}
          variant={ButtonVariant.Primary}
          onPress={handleBuySubmit}
          disabled={disabled}
        />
      </BottomContainer>
      {isFinalModalShown && (
        <FinalBuyMessage
          onConfirm={handleConfirmModal}
          onCancel={hideFinalModalShown}
        />
      )}
    </Root>
  );
});

const Root = variance(View)(() => ({
  root: {
    flex: 1,
    gap: 20,
  },
}));

const BuyTabHeader = variance(View)(() => ({
  root: {
    gap: 20,
    flexDirection: 'row',
  },
}));

const BuyTabContent = variance(View)(() => ({
  root: {
    flex: 1,
    gap: 20,
  },
}));

const BottomContainer = variance(View)(() => ({
  root: {
    paddingHorizontal: 15,
  },
}));

const SubmitButton = variance(Button)(() => ({
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    maxWidth: 345,
  },
}));
