import {useStrings} from '@ncwallet-app/core';
import type {FiatCurrency} from '@ncwallet-app/core/src/NCWalletServer/FiatCurrency';
import {Separator} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {FlatList} from 'react-native-gesture-handler';

import useLgCryptoSectionListStyle, {
  EmptyListTitle,
} from '../../BaseCryptoSettingScreen/LgCryptoSectionList/useLgCryptoSectionListStyle';
import {SettingsGroupTitle} from '../../SharedComponents/SettingsGroupTitle';
import LgFiatItemList from './LgFiatItem';

export type LgFiatSectionListProps = {
  onFiatSelect: (fiat: FiatCurrency) => void;
  baseFiat: FiatCurrency | undefined;
  othersFiat: FiatCurrency[] | undefined;
};

export default observer(function LgFiatSectionList({
  onFiatSelect,
  baseFiat,
  othersFiat,
}: LgFiatSectionListProps) {
  const strings = useStrings();
  const styles = useLgCryptoSectionListStyle();

  if (baseFiat === undefined || othersFiat === undefined) {
    return null;
  }

  return (
    <>
      <SettingsGroupTitle title={strings['updateBaseCryptScreen.selected']} />
      <LgFiatItemList title={baseFiat.name} fiatCode={baseFiat.code} active />
      <SettingsGroupTitle
        title={strings['updateBaseCryptScreen.available']}
        first={false}
      />
      {othersFiat.length > 0 ? (
        <FlatList
          overScrollMode="never"
          data={othersFiat}
          style={[styles.list, {maxHeight: 'auto'}]}
          contentContainerStyle={styles.root}
          ItemSeparatorComponent={() => (
            <Separator
              style={{
                margin: 0,
              }}
            />
          )}
          renderItem={({item}) => (
            <LgFiatItemList
              onPress={() => {
                onFiatSelect(item);
              }}
              title={item.name}
              fiatCode={item.code}
            />
          )}
          keyExtractor={(item, index) => `${index} + ${item.code}`}
        />
      ) : (
        <EmptyListTitle>{strings['sectionList.empty']}</EmptyListTitle>
      )}
    </>
  );
});
