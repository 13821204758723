import {useRoot, variance} from '@ncwallet-app/core';
import {LG_BREAKPOINT} from '@ncwallet-app/ui/src';
import {round} from 'lodash';
import {observer} from 'mobx-react-lite';
import {expr} from 'mobx-utils';
import React, {useMemo} from 'react';
import type {ViewStyle} from 'react-native';
import {Platform, Text, View} from 'react-native';

import {PinKeyboardButton} from './PinKeyboardButton';
import PinKeyboardButtonWeb from './PinKeyboardButtonWeb';

export type PinKeyboardNumberRowProps = {
  keys: [number, number, number];
  currentSelectedKey: {
    value: number | undefined;
  };
  onNumberPress: (n: number) => void;
};

const BUTTON_INITIAL_SIZE = 27;
const BUTTON_LOWER_SIZE = 55;
const BUTTON_UPPER_SIZE = 65;

export default observer(function PinKeyboardNumberRow(
  props: PinKeyboardNumberRowProps,
) {
  const {keys, currentSelectedKey} = props;
  const {windowDimensionsState} = useRoot();
  const computedButtonSize = expr(() => {
    const {width, height} = windowDimensionsState.window;
    const aspectRatio = round(height / width, 2);
    const keyboardButtonSize = BUTTON_INITIAL_SIZE * aspectRatio;
    if (keyboardButtonSize <= BUTTON_LOWER_SIZE) {
      return BUTTON_LOWER_SIZE;
    } else if (keyboardButtonSize >= BUTTON_UPPER_SIZE) {
      return BUTTON_UPPER_SIZE;
    } else {
      return keyboardButtonSize;
    }
  });

  const computedStyle = useMemo(
    () =>
      ({
        width: computedButtonSize,
        height: computedButtonSize,
        cursor: 'pointer',
      }) satisfies ViewStyle,
    [computedButtonSize],
  );

  const isWeb = Platform.OS === 'web';

  return (
    <Row>
      {keys.map(key => {
        return (
          <KeyboardWrapper key={key}>
            {!isWeb ? (
              <KeyPressable
                key={key}
                onPress={() => {
                  props.onNumberPress(key);
                }}
                style={computedStyle}>
                <KeyButtonText selectable={false}>{key}</KeyButtonText>
              </KeyPressable>
            ) : (
              <KeyPressableWeb
                key={key}
                isActive={key === currentSelectedKey.value}
                onPress={() => {
                  props.onNumberPress(key);
                }}
                style={computedStyle}
                testID={`pin-key-${key}`}>
                <KeyButtonText selectable={false}>{key}</KeyButtonText>
              </KeyPressableWeb>
            )}
          </KeyboardWrapper>
        );
      })}
    </Row>
  );
});

const Row = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    paddingVertical: 5,
    width: '100%',

    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        justifyContent: 'space-between',
        paddingVertical: 0,
      },
    }),
  },
}));

const KeyboardWrapper = variance(View)(() => ({
  root: {
    width: 'auto',
  },
}));

const KeyPressable = variance(PinKeyboardButton)(() => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 55,
    height: 55,
  },
}));

const KeyButtonText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('bold'),
    color: theme.palette.textMain,
    fontSize: 36,
    lineHeight: 42,
  },
}));

const KeyPressableWeb = variance(PinKeyboardButtonWeb)(() => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    borderRadius: 5,
  },
}));
