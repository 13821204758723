import {createStylesHook, useStrings, variance} from '@ncwallet-app/core';
import type {BaseSafeAreaProps} from '@ncwallet-app/ui';
import {SafeAreaInset, SafeAreaSectionList} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';

import {EXTRA_BOTTOM_OFFSET} from '../../constants';
import EmptySectionTitle from '../SharedComponents/EmptySectionTitle';
import {SettingsGroupTitle} from '../SharedComponents/SettingsGroupTitle';
import type {CryptoSectionItemData} from './CryptoSectionItem';
import {CryptoSectionItem} from './CryptoSectionItem';

export type CryptoSectionListProps = BaseSafeAreaProps & {
  getBaseCrypto: () => CryptoSectionItemData | undefined;
  getOtherCryptos: () => CryptoSectionItemData[] | undefined;
  onCryptoSelect: (crypto: CryptoSectionItemData) => void;
};

export type SectionInfo = {
  title: string;
  data: CryptoSectionItemData[];
  value?: string;
};

export const CryptoSectionList = observer((props: CryptoSectionListProps) => {
  const strings = useStrings();
  const {getBaseCrypto, getOtherCryptos, onCryptoSelect, ...rest} = props;
  const base = getBaseCrypto();
  const others = getOtherCryptos();
  const styles = useStyles();
  if (!base || !others) {
    return null;
  }

  const data: SectionInfo[] = [
    {
      title: strings['updateBaseCryptScreen.currentCrypt'],
      data: [base],
    },
    {
      title: strings['updateBaseCryptScreen.allCrypt'],
      data: others,
    },
  ];
  return (
    <SafeAreaSectionList
      contentContainerStyle={styles.contentContainerStyle}
      insets={SafeAreaInset.BOTTOM}
      extra={{bottom: EXTRA_BOTTOM_OFFSET}}
      stickySectionHeadersEnabled={false}
      sections={data}
      keyExtractor={(item, index) => `${index} + ${item.crypto.code}`}
      renderSectionHeader={({section}) => (
        <>
          <SectionHeader
            title={section.title}
            first={data.indexOf(section) === 0}
          />
          {others.length === 0 && data.indexOf(section) !== 0 && (
            <EmptySectionTitle />
          )}
        </>
      )}
      renderItem={info => {
        const {item} = info;
        const isLast = info.index === info.section.data.length - 1;
        const isFirst = info.index === 0;
        return (
          <CryptoSectionItem
            isLast={isLast}
            isFirst={isFirst}
            onPress={() => {
              onCryptoSelect(item);
            }}
            title={item.crypto.name}
            {...item}
          />
        );
      }}
      {...rest}
    />
  );
});

const SectionHeader = variance(SettingsGroupTitle)(() => ({
  root: {},
}));

const useStyles = createStylesHook(theme => ({
  contentContainerStyle: {
    backgroundColor: theme.palette.uiPrimary,
    padding: 16,
  },
}));
