import {useStrings} from '@ncwallet-app/core';
import {AggregationItemStatus} from '@ncwallet-app/core/src/WalletTransactionsRequestHelper';
import type {LocaleKeys} from '@ncwallet-app/localization/locale/LocaleStrings';
import {useCallback} from 'react';

const statusToLocale: Record<AggregationItemStatus, LocaleKeys> = {
  [AggregationItemStatus.Created]: 'historyOperationFilterScreen.created',
  [AggregationItemStatus.Canceled]: 'historyOperationFilterScreen.canceled',
  [AggregationItemStatus.Pending]: 'historyOperationFilterScreen.pending',
  [AggregationItemStatus.Completed]: 'historyOperationFilterScreen.completed',
  [AggregationItemStatus.Error]: 'historyOperationFilterScreen.error',
  [AggregationItemStatus.Rejected]: 'historyOperationFilterScreen.rejected',
  [AggregationItemStatus.Reversed]: 'historyOperationFilterScreen.reversed',
};

// eslint-disable-next-line import-x/prefer-default-export
export const useGetTransactionStatusText = () => {
  const strings = useStrings();

  return useCallback(
    (s: AggregationItemStatus) => strings[statusToLocale[s]],
    [strings],
  );
};
