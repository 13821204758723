import type {Theme} from '@ncwallet-app/core';
import {useStrings, useTheme} from '@ncwallet-app/core';
import {AccountDataStatus} from '@ncwallet-app/core/src/NCWalletServer/AccountsData';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo} from 'react';

import {Tag, TagContainer} from '../useCardStyles';

type StatusTagProps = {
  status: AccountDataStatus | undefined;
  testID?: string;
};

// eslint-disable-next-line import-x/prefer-default-export
export const VerificationStatusText = observer((props: StatusTagProps) => {
  const {status, testID} = props;
  const strings = useStrings();
  const theme = useTheme();

  const styles = useMemo(() => {
    const accountStatusColor = mapAccountStatusToColor(theme);
    return {
      root: {
        backgroundColor: status
          ? accountStatusColor.get(status)
          : accountStatusColor.get(AccountDataStatus.Created),
      },
    };
  }, [status, theme]);

  const statusText = useCallback(() => {
    if (!status) {
      return strings[
        'profileSettingsScreen.verification.verifiedStatusRequired'
      ];
    }

    switch (status) {
      case AccountDataStatus.OnReview:
        return strings[
          'profileSettingsScreen.verification.identityDocument.status.waiting'
        ];
      case AccountDataStatus.Verified:
        return strings['profileSettingsScreen.verification.verifiedStatus'];
      case AccountDataStatus.Created:
        return strings[
          'profileSettingsScreen.verification.verifiedStatusRequired'
        ];
      case AccountDataStatus.Failed:
        return strings[
          'profileSettingsScreen.verification.verifiedStatusRequired'
        ];
      default:
        return '';
    }
  }, [status, strings]);

  return (
    <TagContainer style={styles.root} testID={testID}>
      <Tag>{statusText()}</Tag>
    </TagContainer>
  );
});

const mapAccountStatusToColor = (theme: Theme) => {
  return new Map([
    [AccountDataStatus.OnReview, theme.palette.primary],
    [AccountDataStatus.Verified, theme.palette.success],
    [AccountDataStatus.Created, theme.palette.error],
  ]);
};
