import type {AppearanceEvent} from '../Appearance/Appearance';
import type {Url} from '../units';

export enum Domain {
  UnexpectedError = 'unexpected_error',
  Navigation = 'navigation',
  Activity = 'activity',
}

export enum EventType {
  FrontendError = 'frontend_error',
  SocketUnreachable = 'socket_unreachable',
  Preference = 'preference',
  PageOpen = 'page_open',
  PageLeave = 'page_leave',
  ScreenTap = 'screen_tap',
}

type MetaMap = {
  [EventType.FrontendError]: {
    domain: Domain.UnexpectedError;
    stack: string;
    url: Url;
  };
  [EventType.SocketUnreachable]: {
    url: Url;
  };
  [EventType.Preference]: AppearanceEvent;
  [EventType.PageOpen]: {
    domain: Domain.Navigation;
    url: Url | string;
    from?: Url | string;
    host: string;
  };
  [EventType.PageLeave]: {
    domain: Domain.Navigation;
    url: Url | string;
    time_spend: number;
    host: string;
  };
  [EventType.ScreenTap]: {
    domain: Domain.Activity;
    data_test_id: string;
    page_x: number;
    page_y: number;
  };
};

export type Event<T extends EventType = EventType> = {
  name: T;
  meta: MetaMap[T];
};

export type SavedEvent<T extends EventType = EventType> = {
  id: string;
  name: T;
  meta: MetaMap[T];
  client_created_at: number;
  failedAt?: number;
};

export type EventToSend<T extends EventType = EventType> = {
  lauid: string;
  name: T;
  meta: MetaMap[T] & {
    event_id: string;
    delayed?: boolean;
  };
  client_created_at: number;
};

export interface AnalyticsNotification {
  addEvent(event: Event): void;
}
