import {sized, useStrings, useTheme, variance} from '@ncwallet-app/core';
import {
  CollapsableContainer,
  Input,
  InputVariant,
  Pressable,
} from '@ncwallet-app/ui/src';
import {
  HelpSvg,
  MinusSVG,
  PlusSvg,
} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';
import type {TextInput, TextInputProps} from 'react-native';
import {Text, View} from 'react-native';

export type SendCommentProps = TextInputProps & {
  value?: string;
  inputRef?: React.Ref<TextInput>;
  showKeyboard?: boolean;
  onFocus?: () => void;
  onInputChange?: (value: string) => void;
  onActionPress?: () => void;
  wideStyle?: boolean;
  error?: boolean;
  withHiding?: boolean;
  isShown?: boolean;
  isBlockchainComment: boolean;
  toggleComment?: () => void;
  goToFAQ: () => void;
  testID?: string;
};

export default observer(function SendCommentInput(props: SendCommentProps) {
  const {
    value,
    onInputChange,
    inputRef,
    showKeyboard,
    onFocus,
    error,
    withHiding = false,
    isShown = true,
    toggleComment,
    goToFAQ,
    isBlockchainComment,
    ...rest
  } = props;
  const theme = useTheme();
  const strings = useStrings();

  const {label, placeholder} = useMemo(() => {
    if (isBlockchainComment) {
      return {
        label: strings['BlockchainComment'],
        placeholder: strings['sendCryptoScreen.memoTagInputPlaceholder'],
      };
    }

    return {
      label: strings['receiveCryptoScreen.commentFormLabel'],
      placeholder: strings['receiveCryptoScreen.commentFormPlaceholder'],
    };
  }, [strings, isBlockchainComment]);

  return (
    <Root>
      {withHiding && (
        <Label
          isShown={isShown}
          toggle={toggleComment}
          goToFAQ={goToFAQ}
          isBlockchainComment={isBlockchainComment}
        />
      )}
      <CollapsableContainer expanded={isShown} topOffset={10}>
        <Input
          ref={inputRef}
          value={value}
          label={!withHiding ? label : undefined}
          placeholder={placeholder}
          placeholderTextColor={theme.palette.textAdditional3}
          showSoftInputOnFocus={showKeyboard}
          onFocus={onFocus}
          onChangeText={onInputChange}
          variant={error ? InputVariant.Error : InputVariant.Default}
          {...rest}
        />
      </CollapsableContainer>
    </Root>
  );
});

const Label = ({
  isShown,
  toggle,
  goToFAQ,
  isBlockchainComment,
}: {
  isShown: boolean;
  isBlockchainComment: boolean;
  toggle?: () => void;
  goToFAQ: () => void;
}) => {
  const theme = useTheme();
  const strings = useStrings();

  return (
    <Inner>
      <LabelText>
        {isBlockchainComment ? (
          <>{strings['BlockchainComment']}</>
        ) : (
          <>
            {strings['receiveCryptoScreen.commentFormLabel']}
            <Pressable onPress={goToFAQ}>
              <HelpIcon
                style={{marginLeft: 5}}
                color={theme.palette.uiAdditional1}
              />
            </Pressable>
          </>
        )}
      </LabelText>
      <Toggle onPress={toggle} testID="comment_show_btn">
        {isShown ? (
          <MinusIcon color={theme.palette.info} />
        ) : (
          <PlusIcon color={theme.palette.info} />
        )}
      </Toggle>
    </Inner>
  );
};

const PlusIcon = sized(PlusSvg, 14);
const MinusIcon = sized(MinusSVG, 14);
const HelpIcon = sized(HelpSvg, 17);
const Root = variance(View)(() => ({
  root: {
    paddingVertical: 0,
    paddingHorizontal: 0,
  },
}));

const Inner = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const Toggle = variance(Pressable)(() => ({
  root: {
    padding: 4,
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const LabelText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    fontSize: 14,
    lineHeight: 16,
  },
}));
