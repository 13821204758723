import {action, computed, makeObservable, observable} from 'mobx';

import {BusImpl} from '../structure';
import type {BusSource} from '../structure';
import type {
  Callback,
  RPCTimeoutErrorVisibility,
} from './RPCTimeoutErrorVisibility';

export default class RPCTimeoutErrorVisibilityImpl
  implements RPCTimeoutErrorVisibility
{
  private readonly _updates = new BusImpl<() => void>();
  @observable private _isVisible = false;
  @observable private _callback: Callback | null = null;

  @computed
  get isVisible() {
    return !!this._callback;
  }

  constructor() {
    makeObservable(this);
  }

  get updates(): BusSource<() => void> {
    return this._updates;
  }

  @action
  callRegisteredAction() {
    this._callback?.();
    this._callback = null;
  }

  @action
  registerAction(callback: Callback | null) {
    if (this._isVisible) {
      return;
    }
    this._callback = callback;
    this._updates.send();
  }
}
