import {uriToFileData, useStrings, variance} from '@ncwallet-app/core';
import type {IdentityVerificationUploadDocumentRoute} from '@ncwallet-app/core/src/CommonNavigationScheme';
import type {
  AccountDocument,
  AccountDocumentId,
  AccountDocumentType,
} from '@ncwallet-app/core/src/NCWalletServer/AccountsDocuments';
import {fileCanBeUploadedForDocument} from '@ncwallet-app/core/src/NCWalletServer/AccountsDocuments';
import type {AccountDocumentFileInfo} from '@ncwallet-app/core/src/NCWalletServer/AccountsDocuments/AccountDocumentFileInfo';
import {Button, ButtonIconPosition, ButtonVariant} from '@ncwallet-app/ui';
import {UploadSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {useRoute} from '@react-navigation/native';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useState} from 'react';
import {Text, View} from 'react-native';

import {
  getVerificationFileFromFs,
  useHandleVerificationFileError,
} from '../../../../CommonNavigationContainers/hooks/AccountVerification';
import UploadedResultItem from '../../../IdentityVerificationUploadDocument/UploadedResultItem';
import {Description} from '../../../QrCodeScreen/LabelLayer';
import {BodyTag, HeaderTag, HeaderTitleTag} from '../useCardStyles';
import BackButton from './BackButton';

export type UploadDocumentProps = {
  onBack: () => void;
  documents: AccountDocument[];
  onUpload: (
    fileData: string,
    documentTypeId: AccountDocumentType,
    fileName: string | undefined,
  ) => void;
  onDeleteFile: (id: AccountDocumentId, file: AccountDocumentFileInfo) => void;
  testID?: string;
};

export default observer(function UploadDocument(props: UploadDocumentProps) {
  const route = useRoute();
  const {onBack, onUpload, onDeleteFile, documents} = props;
  const strings = useStrings();
  const [errorText, setErrorText] = useState<string | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const firstDocumentType = documents && documents[0] ? documents[0].type : '';
  const {focusedVerification} = route.params as {
    focusedVerification: IdentityVerificationUploadDocumentRoute;
  };
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const {type} = focusedVerification.params || {type: firstDocumentType};
  const doc = documents.find(document => document.type === type);
  const handleVerificationFileError = useHandleVerificationFileError();

  const onUploadPress = useCallback(async () => {
    setErrorText(null);
    const fileRes = await getVerificationFileFromFs();
    if (fileRes.success) {
      const {fileName, uri} = fileRes.right;
      onUpload(await uriToFileData(uri), type, fileName);
    } else {
      setErrorText(handleVerificationFileError(fileRes.left) ?? null);
    }
  }, [onUpload, type, handleVerificationFileError]);

  return (
    <>
      <HeaderTag>
        <BackButton onPress={onBack} testID="back_scrn_btn" />
        <HeaderTitleTag>
          {strings['profileSettingsScreen.verification.upload.title']}
        </HeaderTitleTag>
      </HeaderTag>
      <BodyTag>
        <DescriptionText>
          {strings['profileSettingsScreen.verification.upload.topText']}
        </DescriptionText>
        <TitleText>
          {strings['profileSettingsScreen.verification.upload.formTitle']}
        </TitleText>
        {doc?.files?.length && (
          <BorderedView>
            {doc.files.map((file, idx) => (
              <UploadedResultItem
                key={file.name}
                file={file.name}
                isFirst={idx === 0}
                uploadedAt={file.created_at}
                onDelete={() => {
                  onDeleteFile(doc.id, file);
                }}
              />
            ))}
          </BorderedView>
        )}
        {errorText && (
          <DescriptionStyled testID="upload_error_text">
            {errorText}
          </DescriptionStyled>
        )}
        {!doc || fileCanBeUploadedForDocument(doc) ? (
          <UploadButton
            testID="documents_upload_btn"
            title={strings['profileSettingsScreen.verification.upload.submit']}
            Icon={UploadSvg}
            iconPosition={ButtonIconPosition.Right}
            variant={ButtonVariant.PrimaryLight}
            onPress={onUploadPress}
          />
        ) : (
          <MaxDocumentView>
            <MaxDocumentText>{strings['DocumentsScreen.text']}</MaxDocumentText>
          </MaxDocumentView>
        )}
      </BodyTag>
    </>
  );
});

const BorderedView = variance(View)(theme => ({
  root: {
    paddingHorizontal: 7,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: theme.palette.uiSecondary,
    overflow: 'hidden',
  },
}));

const TitleText = variance(Text)(theme => ({
  root: {
    marginBottom: 20,
    ...theme.fontByWeight('700'),
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.textMain,
  },
}));

const DescriptionStyled = variance(Description)(theme => ({
  root: {
    color: theme.palette.error,
    paddingTop: 20,
    fontSize: 15,
  },
}));

const DescriptionText = variance(Text)(theme => ({
  root: {
    marginBottom: 20,
    ...theme.fontByWeight('500'),
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.textAdditional2,
  },
}));

const UploadButton = variance(Button)(() => ({
  root: {
    marginTop: 'auto',
  },
}));

const MaxDocumentView = variance(View)(() => ({
  root: {
    marginTop: 20,
    alignItems: 'center',
  },
}));

const MaxDocumentText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    fontSize: 14,
    color: theme.palette.textMain,
  },
}));
