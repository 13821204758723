import {useStrings, variance} from '@ncwallet-app/core';
import type {TwoFaProviderKind} from '@ncwallet-app/core/src/TwoFaHelper';
import {LG_BREAKPOINT, useIsDimensions} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

import TwoFaProviderListItem from './TwoFaProviderListItem';

export type TwoFaProviderListProps = {
  testID?: string;
  selectedTwoFaProvider: TwoFaProviderKind | undefined;
  providers: TwoFaProviderKind[];
  onTwoFaProviderSelect: (kind: TwoFaProviderKind) => void;
};

export default observer(function TwoFaProviderList(
  props: TwoFaProviderListProps,
) {
  const {selectedTwoFaProvider, providers, onTwoFaProviderSelect} = props;
  const isLg = useIsDimensions('lg');
  const strings = useStrings();

  return (
    <RootView>
      <DescriptionText>{strings['twoFaSelectScreen.text']}</DescriptionText>
      {!isLg && <Divider />}
      <ListView>
        {providers.map((it, idx) => {
          const isCurrent = !!(
            selectedTwoFaProvider && selectedTwoFaProvider === it
          );
          return (
            <React.Fragment key={it}>
              <TwoFaProviderListItem
                kind={it}
                isCurrent={isCurrent}
                onPress={
                  isCurrent
                    ? null
                    : () => {
                        onTwoFaProviderSelect(it);
                      }
                }
              />
              {idx !== providers.length - 1 && <Divider />}
            </React.Fragment>
          );
        })}
      </ListView>
      {!isLg && <Divider />}
    </RootView>
  );
});

const RootView = variance(View)(() => ({
  root: {},
}));

const Divider = variance(View)(theme => ({
  root: {
    width: '100%',
    height: 1,
    backgroundColor: theme.palette.uiSecondary,
  },
}));

const DescriptionText = variance(Text)(theme => ({
  root: {
    paddingVertical: 30,
    paddingHorizontal: 15,
    color: theme.palette.textMain,
    ...theme.fontByWeight('400'),
    fontSize: 16,
  },
}));

const ListView = variance(View)(theme => ({
  root: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        marginHorizontal: 15,
        backgroundColor: theme.palette.uiPrimary,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: theme.palette.uiSecondary,
      },
    }),
  },
}));
