import {useBoolean} from '@ncwallet-app/core';
import {Button, ButtonVariant} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';

import ComponentWithError from './ComponentWithError';

export default observer(function DebugScreen() {
  const [visibleErrorComponent, showErrorComponent] = useBoolean(false);

  return (
    <>
      <Button
        title="Raise an error in a React component"
        onPress={showErrorComponent}
        variant={ButtonVariant.Danger}
      />
      {visibleErrorComponent && <ComponentWithError />}
    </>
  );
});
