import type {TelegramCredentials} from '@ncwallet-app/core';
import {
  useBoolean,
  useRoot,
  useStrings,
  useTheme,
  variance,
} from '@ncwallet-app/core';
import {UserCardIcon} from '@ncwallet-app/core/src/Components/UserCardIcon';
import {Button, ButtonVariant} from '@ncwallet-app/ui';
import {TelegramSvg} from '@ncwallet-app/ui/src/assets/svg/colorless/';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo} from 'react';
import {Platform, Text, View} from 'react-native';

import type {LinkToTelegramModalProps} from './LinkToTelegramModal';
import TelegramWidget from './TelegramWidget';

export type LinkToTelegramScreenBodyProps = LinkToTelegramModalProps & {
  inBottomSheet: boolean;
};

export default observer(function LinkToTelegramScreenBody({
  telegramBotId,
  telegramOauthOrigin,
  redirectUriForTelegramLinking,
  inBottomSheet,
  onClose,
}: LinkToTelegramScreenBodyProps) {
  const strings = useStrings();
  const theme = useTheme();
  const [visibleModal, showModal, hideModal] = useBoolean(false);
  const mainBlockStyle = useMemo(() => {
    return inBottomSheet ? {flex: 1} : {};
  }, [inBottomSheet]);
  const root = useRoot();
  const onLinkAccountPress = useCallback(async () => {
    if (Platform.OS !== 'web') {
      showModal();
      return;
    }
    try {
      const credentials = await root.telegramCredentialsProvider.signIn();
      await root.linkToTelegram.linkToTelegram(credentials);
      onClose();
    } catch {
      /* empty */
    }
  }, [
    onClose,
    root.linkToTelegram,
    root.telegramCredentialsProvider,
    showModal,
  ]);
  const onLogged = useCallback(
    async (_: TelegramCredentials) => {
      await root.linkToTelegram.linkToTelegram(_);
      hideModal();
      onClose();
    },
    [hideModal, onClose, root.linkToTelegram],
  );

  return (
    <>
      <MainBlock style={mainBlockStyle}>
        <UserCardIcon
          secondaryIconColor={'#28abee'}
          Icon={
            <TelegramSvg width={24} height={24} color={theme.palette.whitey} />
          }
        />
        <Title>{strings['linkToTelegram.title']}</Title>
        <Description>
          {strings['linkToTelegram.toTelegramDescription1']}{' '}
          <BoldDescription>
            {strings['linkToTelegram.toTelegramDescription2']}
          </BoldDescription>
        </Description>
      </MainBlock>
      <TelegramWidget
        botId={telegramBotId}
        origin={telegramOauthOrigin}
        returnTo={redirectUriForTelegramLinking}
        onLogged={onLogged}
        visible={visibleModal}
        onRequestClose={hideModal}
      />
      <Buttons>
        <ButtonStyled
          testID="link_tg_btn"
          title={strings['linkToTelegram.linkAccount']}
          variant={ButtonVariant.Primary}
          onPress={onLinkAccountPress}
        />
      </Buttons>
    </>
  );
});

const MainBlock = variance(View)(() => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
}));

const Title = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    fontSize: 26,
    lineHeight: 36,
    marginBottom: 5,
  },
}));

const Description = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    color: theme.palette.textAdditional1,
    fontSize: 16,
    lineHeight: 26,
    textAlign: 'center',
  },
}));

const BoldDescription = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textAdditional1,
    fontSize: 16,
    lineHeight: 26,
  },
}));

const Buttons = variance(View)(() => ({
  root: {
    paddingVertical: 20,
    paddingHorizontal: 15,
    gap: 10,
    alignItems: 'center',
  },
}));

const ButtonStyled = variance(Button)(() => ({
  root: {
    minWidth: 345,
  },
}));
