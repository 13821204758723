import {createStylesHook, useStrings, variance} from '@ncwallet-app/core';
import type {BaseSafeAreaProps} from '@ncwallet-app/ui';
import {SafeAreaSectionList, Separator} from '@ncwallet-app/ui';
import {isNil} from 'lodash';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo} from 'react';
import type {SectionListRenderItemInfo} from 'react-native';

import LimitsSettingsEmptySearch from '../../shared/LimitsSettingsEmptySearch';
import type {WalletItemData} from '../../shared/WalletItem';
import WalletItem from '../../shared/WalletItem';
import WalletLimitItem from '../../shared/WalletLimitItem';
import type {WalletLimitsItemData} from '../../shared/WalletLimitsItemData';
import {SettingsGroupTitle} from '../UserSettingsScreens/SharedComponents/SettingsGroupTitle';

type SectionListDataItem =
  | {
      isLimit: true;
      item: WalletLimitsItemData;
    }
  | {
      isLimit: false;
      item: WalletItemData;
    };

export type FullListItemProps = {
  title: string;
  data: SectionListDataItem[];
};

export type LimitsSettingsListProps = BaseSafeAreaProps & {
  searchValue: string | undefined;
  limitsData: WalletLimitsItemData[];
  walletsWithoutLimitsData: WalletItemData[];
  onLimitPress: (l: WalletLimitsItemData) => void;
  onWalletPress: (w: WalletItemData) => void;
};

export default observer(function LimitsSettingsList(
  props: LimitsSettingsListProps,
) {
  const {
    searchValue,
    limitsData,
    walletsWithoutLimitsData,
    onLimitPress,
    onWalletPress,
    ...rest
  } = props;
  const strings = useStrings();
  const fullListItem: FullListItemProps[] = useMemo(
    () => [
      {
        index: 0,
        title: strings['limitsSettingsScreen.activeTitle'],
        data: limitsData.map(d => ({isLimit: true, item: d})),
      },
      {
        index: 1,
        title: strings['limitsSettingsScreen.noSetTitle'],
        data: walletsWithoutLimitsData.map(d => ({isLimit: false, item: d})),
      },
    ],
    [limitsData, strings, walletsWithoutLimitsData],
  );

  const styles = useStyles();

  const renderItem = useCallback(
    (
      info: SectionListRenderItemInfo<
        SectionListDataItem,
        {title: string; index?: number}
      >,
    ) => {
      const {item} = info;
      const isLast = info.index === info.section.data.length - 1;
      const isFirst = info.index === 0;
      if (item.isLimit) {
        return (
          <WalletLimitItem
            data={item.item}
            isLast={isLast}
            isFirst={isFirst}
            onPress={() => {
              onLimitPress(item.item);
            }}
          />
        );
      } else {
        return (
          <WalletItem
            isLast={isLast}
            isFirst={isFirst}
            data={item.item}
            onPress={() => {
              onWalletPress(item.item);
            }}
          />
        );
      }
    },
    [onLimitPress, onWalletPress],
  );

  if (
    !isNil(searchValue) &&
    !limitsData.length &&
    !walletsWithoutLimitsData.length
  ) {
    return <LimitsSettingsEmptySearch searchValue={searchValue} />;
  }

  return (
    <SafeAreaSectionList
      sections={fullListItem}
      keyExtractor={item => item.item.walletId}
      stickySectionHeadersEnabled={false}
      renderSectionHeader={({section}) => {
        return section.data.length ? (
          <SettingsGroupTitle
            title={section.title}
            first={section.index === 0}
          />
        ) : null;
      }}
      renderItem={renderItem}
      ItemSeparatorComponent={LineSeparator}
      contentContainerStyle={styles.root}
      {...rest}
    />
  );
});

const useStyles = createStylesHook(theme => ({
  root: {
    backgroundColor: theme.palette.uiPrimary,
    padding: 16,
  },
}));

const LineSeparator = variance(Separator)(() => ({
  root: {
    marginRight: 0,
    marginLeft: 0,
  },
}));
