import {TransactionFilterKind, useStrings, variance} from '@ncwallet-app/core';
import type {BaseSafeAreaProps} from '@ncwallet-app/ui';
import {LG_BREAKPOINT, TransactionFilterIcon} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

import {
  HistoryItemColumn,
  HistoryItemTitle,
} from '../HistoryScreen/SharedComponents';
import HistoryFilterKindContainer from './HistoryFilterKindContainer';
import HistoryFilterKindItemContainer from './HistoryFilterKindItemContainer';

export type HistoryFilterKindScreenProps = BaseSafeAreaProps & {
  onSelect: (kind: TransactionFilterKind) => void;
  onBack: () => void;
};
export default observer(function HistoryFilterKindScreen(
  props: HistoryFilterKindScreenProps,
) {
  const {onSelect, onBack, ...rest} = props;
  const strings = useStrings();

  return (
    <HistoryFilterKindContainer onBack={onBack} {...rest}>
      <HeaderRoot>
        <GroupTitle>
          {strings['historyOperationFilterScreen.title']}:
        </GroupTitle>
      </HeaderRoot>

      <HistoryFilterKindItemContainer
        onPress={() => {
          onSelect(TransactionFilterKind.All);
        }}
        testID="filter_trans_all"
        wrap>
        <TransactionFilterIcon kind={TransactionFilterKind.All} size={40} />
        <HistoryItemColumn isRight>
          <HistoryItemTitle isRight>
            {strings['historyOperationFilterScreen.operation.all']}
          </HistoryItemTitle>
        </HistoryItemColumn>
      </HistoryFilterKindItemContainer>

      <Row>
        <HistoryFilterKindItemContainer
          testID="filter_trans_deposit"
          onPress={() => {
            onSelect(TransactionFilterKind.Incoming);
          }}>
          <TransactionFilterIcon
            kind={TransactionFilterKind.Incoming}
            size={40}
          />
          <HistoryItemColumn isRight>
            <HistoryItemTitle isRight>
              {strings['historyOperationFilterScreen.operation.deposit']}
            </HistoryItemTitle>
          </HistoryItemColumn>
        </HistoryFilterKindItemContainer>

        <HistoryFilterKindItemContainer
          testID="filter_trans_withdrawal"
          onPress={() => {
            onSelect(TransactionFilterKind.Withdraw);
          }}>
          <TransactionFilterIcon
            kind={TransactionFilterKind.Withdraw}
            size={40}
          />
          <HistoryItemColumn isRight>
            <HistoryItemTitle isRight>
              {strings['historyOperationFilterScreen.operation.widthrawal']}
            </HistoryItemTitle>
          </HistoryItemColumn>
        </HistoryFilterKindItemContainer>

        <HistoryFilterKindItemContainer
          testID="filter_trans_incoming"
          onPress={() => {
            onSelect(TransactionFilterKind.To);
          }}>
          <TransactionFilterIcon kind={TransactionFilterKind.To} size={40} />
          <HistoryItemColumn isRight>
            <HistoryItemTitle isRight>
              {strings['historyOperationFilterScreen.operation.incoming']}
            </HistoryItemTitle>
          </HistoryItemColumn>
        </HistoryFilterKindItemContainer>

        <HistoryFilterKindItemContainer
          testID="filter_trans_outgoing"
          onPress={() => {
            onSelect(TransactionFilterKind.From);
          }}>
          <TransactionFilterIcon kind={TransactionFilterKind.From} size={40} />
          <HistoryItemColumn isRight>
            <HistoryItemTitle isRight>
              {strings['historyOperationFilterScreen.operation.outgoing']}
            </HistoryItemTitle>
          </HistoryItemColumn>
        </HistoryFilterKindItemContainer>

        <HistoryFilterKindItemContainer
          testID="filter_trans_exchanged"
          onPress={() => {
            onSelect(TransactionFilterKind.Exchange);
          }}>
          <TransactionFilterIcon
            kind={TransactionFilterKind.Exchange}
            size={40}
          />
          <HistoryItemColumn isRight>
            <HistoryItemTitle isRight>
              {strings['historyOperationFilterScreen.operation.exchange']}
            </HistoryItemTitle>
          </HistoryItemColumn>
        </HistoryFilterKindItemContainer>

        <HistoryFilterKindItemContainer
          testID="filter_trans_rollback"
          onPress={() => {
            onSelect(TransactionFilterKind.Rollback);
          }}>
          <TransactionFilterIcon
            kind={TransactionFilterKind.Rollback}
            size={40}
          />
          <HistoryItemColumn isRight>
            <HistoryItemTitle isRight>
              {strings['historyOperationFilterScreen.operation.rollback']}
            </HistoryItemTitle>
          </HistoryItemColumn>
        </HistoryFilterKindItemContainer>
      </Row>
    </HistoryFilterKindContainer>
  );
});

const Row = variance(View)(theme => ({
  root: {
    width: '100%',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flexDirection: 'row',
        flexWrap: 'wrap',
      },
    }),
  },
}));

const HeaderRoot = variance(View)(theme => ({
  root: {
    backgroundColor: theme.palette.background,
    paddingTop: 20,
    padding: 15,
    borderBottomColor: theme.palette.uiSecondary,
    borderBottomWidth: 1,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        display: 'none',
      },
    }),
  },
}));

const GroupTitle = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 18,
    lineHeight: 22,
    color: theme.palette.textPrimary,
  },
}));
