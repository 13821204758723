import {variance} from '@ncwallet-app/core';
import {LG_BREAKPOINT} from '@ncwallet-app/core/src/Layout';
import {Text, View} from 'react-native';

export const BottomContainer = variance(View)(() => ({
  root: {
    marginTop: 'auto',
  },
}));

export const ErrorTitle = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    textAlign: 'left',
    marginBottom: 20,
    marginTop: 'auto',
    fontSize: 13,
    lineHeight: 20,
    color: theme.palette.error,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        textAlign: 'center',
      },
    }),
  },
  withoutOffset: {
    marginTop: 0,
  },
}));
