import {BigNumber} from 'bignumber.js';

import type {AddressHistoryRepository} from '../AddressHistoryRepository';
import type {AddressParser, Receipt} from '../AddressParser';
import type {BaseAsyncOptions} from '../Async';
import type {
  ListWalletsRoute,
  PromptCryptoToSendRoute,
  PromptOutputAddressRoute,
  RedirectToSendRouteParams,
  ShallowCommonState,
} from '../CommonNavigationScheme';
import {
  LIST_QR_CODE_HISTORY_ROUTE,
  LIST_WALLETS_ROUTE,
  PROMPT_CRYPTO_TO_SEND_ROUTE,
  PROMPT_OUTPUT_ADDRESS_ROUTE,
} from '../CommonNavigationScheme';
import type {CurrencyStore, WalletStore} from '../dataStores';
import type {CancellationError} from '../Error';
import {CANCELLATION_ERROR} from '../Error';
import type {ErrorRepository} from '../ErrorRepository';
import type {Either} from '../fp';
import {error, success} from '../fp';
import type {JsonRpcClient} from '../JsonRpc';
import {CRYPTO_CODE_TO_PROTOCOL_MAP} from '../LinkingOptionsProvider/constant';
import type {Maybe} from '../Maybe';
import type {CryptoCurrencyCode} from '../Money';
import type {
  CryptoAddress,
  CryptoCurrency,
  NCWalletCallScheme,
  NCWalletNotificationScheme,
  Wallet,
} from '../NCWalletServer';
import {getDefaultInOutCurrency} from '../NCWalletServer';
import type {AddressNetwork} from '../NCWalletServer/AddressInfo';
import {getDefaultAddressParams} from '../NCWalletServer/InOutCurrency';
import {
  AddressRedirectError,
  shouldStoreAddressOnError,
  toAddressRedirectError,
} from './AddressRedirectError';
import type {AddressUriHelper} from './AddressUriHelper';
import {SendAddressValidator} from './SendAddressValidator';

export default class AddressUriHelperImpl implements AddressUriHelper {
  private readonly addressValidator: SendAddressValidator;
  private readonly _WEI_TO_ETH = 10 ** 18;

  constructor(
    private readonly _root: {
      readonly errorRepository: ErrorRepository;
      readonly addressHistoryRepository: AddressHistoryRepository;
      readonly walletStore: WalletStore;
      readonly currencyStore: CurrencyStore;
      readonly ncWalletJsonRpcClient: JsonRpcClient<
        NCWalletCallScheme,
        NCWalletNotificationScheme
      >;
      readonly addressParser: AddressParser;
    },
  ) {
    this.addressValidator = new SendAddressValidator(_root);
  }

  async prepareRedirection(
    params: RedirectToSendRouteParams,
    options?: BaseAsyncOptions,
  ): Promise<Maybe<ShallowCommonState>> {
    if (options?.signal?.aborted) {
      return error(this._createCancellationError(options.signal.reason));
    }

    let receipt = {
      ...(params.addressNetwork && {network: params.addressNetwork}),
    } as Partial<Receipt>;

    if (params.externalFrom) {
      await this._root.currencyStore.refreshCryptoCurrencies();
      receipt = this._root.addressParser.parse(params.externalFrom);

      if (!receipt.kind && !receipt.finished) {
        return this._handleRedirectError(
          {
            address: (receipt.address ?? params.address) as CryptoAddress,
            crypto: params.crypto,
          },
          AddressRedirectError.NoCryptoCode,
          options,
        );
      }
    }

    const [cryptoRes, walletRes] = await Promise.all([
      this.getCrypto(receipt.code ?? params.crypto, options),
      this.getWallet(
        receipt.address ?? params.address,
        receipt.code ?? params.crypto,
        options,
        receipt.network ?? params.addressNetwork,
      ),
    ]);

    if (!cryptoRes.success) {
      return this._handleRedirectError(params, cryptoRes.left, options);
    }
    if (!walletRes.success) {
      return this._handleRedirectError(params, walletRes.left, options);
    }

    const add_ = await this._root.addressHistoryRepository.add(
      translateReceiptToUri(params),
      options,
    );
    if (!add_.success) {
      return add_;
    }
    const currencyOut = getDefaultInOutCurrency(cryptoRes.right, 'out');

    const zeroth: ListWalletsRoute = {kind: LIST_WALLETS_ROUTE};

    const first: PromptCryptoToSendRoute = {
      kind: PROMPT_CRYPTO_TO_SEND_ROUTE,
    };

    const second: PromptOutputAddressRoute = {
      kind: PROMPT_OUTPUT_ADDRESS_ROUTE,
      params: {
        ...getDefaultAddressParams(currencyOut, receipt),
        amount: receipt.amount ?? params.amount,
        walletId: walletRes.right.id,
        addressTo: receipt.address ?? params.address,
        minFreeWithdrawAmount:
          params.crypto === 'BTC'
            ? getCheckedMinFreeWithdrawalAmount(params.minFreeWithdrawAmount)
            : undefined,
      },
    };

    const state: ShallowCommonState = [
      {route: zeroth},
      {route: first},
      {route: second},
    ];
    return success(state);
  }

  private async _handleRedirectError(
    params: RedirectToSendRouteParams,
    _: AddressRedirectError,
    options?: BaseAsyncOptions,
  ): Promise<Maybe<ShallowCommonState>> {
    if (options?.signal?.aborted) {
      return error(this._createCancellationError(options.signal.reason));
    }
    if (shouldStoreAddressOnError(_)) {
      const add_ = await this._root.addressHistoryRepository.add(
        translateReceiptToUri(params),
        options,
      );
      if (!add_.success) {
        return add_;
      }
    }
    return success([
      {route: {kind: LIST_WALLETS_ROUTE}},
      {
        route: {
          kind: LIST_QR_CODE_HISTORY_ROUTE,
          params: {address: params.address, error: _},
        },
      },
    ]);
  }

  async getCrypto<C extends CryptoCurrencyCode>(
    code?: C,
    options?: BaseAsyncOptions,
  ) {
    if (options?.signal?.aborted) {
      return error(AddressRedirectError.Aborted);
    }
    if (!code) {
      return error(AddressRedirectError.NoCryptoCode);
    }
    const cryptosRes =
      await this._root.currencyStore.refreshCryptoCurrencies(options);

    if (!cryptosRes.success) {
      return error(AddressRedirectError.NetworkError);
    }

    const crypto = cryptosRes.right.find(c => c.code === code);

    if (!crypto || !crypto.options.withdraw) {
      return error(AddressRedirectError.UnsupportedCrypto);
    }

    return success(crypto as CryptoCurrency<C>);
  }

  async getWallet<C extends CryptoCurrencyCode>(
    address: string,
    code?: C,
    options?: BaseAsyncOptions,
    network?: AddressNetwork,
  ): Promise<Either<Wallet<C>, AddressRedirectError>> {
    if (options?.signal?.aborted) {
      return error(AddressRedirectError.Aborted);
    }

    if (!code) {
      return error(AddressRedirectError.NoCryptoCode);
    }

    const walletsRes = await this._root.walletStore.refreshWallets(options);

    if (!walletsRes.success) {
      return error(AddressRedirectError.NetworkError);
    }

    const wallets = walletsRes.right.filter(w => w.currency === code);

    if (wallets.length > 1) {
      return error(AddressRedirectError.MultipleWallets);
    }
    if (wallets.length === 0) {
      return error(AddressRedirectError.NoWallet);
    }
    const wallet = wallets[0];

    const addressError = await this.addressValidator.validate(
      address,
      code,
      wallet.addresses.map(a => a.address),
      network,
      options,
    );

    if (addressError) {
      return error(toAddressRedirectError(addressError));
    }

    return success(wallet as Wallet<C>);
  }

  private _createCancellationError(cause?: unknown) {
    return this._root.errorRepository.create<CancellationError>({
      kind: CANCELLATION_ERROR,
      raw: cause,
    });
  }
}

function getCheckedMinFreeWithdrawalAmount(amount?: string) {
  return amount && amount === 'null'
    ? '0'
    : amount && BigNumber(amount).isPositive()
      ? amount
      : undefined;
}

function translateReceiptToUri(params: RedirectToSendRouteParams) {
  const protocol = params.crypto
    ? CRYPTO_CODE_TO_PROTOCOL_MAP[params.crypto]
    : '';
  const query = params.amount ? `?amount=${params.amount}` : '';
  return `${protocol}${params.address}${query}`;
}
