import {VAPID_KEY} from '@ncwallet-app/browser/src/Messaging/constants';
import type {FcmToken, Service} from '@ncwallet-app/core/src';
import type {MessagingIdentity} from '@ncwallet-app/core/src/MessagingIdentity';
import type {NotificationPermissions} from '@ncwallet-app/core/src/NotificationPermissions';
import {getMessaging, getToken} from 'firebase/messaging';
import {autorun, makeObservable, observable, runInAction} from 'mobx';

export default class WebMessagingIdentityService
  implements MessagingIdentity, Service
{
  constructor(
    private readonly _root: {
      readonly notificationPermissions: NotificationPermissions;
    },
  ) {
    makeObservable(this);
  }

  @observable private _token?: FcmToken;

  get token() {
    return this._token;
  }

  private async _initialize() {
    if (!('serviceWorker' in navigator)) {
      return;
    }
    const messaging = getMessaging();
    const serviceWorkerRegistration =
      await navigator.serviceWorker.register('/sw.js');
    const token = await getToken(messaging, {
      vapidKey: VAPID_KEY,
      serviceWorkerRegistration,
    });
    runInAction(() => {
      this._token = token as FcmToken;
    });
  }

  private _reinitializeWhenGrantedPermission() {
    return autorun(() => {
      if (
        this._token === undefined &&
        this._root.notificationPermissions.granted
      ) {
        void this._initialize();
      }
    });
  }

  subscribe() {
    return this._reinitializeWhenGrantedPermission();
  }
}
