import {type FilterOption, useStrings, variance} from '@ncwallet-app/core';
import {
  BaseSkeleton,
  Button,
  ButtonVariant,
  TouchableOpacity,
  useIsDimensions,
} from '@ncwallet-app/ui';
import {
  ArrowDownWideSvg,
  PlusSvg,
} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Modal, Text, View} from 'react-native';

import ListWalletOptions from '../ListWalletOptionsScreen/ListWalletOptions';

export type CurrencyListHeaderProps = {
  getWalletCount: () => number | undefined;
  onAddCurrencyPress: () => void;
  isFilterOpened?: boolean;
  selectedFilterOption: FilterOption;
  handleFilterOptionPress?: (filter: FilterOption) => void;
  onFilterPress: (() => void) | null;
  onBuyCrypto: () => void;
};

export const WalletListHeader = observer(function WalletListHeader(
  props: CurrencyListHeaderProps,
) {
  const strings = useStrings();
  const total = props.getWalletCount();
  const isLg = useIsDimensions('lg');
  const containerRef = useRef<View>(null);
  const [filterPosition, setFilterPosition] = useState({x: 0, y: 0});

  const handleTitlePress = useCallback(() => {
    if (props.onFilterPress) {
      props.onFilterPress();
    }
  }, [props]);

  useEffect(() => {
    if (props.isFilterOpened) {
      containerRef.current?.measure((_, __, ___, ____, pX, pY) => {
        setFilterPosition({x: pX, y: pY});
      });
    }
  }, [props.isFilterOpened]);

  return (
    <Container ref={containerRef} collapsable={false}>
      <TitlePressable
        onPress={handleTitlePress}
        disabled={!props.onFilterPress}>
        {total !== undefined ? (
          <>
            {!props.onFilterPress ? (
              <TitleText>{strings['coinFilter.All']}</TitleText>
            ) : (
              <FilterTitle filteredOption={props.selectedFilterOption} />
            )}
            <TotalContainer>
              <Total>{total}</Total>
            </TotalContainer>
            {props.onFilterPress && <ChevronIcon />}
          </>
        ) : (
          <TotalPlaceholder />
        )}
      </TitlePressable>
      {isLg && props.isFilterOpened && filterPosition.x !== 0 && (
        <Modal statusBarTranslucent transparent>
          <FilterPopupTouchableOverlay onPress={handleTitlePress}>
            <FilterPopupView
              style={{
                left: filterPosition.x + 15,
                top: filterPosition.y + 50,
              }}>
              <ListWalletOptions
                onFilterOptionPress={filter => {
                  if (props.handleFilterOptionPress) {
                    props.handleFilterOptionPress(filter);
                  }
                }}
                selectedFilterOption={props.selectedFilterOption}
              />
            </FilterPopupView>
          </FilterPopupTouchableOverlay>
        </Modal>
      )}
      <ButtonsWrapper>
        <AddCurrencyBtn
          testID="create_wallet_btn"
          Icon={PlusSvg}
          onPress={props.onAddCurrencyPress}
          variant={ButtonVariant.PrimaryLight}
        />
        <Btn
          testID="buy_currency_btn"
          title={strings['buyButton']}
          onPress={props.onBuyCrypto}
          variant={ButtonVariant.Primary}
        />
      </ButtonsWrapper>
    </Container>
  );
});

const FilterTitle = observer(function (props: {filteredOption: FilterOption}) {
  const {filteredOption} = props;
  const strings = useStrings();
  if (filteredOption === 'all') {
    return (
      <TitleText testID="main_coins_filter">
        {strings['coinFilter.All']}
      </TitleText>
    );
  }

  if (filteredOption === 'positive_funds') {
    return <TitleText>{strings['coinFilter.withFunds']}</TitleText>;
  }
  return <TitleText>{strings['walletsScreen.currencyListTitle']}</TitleText>;
});

const Container = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 15,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.uiSecondary,
    zIndex: 5,
  },
}));

const TitlePressable = variance(TouchableOpacity)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const TitleText = variance(Text)(theme => ({
  root: {
    color: theme.palette.textMain,
    ...theme.fontByWeight('bold'),
    fontSize: 18,
  },
}));

const TotalContainer = variance(View)(() => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 0,
    marginLeft: 6,
    borderRadius: 20,
  },
}));

const Total = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('bold'),
    color: theme.palette.textAdditional3,
    fontSize: 14,
  },
}));

const TotalPlaceholder = variance(BaseSkeleton)(() => ({
  root: {
    marginTop: 3,
    marginLeft: 5,
    width: 120,
    height: 20,
    borderRadius: 10,
  },
}));

const ButtonsWrapper = variance(View)(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
  },
}));

const AddCurrencyBtn = variance(Button)(() => ({
  root: {
    paddingVertical: 10,
  },
}));

const Btn = variance(Button)(() => ({
  root: {
    paddingVertical: 10,
    minWidth: 80,
  },
}));

const FilterPopupTouchableOverlay = variance(TouchableOpacity)(() => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
}));

const FilterPopupView = variance(View)(theme => ({
  root: {
    position: 'absolute',
    width: 350,
    borderRadius: 6,
    borderColor: theme.palette.uiSecondary,
    shadowColor: theme.palette.blackout,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.15,
    shadowRadius: 10,
    backgroundColor: theme.palette.uiAdditional2,
    overflow: 'hidden',
    zIndex: 1000,
  },
}));

const ChevronIcon = variance(ArrowDownWideSvg)(
  () => ({
    root: {},
  }),
  theme => ({
    color: theme.palette.uiSecondary,
    width: 22,
    height: 17,
  }),
);
