import type {Millisecond} from '../Time';
import type {Uri, Url} from '../units';

export type Env = {
  NC_WALLET_SENTRY_DSN_CHROME_EXTENSION?: string;
  NC_WALLET_SENTRY_DSN_ANDROID?: string;
  NC_WALLET_SENTRY_DSN_IOS?: string;
  NC_WALLET_SENTRY_DSN_WEB?: string;
  NC_WALLET_SENTRY_DSN_TELEGRAM?: string;

  NC_WALLET_SITE_URL: Url;
  NC_WALLET_REST_API_URL: Url;
  NC_WALLET_REST_API_TIMEOUT: Millisecond;
  NC_WALLET_JSON_RPC_URL: Url;
  NC_WALLET_JSON_RPC_TIMEOUT: Millisecond;
  NC_WALLET_GOOGLE_MOBILE_OAUTH_CLIENT_ID: string;
  NC_WALLET_GOOGLE_WEB_OAUTH_CLIENT_ID: string;
  NC_WALLET_APPLE_OAUTH_REDIRECT_URI: Uri;
  NC_WALLET_APPLE_OAUTH_CLIENT_ID: string;
  NC_WALLET_TELEGRAM_BOT_ID: string;
  NC_WALLET_TELEGRAM_BOT_NAME: string;
  NC_WALLET_TELEGRAM_OAUTH_ORIGIN: string;
  NC_WALLET_MINI_APP_URL: string;
  NC_WALLET_GOOGLE_REDIRECT_URI_FOR_EMILE_LINKING: string;
  NC_WALLET_APPLE_REDIRECT_URI_FOR_EMILE_LINKING: string;
};

const DEFAULT_TIMEOUT = 1000;

export const env = {
  NC_WALLET_SENTRY_DSN_CHROME_EXTENSION:
    process.env.NC_WALLET_SENTRY_DSN_CHROME_EXTENSION,
  NC_WALLET_SENTRY_DSN_ANDROID: process.env.NC_WALLET_SENTRY_DSN_ANDROID,
  NC_WALLET_SENTRY_DSN_IOS: process.env.NC_WALLET_SENTRY_DSN_IOS,
  NC_WALLET_SENTRY_DSN_WEB: process.env.NC_WALLET_SENTRY_DSN_WEB,
  NC_WALLET_SENTRY_DSN_TELEGRAM: process.env.NC_WALLET_SENTRY_DSN_TELEGRAM,

  NC_WALLET_SITE_URL: process.env.NC_WALLET_SITE_URL as Url,
  NC_WALLET_REST_API_URL: process.env.NC_WALLET_REST_API_URL as Url,
  NC_WALLET_REST_API_TIMEOUT: parseFinite(
    process.env.NC_WALLET_REST_API_TIMEOUT,
    DEFAULT_TIMEOUT,
  ) as Millisecond,
  NC_WALLET_JSON_RPC_URL: process.env.NC_WALLET_JSON_RPC_URL as Url,
  NC_WALLET_JSON_RPC_TIMEOUT: parseFinite(
    process.env.NC_WALLET_JSON_RPC_TIMEOUT,
    DEFAULT_TIMEOUT,
  ) as Millisecond,
  NC_WALLET_GOOGLE_MOBILE_OAUTH_CLIENT_ID:
    process.env.NC_WALLET_GOOGLE_MOBILE_OAUTH_CLIENT_ID ?? '',
  NC_WALLET_GOOGLE_WEB_OAUTH_CLIENT_ID:
    process.env.NC_WALLET_GOOGLE_WEB_OAUTH_CLIENT_ID ?? '',
  NC_WALLET_APPLE_OAUTH_REDIRECT_URI: (process.env
    .NC_WALLET_APPLE_OAUTH_REDIRECT_URI ?? '') as Url,
  NC_WALLET_APPLE_OAUTH_CLIENT_ID:
    process.env.NC_WALLET_APPLE_OAUTH_CLIENT_ID ?? '',
  NC_WALLET_TELEGRAM_BOT_ID: process.env.NC_WALLET_TELEGRAM_BOT_ID ?? '',
  NC_WALLET_TELEGRAM_BOT_NAME: process.env.NC_WALLET_TELEGRAM_BOT_NAME ?? '',
  NC_WALLET_MINI_APP_URL: process.env.NC_WALLET_MINI_APP_URL ?? '',
  NC_WALLET_TELEGRAM_OAUTH_ORIGIN:
    process.env.NC_WALLET_TELEGRAM_OAUTH_ORIGIN ?? '',
  NC_WALLET_GOOGLE_REDIRECT_URI_FOR_EMILE_LINKING:
    process.env.NC_WALLET_GOOGLE_REDIRECT_URI_FOR_EMILE_LINKING ?? '',
  NC_WALLET_APPLE_REDIRECT_URI_FOR_EMILE_LINKING:
    process.env.NC_WALLET_APPLE_REDIRECT_URI_FOR_EMILE_LINKING ?? '',
} satisfies Env;

function parseFinite(input: string | undefined, fallback: number) {
  if (input === undefined || input === '') {
    return fallback;
  }
  const parsed = parseFloat(input);
  return isFinite(parsed) ? parsed : fallback;
}
