import type {WebBrowser} from '.';
import type {Location} from '../Location';
import type {Url} from '../units';

export default class WebBrowserImpl implements WebBrowser {
  constructor(
    protected readonly _root: {
      readonly location: Location;
    },
  ) {}

  async open(url: Url) {
    await this._root.location.open(url);
  }
}
