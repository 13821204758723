import {
  APP_WINDOW_ACTIVE,
  PermissionStatus,
  useRoot,
  variance,
} from '@ncwallet-app/core';
import type {PermissionResponse} from 'expo-camera';
import {isNil} from 'lodash';
import {reaction} from 'mobx';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useState} from 'react';
import {StyleSheet, View} from 'react-native';

import CameraHeader from './CameraHeader';
import CameraLayer from './CameraLayer';
import LabelLayer from './LabelLayer';
import NeedAccess from './NeedAccess';

export type QrCodeScreenProps = {
  getIsFocused: () => boolean;
  getIsTransitioning: () => boolean;
  onCodeScanned: (code: string) => void;
  onHistoryPress: () => void;
  onClose: () => void;
};

export default observer(function QrCodeScreen(props: QrCodeScreenProps) {
  const {
    onClose,
    getIsFocused,
    getIsTransitioning,
    onCodeScanned,
    onHistoryPress,
  } = props;
  const {
    appWindow,
    camera: {requestCameraPermissionsAsync, getCameraPermissionsAsync},
  } = useRoot();
  const [cameraPermissions, setCameraPermissions] =
    useState<PermissionResponse>();
  const requestPermission = useCallback(async () => {
    try {
      const response = await requestCameraPermissionsAsync();
      setCameraPermissions(response);
    } catch {
      /* empty */
    }
  }, [requestCameraPermissionsAsync]);
  useEffect(
    () =>
      reaction(
        () => getIsFocused(),
        async isFocused => {
          if (isFocused) {
            try {
              const response = await getCameraPermissionsAsync();
              setCameraPermissions(response);
              if (
                response.canAskAgain &&
                response.status === PermissionStatus.UNDETERMINED
              ) {
                await requestPermission();
              }
            } catch {
              /* empty */
            }
          }
        },
        {fireImmediately: true},
      ),
    [getCameraPermissionsAsync, getIsFocused, requestPermission],
  );
  useEffect(
    () =>
      appWindow.updates.listen(APP_WINDOW_ACTIVE, async () => {
        try {
          const response = await getCameraPermissionsAsync();
          setCameraPermissions(response);
        } catch {
          /* empty */
        }
      }),
    [appWindow, getCameraPermissionsAsync],
  );

  if (
    isNil(cameraPermissions) ||
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    cameraPermissions.status === PermissionStatus.UNDETERMINED
  ) {
    return (
      <Root>
        <CameraHeader onClose={onClose} />
      </Root>
    );
  }

  return (
    <Root>
      <CameraHeader onClose={onClose} />
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison */}
      {cameraPermissions.status === PermissionStatus.GRANTED ? (
        <View style={styles.camera}>
          <CameraLayer
            visibleCamera={!getIsTransitioning()}
            onBarCodeScanned={onCodeScanned}
          />
          <LabelLayer onHistoryPress={onHistoryPress} />
        </View>
      ) : (
        <NeedAccess
          canAsk={cameraPermissions.canAskAgain}
          requestPermission={requestPermission}
        />
      )}
    </Root>
  );
});

const styles = StyleSheet.create({
  camera: {
    flex: 1,
  },
});

const Root = variance(View)(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.blackout,
    flex: 1,
  },
}));
