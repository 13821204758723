import type {CryptoCurrency, WalletId} from '@ncwallet-app/core';
import {createStylesHook, variance} from '@ncwallet-app/core';
import {CurrencyCircleLogo, TouchableListItem} from '@ncwallet-app/ui/src';
import {PlusSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {type StyleProp, Text, View, type ViewStyle} from 'react-native';

export type WalletWithoutLimitsProps = {
  data: WalletItemData;
  onPress: () => void;
  testID?: string;
  isLast?: boolean;
  isFirst?: boolean;
  style?: StyleProp<ViewStyle>;
  bodyStyle?: StyleProp<ViewStyle>;
};

export type WalletItemData = {
  walletId: WalletId;
  currency: CryptoCurrency;
};

export default observer(function WalletItem(props: WalletWithoutLimitsProps) {
  const {data, onPress, testID, isFirst, isLast, style, bodyStyle} = props;
  const bodyViewStyle = useBodyStyle();

  return (
    <NoSetLimitItemContainer
      style={[
        style,
        isFirst && bodyViewStyle.isFirst,
        isLast && bodyViewStyle.isLast,
      ]}
      onPress={onPress}
      testID={testID}
      bodyStyle={[
        bodyViewStyle.root,
        isFirst && bodyViewStyle.isFirst,
        isLast && bodyViewStyle.isLast,
        bodyStyle,
      ]}>
      <BodyView>
        <NoSetLimitItemIcon>
          <CurrencyCircleLogo code={data.currency.code} size={40} />
        </NoSetLimitItemIcon>
        <NoSetLimitItemBody>
          <NoSetLimitItemTitle>{data.currency.name}</NoSetLimitItemTitle>
        </NoSetLimitItemBody>
        <NoSetLimitRight>
          <PlusIcon />
        </NoSetLimitRight>
      </BodyView>
    </NoSetLimitItemContainer>
  );
});

const NoSetLimitItemContainer = variance(TouchableListItem)(() => ({
  root: {
    overflow: 'hidden',
  },
}));

const useBodyStyle = createStylesHook(theme => ({
  root: {
    backgroundColor: theme.palette.uiAdditional2,
  },
  isLast: {
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
  },
  isFirst: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
}));

const BodyView = variance(View)(() => ({
  root: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15,
  },
}));

const NoSetLimitItemIcon = variance(View)(() => ({
  root: {
    marginRight: 15,
  },
}));

const NoSetLimitItemBody = variance(View)(() => ({
  root: {
    flex: 1,
  },
}));

const NoSetLimitItemTitle = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textMain,
    fontSize: 16,
  },
}));

const NoSetLimitRight = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const PlusIcon = variance(PlusSvg)(
  () => ({
    root: {
      marginLeft: 'auto',
    },
  }),
  theme => ({
    width: 12,
    color: theme.palette.info,
  }),
);
