import {useRoot, useTheme, variance} from '@ncwallet-app/core';
import type {ThemeKind} from '@ncwallet-app/core/src/Appearance';
import type {AvailableLanguageCode} from '@ncwallet-app/core/src/Localization/constant';
import {
  CircleHalfSvg,
  SettingsButtonDecor,
} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {View} from 'react-native';

import {LANGUAGES, LanguageSettingsList} from '../../../../../components';
import WideLayoutBackground from '../../../../../components/WideLayoutBackground';
import SettingButtonBase from './SettingButtonBase';
import type {SettingsBarProps} from './SettingsBar';
import SettingsBar from './SettingsBar';
import SettingsOverlay from './SettingsOverlay';
import ThemeSelect from './WalletLayout/ThemeSelect';

export type SettingsLayoutProps = {
  children: React.ReactNode;
  Header?: React.ReactNode;
  Modals?: React.ReactNode;
} & SettingsBarProps;

export default observer(function SettingsLayout(props: SettingsLayoutProps) {
  const {
    children,
    isLanguageMenuVisible,
    isThemeMenuVisible,
    onMenuClose,
    Header,
    Modals,
  } = props;

  return (
    <Container>
      <WideLayoutBackground>
        {Header}
        <Container>
          <Inner>
            <SettingsBar {...props} />
            <ContentContainer>{children}</ContentContainer>
            {(isLanguageMenuVisible || isThemeMenuVisible) && (
              <SettingsOverlay onMenuClose={onMenuClose}>
                {isLanguageMenuVisible && <LanguageModal {...props} />}
                {isThemeMenuVisible && <ThemeModal {...props} />}
              </SettingsOverlay>
            )}
          </Inner>
          {Modals}
        </Container>
      </WideLayoutBackground>
    </Container>
  );
});

const Inner = variance(View)(() => ({
  root: {
    position: 'relative',
    paddingTop: 20,
    flex: 1,
  },
}));

const Container = variance(View)(() => ({
  root: {
    flex: 1,
  },
}));

const ContentContainer = variance(View)(() => ({
  root: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    overflow: 'visible',
    marginTop: 20,
    maxHeight: 660,
  },
}));

type LanguageModalProps = {
  onMenuClose: () => void;
  onLanguageMenuPress: () => void;
};

const LanguageModal = observer(function LanguageModal(
  props: LanguageModalProps,
) {
  const {onMenuClose} = props;
  const {userPreferenceState} = useRoot();
  const theme = useTheme();

  const LanguageIcon = LANGUAGES[userPreferenceState.languageCode].Icon;

  const onLanguagePress = useCallback(
    (language: AvailableLanguageCode) => {
      void userPreferenceState.setLanguageCode(language);
      onMenuClose();
    },
    [userPreferenceState, onMenuClose],
  );

  return (
    <>
      <SettingButton active language onPress={props.onLanguageMenuPress}>
        <LanguageIcon width={33} height={20} />
        <DecorWrapper>
          <SettingsButtonDecor color={theme.palette.uiPrimary} />
        </DecorWrapper>
      </SettingButton>
      <SettingsDropdown>
        <LanguageSettingsList
          activeItem={userPreferenceState.languageCode}
          onLanguagePress={onLanguagePress}
        />
      </SettingsDropdown>
    </>
  );
});

type ThemeModalProps = {
  onMenuClose: () => void;
  onThemeMenuPress: () => void;
};

const ThemeModal = observer(function ThemeModal(props: ThemeModalProps) {
  const {onMenuClose} = props;
  const {appearance} = useRoot();
  const theme = useTheme();

  const handleSetTheme = useCallback(
    (kind: ThemeKind) => {
      void appearance.setThemeMode(kind);
      onMenuClose();
    },
    [appearance, onMenuClose],
  );

  return (
    <>
      <SettingButton active theme onPress={props.onThemeMenuPress}>
        <CircleHalfSvg color={theme.palette.uiMain} />
        <DecorWrapper>
          <SettingsButtonDecor color={theme.palette.uiPrimary} />
        </DecorWrapper>
      </SettingButton>
      <SettingsDropdown>
        <ThemeSelect
          currentTheme={appearance.preferredThemeKind}
          onChangeTheme={handleSetTheme}
        />
      </SettingsDropdown>
    </>
  );
});

const TOP = 30;

const SettingsDropdown = variance(View)(theme => ({
  root: {
    position: 'absolute',
    top: TOP + 50,
    right: 0,
    width: 375,
    maxHeight: 550,
    borderRadius: 10,
    backgroundColor: theme.palette.uiPrimary,
  },
}));

const SettingButton = variance(SettingButtonBase)(() => ({
  root: {
    position: 'absolute',
    top: TOP,
  },
  language: {
    right: 128,
  },
  theme: {
    right: 69,
  },
}));

const DecorWrapper = variance(View)(() => ({
  root: {
    position: 'absolute',
    bottom: -12,
    left: -13,
  },
}));
