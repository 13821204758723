import {useStyles, variance} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import type {LayoutChangeEvent} from 'react-native';
import {View} from 'react-native';
import MarkdownComponent from 'react-native-awesome-markdown';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';

type AnimatedTextErrorProps = {
  errorText?: string;
  onPress?: (text: string) => void;
};

const ERROR_PADDING = 10;
const ANIMATION_DURATION_MS = 300;

export default observer(function AnimatedTextError(
  props: AnimatedTextErrorProps,
) {
  const {errorText} = props;
  const errorHeightShared = useSharedValue(0);

  const handleErrorTextLayout = useCallback(
    (event: LayoutChangeEvent) => {
      errorHeightShared.value = event.nativeEvent.layout.height;
    },
    [errorHeightShared],
  );

  const wrapperAnimatedStyle = useAnimatedStyle(() => ({
    height: withTiming(
      errorHeightShared.value > 0 ? errorHeightShared.value + ERROR_PADDING : 0,
      {duration: ANIMATION_DURATION_MS},
    ),
  }));

  const themedStyles = useStyles(theme => ({
    paragraph: {
      ...theme.fontByWeight('400'),
      color: theme.palette.error,
      fontStyle: 'normal',
      fontSize: 12,
      lineHeight: 18,
      margin: 0,
      marginTop: 0,
      marginBottom: 0,
    },
    link: {
      ...theme.fontByWeight('600'),
      color: theme.palette.error,
      textDecorationLine: 'underline',
    },
  }));

  return (
    <Root>
      <Animated.View style={[wrapperAnimatedStyle, {overflow: 'hidden'}]}>
        <ErrorTextWrapper onLayout={handleErrorTextLayout}>
          {errorText && (
            <MarkdownComponent
              text={errorText}
              onLinkPress={props.onPress}
              overridden={{styles: themedStyles}}
            />
          )}
        </ErrorTextWrapper>
      </Animated.View>
    </Root>
  );
});

const Root = variance(View)(() => ({
  root: {
    position: 'relative',
  },
}));

const ErrorTextWrapper = variance(View)(() => ({
  root: {
    position: 'absolute',
    top: 10,
    left: 0,
    right: 0,
    width: '100%',
  },
}));
