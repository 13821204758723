import {useRoot, variance} from '@ncwallet-app/core';
import {Button, ButtonVariant, TouchableOpacity} from '@ncwallet-app/ui';
import {
  NCWalletLogoDarkSvg,
  NCWalletLogoSvg,
} from '@ncwallet-app/ui/src/assets/svg/colored/';
import {
  CopySvg,
  ErrorLabelSvg,
  WarningSvg,
} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {setStringAsync} from 'expo-clipboard';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo} from 'react';
import {Text, View} from 'react-native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

import type {BuyLinkError} from '../../../Navigation/HomeStack/BuyLinkBinding/BuyLinkBindingState';
import useGoToSupport from '../../../Navigation/hooks/useGoToSupport';

export type ErrorModalContentProps = {
  code?: BuyLinkError;
};

export default observer(function FinalMessageContent({
  code,
}: ErrorModalContentProps) {
  const {appearance, layoutHelperState, localization, flashMessage} = useRoot();
  const {bottom} = useSafeAreaInsets();
  const isLarge = layoutHelperState.isLarge;

  const containerStyle = useMemo(() => {
    return isLarge ? {} : {paddingBottom: bottom};
  }, [bottom, isLarge]);

  const codeString = localization.executeTemplate(
    'buyCrypto.errorDeposit.code',
    {code},
  );

  const handleCopy = useCallback(() => {
    void setStringAsync(codeString);
    flashMessage.showMessage({
      title: localization.getTranslation('copied'),
      variant: 'success',
    });
  }, [codeString, flashMessage, localization]);

  const goToSupport = useGoToSupport();

  return (
    <Container style={containerStyle} isLarge={isLarge}>
      <BodyWrapper>
        <IconContainer>
          <View>{appearance.isDark ? <LogoImg /> : <LogoDarkImg />}</View>
          <PlusIcon>
            <CrossImg />
          </PlusIcon>
        </IconContainer>
        <Title>
          {localization.getTranslation('buyCrypto.errorDeposit.title')}
        </Title>
        <Description>
          {localization.getTranslation('buyCrypto.errorDeposit.description')}
        </Description>
        <CodeBlock isLarge={isLarge}>
          <CodeWrapper>
            <WarningImg />
            <CodeText>{codeString}</CodeText>
          </CodeWrapper>
          <TouchableOpacity onPress={handleCopy}>
            <CopyImg />
          </TouchableOpacity>
        </CodeBlock>
      </BodyWrapper>
      <ButtonStyled
        isLarge={isLarge}
        title={localization.getTranslation('contactSupport')}
        variant={ButtonVariant.Primary}
        onPress={goToSupport}
      />
    </Container>
  );
});

const Container = variance(View)(() => ({
  root: {
    paddingHorizontal: 15,
    alignItems: 'stretch',
  },
  isLarge: {
    alignItems: 'center',
  },
}));

const BodyWrapper = variance(View)(() => ({
  root: {
    gap: 20,
    paddingVertical: 20,
    alignItems: 'center',
  },
}));

const IconContainer = variance(View)(() => ({
  root: {
    position: 'relative',
    padding: 17,
  },
}));

const PlusIcon = variance(View)(() => ({
  root: {
    position: 'absolute',
    bottom: 2,
    right: 0,
  },
}));

export const Title = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 26,
    lineHeight: 36,
    textAlign: 'center',
    color: theme.palette.textPrimary,
  },
}));

export const Description = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    fontSize: 16,
    lineHeight: 26,
    textAlign: 'center',
    color: theme.palette.textAdditional1,
  },
}));

export const CodeBlock = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 13,
    paddingHorizontal: 15,
    borderRadius: 6,
    alignSelf: 'stretch',
    gap: 10,
    borderWidth: 1,
    borderColor: theme.palette.error,
    backgroundColor: theme.palette.additional5,
  },
  isLarge: {
    alignSelf: 'auto',
    width: 345,
  },
}));

export const CodeWrapper = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
    flex: 1,
  },
}));

export const CodeText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 14,
    color: theme.palette.textPrimary,
  },
}));

const ButtonStyled = variance(Button)(() => ({
  root: {
    marginVertical: 20,
  },
  isLarge: {
    width: 345,
  },
}));

const LogoImg = variance(NCWalletLogoSvg)(
  () => ({root: {}}),
  () => ({
    width: 86,
    height: 86,
  }),
);

const LogoDarkImg = variance(NCWalletLogoDarkSvg)(
  () => ({root: {}}),
  () => ({
    width: 86,
    height: 86,
  }),
);

const WarningImg = variance(WarningSvg)(
  () => ({root: {}}),
  theme => ({
    color: theme.palette.error,
    width: 22,
    height: 22,
  }),
);

const CopyImg = variance(CopySvg)(
  () => ({root: {}}),
  theme => ({
    color: theme.palette.icon1,
    width: 22,
    height: 22,
  }),
);

const CrossImg = variance(ErrorLabelSvg)(
  () => ({root: {}}),
  theme => ({
    color: theme.palette.background,
    width: 40,
    height: 40,
  }),
);
