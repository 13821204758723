import type {LocaleKeys} from '@ncwallet-app/localization/locale/LocaleStrings';

import type {BaseError, GENERAL_REST_CLIENT_ERROR} from '../Error';

export enum CommonRestErrorCode {
  TokenInvalid = 'token_invalid',
  TokenExpired = 'token_expired',
  TokenNotFound = 'token_not_found',
  TwoFaMismatch = '2fa_mismatch',
}

export type CommonRestErrorResponse = {
  kind: typeof GENERAL_REST_CLIENT_ERROR;
  description: string;
  raw: unknown;
  body: {
    code: CommonRestErrorCode;
  };
};

export const isCommonRestErrorDescription = (
  error: BaseError,
): error is CommonRestErrorResponse => {
  const code = Reflect.get(Reflect.get(error, 'body') ?? {}, 'code') as
    | CommonRestErrorCode
    | undefined;
  return !!(code && Object.values(CommonRestErrorCode).includes(code));
};

export const COMMON_REST_ERROR_TO_LOCALE: Record<
  CommonRestErrorCode,
  LocaleKeys
> = {
  [CommonRestErrorCode.TokenInvalid]: 'errors.invalidToken',
  [CommonRestErrorCode.TokenExpired]: 'errors.tokenExpired',
  [CommonRestErrorCode.TokenNotFound]: 'errors.tokenExpired',
  [CommonRestErrorCode.TwoFaMismatch]: 'errors.2faMismatch',
};
