import {useStrings, variance} from '@ncwallet-app/core';
import {
  Button,
  ButtonColor,
  ButtonIconPosition,
  ButtonVariant,
} from '@ncwallet-app/ui';
import {ArrowRightSmallSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {Image, Linking, Platform, Text, View} from 'react-native';

import bannerImg from './md-banner-img.png';

export default observer(function Banner() {
  const strings = useStrings();

  const handleDetailsPress = useCallback(() => {
    const url = 'https://cryptobrowser.site/?utm_source=ncw';
    if (Platform.OS === 'web') {
      window.open(url);
    } else {
      void Linking.openURL(url);
    }
  }, []);

  return (
    <Container>
      <BannerImg source={bannerImg} resizeMode="cover" />
      <InnerContainer>
        <Title>{strings['dashboardBanner.title']}</Title>
        <Description>{strings['dashboardBanner.description']}</Description>
      </InnerContainer>
      <Footer>
        <Tag>{strings['dashboardBanner.tag']}</Tag>
        <ButtonDetail
          title={strings['dashboardBanner.buttonTitle']}
          Icon={ArrowRightSmallSvg}
          color={ButtonColor.Secondary}
          iconPosition={ButtonIconPosition.Right}
          variant={ButtonVariant.Text}
          onPress={handleDetailsPress}
        />
      </Footer>
    </Container>
  );
});

const Container = variance(View)(theme => ({
  root: {
    borderRadius: 10,
    flex: 1,
    backgroundColor: theme.palette.background,
    shadowColor: theme.palette.blackout,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.15,
    shadowRadius: 10,
  },
}));

const InnerContainer = variance(View)(() => ({
  root: {
    padding: 15,
  },
}));

const BannerImg = variance(Image)(() => ({
  root: {
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    height: 198,
    width: '100%',
  },
}));

const ButtonDetail = variance(Button)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    padding: 0,
    borderWidth: 0,
    textTransform: 'uppercase',
    fontSize: 13,
    lineHeight: 16,
    marginRight: 5,
    color: theme.palette.info,
  },
}));

const Footer = variance(View)(theme => ({
  root: {
    borderTopWidth: 1,
    borderTopColor: theme.palette.uiSecondary,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 15,
  },
}));

const Tag = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 10,
    lineHeight: 12,
    color: theme.palette.primary,
    borderWidth: 1,
    borderColor: theme.palette.primary,
    borderRadius: 2,
    paddingHorizontal: 5,
    paddingVertical: 3,
  },
}));

const Title = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 16,
    lineHeight: 26,
    color: theme.palette.textPrimary,
    marginBottom: 5,
  },
}));

const Description = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    fontSize: 13,
    lineHeight: 20,
    color: theme.palette.textAdditional1,
    marginBottom: 5,
  },
}));
