import {useStrings, variance} from '@ncwallet-app/core';
import {SafeAreaSectionList, Separator} from '@ncwallet-app/ui/src';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo} from 'react';
import {StyleSheet} from 'react-native';

import {SettingsGroupTitle} from '../UserSettingsScreens/SharedComponents/SettingsGroupTitle';
import LimitsSettingsEmptyListLimitSkeleton from './LimitsSettingsEmptyListLimitSkeleton';
import LimitsSettingsEmptyListSkeleton from './LimitsSettingsEmptyListSkeleton';

export default observer(function LimitsSettingsEmptyList() {
  const strings = useStrings();
  const fullListItem = useMemo(
    () => [
      {
        index: 0,
        title: strings['limitsSettingsScreen.activeTitle'],
        data: [0, 0, 0, 0].map(() => ({isLimit: true})),
      },
      {
        index: 1,
        title: strings['limitsSettingsScreen.noSetTitle'],
        data: [0, 0, 0, 0].map(() => ({isLimit: false})),
      },
    ],
    [strings],
  );

  const renderItem = useCallback(({item}: {item: {isLimit: boolean}}) => {
    if (item.isLimit) {
      return <LimitsSettingsEmptyListLimitSkeleton />;
    } else {
      return <LimitsSettingsEmptyListSkeleton />;
    }
  }, []);

  return (
    <SafeAreaSectionList
      sections={fullListItem}
      style={styles.root}
      stickySectionHeadersEnabled={false}
      renderSectionHeader={({section}) => {
        return (
          <SettingsGroupTitle
            title={section.title}
            first={section.index === 0}
          />
        );
      }}
      SectionSeparatorComponent={LineSeparator}
      ItemSeparatorComponent={LineSeparator}
      renderItem={renderItem}
    />
  );
});

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
});

const LineSeparator = variance(Separator)(() => ({
  root: {
    marginRight: 0,
    marginLeft: 0,
  },
}));
