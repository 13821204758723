import {TransactionFilterKind, useStrings, variance} from '@ncwallet-app/core';
import {
  LG_BREAKPOINT,
  Pressable,
  TouchableListItem,
  TransactionFilterIcon,
  useIsDimensions,
} from '@ncwallet-app/ui/src';
import {
  ArrowDownWideSvg,
  ArrowRightSvg,
} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

import {useGetHistoryFilterKindStrings} from './useGetHistoryFilterKindStrings';

export type HistoryOperationItemProps = {
  goToOperationList: () => void;
  transactionFilterKind?: TransactionFilterKind;
  testID?: string;
};

export default observer(function HistoryOperationItem({
  goToOperationList,
  transactionFilterKind = TransactionFilterKind.All,
  testID,
}: HistoryOperationItemProps) {
  const isLg = useIsDimensions('lg');

  const texts = useGetHistoryFilterKindStrings();
  const strings = useStrings();
  return (
    <Pressable dataSet={{['app']: 'history-filter-item'}} testID={testID}>
      <Item
        Icon={isLg ? ArrowDownWideImg : ArrowRightSvg}
        onPress={goToOperationList}>
        <TransactionFilterIcon kind={transactionFilterKind} size={40} />
        <Block>
          <Title>{strings[texts[transactionFilterKind]]}</Title>
        </Block>
      </Item>
    </Pressable>
  );
});

const Item = variance(TouchableListItem)(theme => ({
  root: {
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.uiSecondary,
    paddingHorizontal: 15,
    paddingVertical: 15,

    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        borderWidth: 1,
        borderColor: theme.palette.uiSecondary,
        borderRadius: 8,
        paddingVertical: 10,
      },
    }),
  },
}));

const Block = variance(View)(() => ({
  root: {
    marginLeft: 15,
    marginRight: 15,
  },
}));

const Title = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    fontSize: 14,
    lineHeight: 17,
  },
}));

const ArrowDownWideImg = variance(ArrowDownWideSvg)(
  () => ({
    root: {},
  }),
  () => ({
    width: 22,
    height: 22,
  }),
);
