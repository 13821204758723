import {urlLinks, useStrings} from '@ncwallet-app/core';
import {TwoFaProviderKind} from '@ncwallet-app/core/src/TwoFaHelper';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo} from 'react';
import {Linking} from 'react-native';

import {
  HeaderTag,
  HeaderTitleTag,
  LgProfileCardContainer,
} from '../../ProfileSettingsScreen/LgProfileCards/useCardStyles';
import {
  TwoFaSecretScreen,
  TwoFaWelcomeScreen,
} from '../../UserSettingsScreens/SecurityTwoFaAuthScreen';
import TwoFaProviderList from '../../UserSettingsScreens/SecurityTwoFaAuthScreen/TwoFaProviderList';
import TwoFACardAuthenticatorView from '../TwoFaCardAuthenticatorView';
import TwoFaCardSetCode from '../TwoFaCardSetCode';
import type {TwoFaSettingsCardProps} from '../TwoFaSettingsCard';
import TwoFASettingsCard from '../TwoFaSettingsCard';
import PromptOtpToChangeTwoFASettingsCard from './PromptOtpToChangeTwoFaSettingsCard';
import PromptOtpToDisableTwoFACard from './PromptOtpToDisableTwoFaCard';

export type TwoFaCardProps = {
  twoFaEnabled: boolean;
  onDisableTwoFaSubmit: (code: string) => void;
  onEnableTwoFaSubmit: (code: string) => void;
  onChangeTwoFaSettingsSubmit: (code: string) => void;
  getSecret: () => string | undefined;
  getOtpAuthUri: () => string;
  getError: () => string | undefined;
  getHasAuthenticator: () => boolean | undefined;
  onOpenAuthenticator: () => void;
  onDirectlyOpenAuthenticator: () => void;
  onInstallAuthenticator: () => void;
  onCopySecret: () => void;
  onEnterTwoFaCode: () => void;
  getTwoFaError: () => string | undefined;
  resetError: () => void;
  twoFaFlowState: EnableTwoFaFlow;
  showTwoFaStatus: () => void;
  showGeneratedSecret: () => void;
  listAuthApps: () => void;
  promptOtpToEnableTwoFa: () => void;
  handleTwoFaEnable: () => void;
  providerKind: TwoFaProviderKind | undefined;
  twoFaSettingsProps: TwoFaSettingsCardProps;
  currentTwoFaProvider: TwoFaProviderKind | undefined;
  prevTwoFaProvider: TwoFaProviderKind | undefined;
  selectedTwoFaProvider: () => TwoFaProviderKind | undefined;
  handleSendTelegramOpt: (withSecret?: boolean) => Promise<void>;
  availableTwoFaProviders: TwoFaProviderKind[];
  onTwoFaProviderSelect: (kind: TwoFaProviderKind) => void;
  interval: number;
  resetInterval: () => void;
};

export enum EnableTwoFaFlow {
  Welcome,
  Settings,
  Start, // SHOW_TWO_FA_GENERATED_SECRET_ROUTE, SHOW_Two_FA_STATUS_ROUTE
  Auth, // LIST_AUTH_APPS_ROUTE
  SetCode, // PROMPT_OTP_TO_ENABLE_TWO_FA_ROUTE
  PromptOtpToDisable, // PROMPT_OTP_TO_DISABLE_TWO_FA_ROUTE
  PromptOtpToChangeTwoFaSettings,
  PromptSelectTwoFaProvider,
}

export default observer(function TwoFaCard(props: TwoFaCardProps) {
  const enableFlowState = props.twoFaFlowState;
  const strings = useStrings();
  const handleFaqHelpPress = useCallback(async () => {
    await Linking.openURL(urlLinks.whatIs2FaAuthentication);
  }, []);

  const shouldRenderResendButton = useMemo(() => {
    if (!props.currentTwoFaProvider) {
      return true;
    }

    if (
      props.currentTwoFaProvider === TwoFaProviderKind.Telegram &&
      props.prevTwoFaProvider === TwoFaProviderKind.Ga
    ) {
      return false;
    }

    return true;
  }, [props.currentTwoFaProvider, props.prevTwoFaProvider]);

  if (
    props.twoFaEnabled &&
    enableFlowState !== EnableTwoFaFlow.PromptSelectTwoFaProvider &&
    enableFlowState !== EnableTwoFaFlow.SetCode &&
    enableFlowState !== EnableTwoFaFlow.Start
  ) {
    if (enableFlowState === EnableTwoFaFlow.PromptOtpToChangeTwoFaSettings) {
      return (
        <PromptOtpToChangeTwoFASettingsCard
          resetError={props.resetError}
          onBack={props.showTwoFaStatus}
          getError={props.getTwoFaError}
          onSubmit={props.onChangeTwoFaSettingsSubmit}
          onTwoFaResendCode={() => props.handleSendTelegramOpt(false)}
          currentTwoFaProvider={props.currentTwoFaProvider}
          resendCodeInterval={props.interval}
          resetInterval={props.resetInterval}
        />
      );
    }

    if (enableFlowState === EnableTwoFaFlow.PromptOtpToDisable) {
      return (
        <PromptOtpToDisableTwoFACard
          resetError={props.resetError}
          onBack={props.showTwoFaStatus}
          getError={props.getTwoFaError}
          currentTwoFaProvider={props.currentTwoFaProvider}
          onTwoFaResendCode={() => props.handleSendTelegramOpt(false)}
          onSubmit={props.onDisableTwoFaSubmit}
          resendCodeInterval={props.interval}
          resetInterval={props.resetInterval}
        />
      );
    }

    if (enableFlowState === EnableTwoFaFlow.Settings) {
      return (
        <TwoFASettingsCard
          {...props.twoFaSettingsProps}
          currentTwoFaProvider={props.twoFaSettingsProps.selectedTwoFaProvider}
        />
      );
    }

    return <TwoFASettingsCard {...props.twoFaSettingsProps} />;
  }

  return (
    <LgProfileCardContainer>
      {enableFlowState === EnableTwoFaFlow.Start && (
        <>
          <HeaderTag>
            <HeaderTitleTag>
              {strings['lgSecureSettingsScreen.twoFACardTitle']}
            </HeaderTitleTag>
          </HeaderTag>
          <TwoFaSecretScreen
            {...props}
            providerKind={props.providerKind}
            onEnterCode={props.onEnterTwoFaCode}
            goToSetCode={props.promptOtpToEnableTwoFa}
            goToAuth={props.listAuthApps}
            onFaqHelpPress={handleFaqHelpPress}
          />
        </>
      )}
      {enableFlowState === EnableTwoFaFlow.Welcome && (
        <>
          <HeaderTag>
            <HeaderTitleTag>
              {strings['secureTwoFactorAuthScreen.enableButton']}
            </HeaderTitleTag>
          </HeaderTag>
          <TwoFaWelcomeScreen onTwoFaEnable={props.handleTwoFaEnable} />
        </>
      )}
      {enableFlowState === EnableTwoFaFlow.Auth && (
        <TwoFACardAuthenticatorView onBack={props.showGeneratedSecret} />
      )}
      {enableFlowState === EnableTwoFaFlow.SetCode && (
        <TwoFaCardSetCode
          resetError={props.resetError}
          headerText={strings['secureTwoFactorAuthScreen.install']}
          getError={props.getTwoFaError}
          titleText={strings['secureTwoFactorAuthScreen.title']}
          submitText={strings['secureTwoFactorAuthScreen.enableButton']}
          onBack={props.handleTwoFaEnable}
          onSubmit={props.onEnableTwoFaSubmit}
          onTwoFaResendCode={
            shouldRenderResendButton ? props.handleSendTelegramOpt : undefined
          }
          currentTwoFaProvider={props.providerKind}
          resendCodeInterval={props.interval}
          resetInterval={props.resetInterval}
        />
      )}
      {enableFlowState === EnableTwoFaFlow.PromptSelectTwoFaProvider && (
        <>
          <HeaderTag>
            <HeaderTitleTag>
              {strings['twoFaSelectScreen.title']}
            </HeaderTitleTag>
          </HeaderTag>
          <TwoFaProviderList
            selectedTwoFaProvider={props.selectedTwoFaProvider()}
            providers={props.availableTwoFaProviders}
            onTwoFaProviderSelect={props.onTwoFaProviderSelect}
          />
        </>
      )}
    </LgProfileCardContainer>
  );
});
