import type {
  AddressInfo,
  CryptoCurrencyCode,
  CryptoCurrencyOptions,
  CurrenciesRateHistoryStore,
  CurrenciesRateStore,
  CurrencyStore,
  DecimalString,
  FiatCurrencyCode,
  NavigationContainer,
  OnTimeoutError,
  RateHistoryPeriod,
  Service,
  WalletId,
  WalletStore,
} from '@ncwallet-app/core';
import {
  FULFILLED,
  getDefaultInOutCurrency,
  isEnoughMoneyForSendOrExchange,
  MoneyStatic,
  patchRateHistoryWithLatestRate,
  TIMEOUT_ERROR,
  toCurrencyDescriptionFromCrypto,
  toCurrencyDescriptionFromFiat,
} from '@ncwallet-app/core';
import type {AccountStore} from '@ncwallet-app/core/src/AccountStore';
import {getHistoryFromResponse} from '@ncwallet-app/core/src/dataStores/CurrenciesRateHistoryStore/AcceptedRateHistoryResponse';
import {getDefaultAddressInfo} from '@ncwallet-app/core/src/NCWalletServer/AddressInfo';
import {getDefaultBuyCurrency as _getDefaultBuyCurrency} from '@ncwallet-app/core/src/NCWalletServer/InOutCurrency';
import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx';

// eslint-disable-next-line import-x/prefer-default-export
export class CurrencyBindingStateService implements Service, OnTimeoutError {
  @observable period: RateHistoryPeriod | undefined;
  @observable isGraphLoading: boolean = false;
  @observable private _cryptoCurrencyOptions: CryptoCurrencyOptions | undefined;

  @observable private _walletId: WalletId | undefined;
  @observable private _baseFiatTag: FiatCurrencyCode | undefined;
  @observable private _isTimeoutError = false;

  @computed get isTimeoutError() {
    return this._isTimeoutError;
  }

  @computed get wallet() {
    return (
      this._walletId && this._root.walletStore.getWalletById(this._walletId)
    );
  }

  @computed get cryptoValue(): DecimalString | undefined {
    return this.wallet?.total;
  }

  @computed get baseFiatRate() {
    return this._baseFiatRateResult?.rate;
  }

  @computed get baseFiatValue() {
    const baseFiatRate = this.baseFiatRate;
    if (!this.wallet || !baseFiatRate) {
      return undefined;
    }

    return MoneyStatic.convert(this.wallet.total, baseFiatRate);
  }

  @computed get rateHistory() {
    if (
      !this.wallet ||
      !this._baseFiatTag ||
      !this._baseFiatRateResult ||
      this.isGraphLoading
    ) {
      return undefined;
    }

    const historyRes =
      this.period &&
      this._root.currenciesRateHistoryStore.getLatestHistoryRes(
        this.wallet.currency,
        this._baseFiatTag,
        this.period,
      );

    const history = historyRes && getHistoryFromResponse(historyRes);
    return (
      this.period &&
      history &&
      patchRateHistoryWithLatestRate(
        history,
        this._baseFiatRateResult,
        this.period,
      )
    );
  }

  @computed get cryptoCurrency() {
    const currency =
      this.wallet &&
      this._root.currencyStore.getCryptoCurrency(this.wallet.currency);
    return currency && toCurrencyDescriptionFromCrypto(currency);
  }

  @computed get baseFiatCurrency() {
    const currency =
      this._baseFiatTag &&
      this._root.currencyStore.getFiatCurrency(this._baseFiatTag);

    return currency && toCurrencyDescriptionFromFiat(currency);
  }

  constructor(
    private readonly _root: {
      readonly walletStore: WalletStore;
      readonly currencyStore: CurrencyStore;
      readonly currenciesRateStore: CurrenciesRateStore;
      readonly currenciesRateHistoryStore: CurrenciesRateHistoryStore;
      readonly accountStore: AccountStore;
      readonly navigationContainer: NavigationContainer;
    },
  ) {
    makeObservable(this);
  }

  @action.bound
  async refreshWalletsAndCurrencies(
    walletId: WalletId,
    baseFiatTag: FiatCurrencyCode,
  ) {
    this._walletId = walletId;
    this._baseFiatTag = baseFiatTag;
    const refreshWalletsResult = await this._root.walletStore.refreshWallets();
    const wallet = this._root.walletStore.getWalletById(walletId);
    if (!wallet) {
      return;
    }

    const responses = await Promise.all([
      this._root.currencyStore.refreshFiatCurrencies(),
      this._root.currencyStore.refreshCryptoCurrencies(),
    ]);

    this._isTimeoutError =
      responses.some(it => !it.success && it.left.kind === TIMEOUT_ERROR) ||
      (!refreshWalletsResult.success &&
        refreshWalletsResult.left.kind === TIMEOUT_ERROR);
  }

  _isSuspended(): boolean {
    return (
      this._root.accountStore.state?.status === FULFILLED &&
      this._root.accountStore.state.result.settings.is_suspended
    );
  }

  buyEnabled(): boolean {
    const currency =
      this.wallet &&
      this._root.currencyStore.getCryptoCurrency(this.wallet.currency);

    return !!currency?.options.deposit_is_active;
  }

  receiveEnabled(): boolean {
    const currency =
      this.wallet &&
      this._root.currencyStore.getCryptoCurrency(this.wallet.currency);

    return !!currency?.options.incoming;
  }

  exchangeEnabled(): boolean {
    const currency =
      this.wallet &&
      this._root.currencyStore.getCryptoCurrency(this.wallet.currency);
    return (
      !this._isSuspended() &&
      !!(currency?.options.change && this.wallet) &&
      Number(this.cryptoValue) !== 0
    );
  }

  sendEnabled(): boolean {
    const currency =
      this.wallet &&
      this._root.currencyStore.getCryptoCurrency(this.wallet.currency);

    return (
      !this._isSuspended() &&
      !!(
        currency?.options.withdraw &&
        this.wallet &&
        isEnoughMoneyForSendOrExchange(this.wallet)
      )
    );
  }

  @action.bound
  changeGraphPeriod(period: RateHistoryPeriod) {
    this.period = period;
  }

  subscribe() {
    return reaction(
      () =>
        this.wallet && this._baseFiatTag && this.period
          ? {
              crypto: this.wallet.currency,
              fiat: this._baseFiatTag,
              period: this.period,
            }
          : undefined,
      args => {
        if (args) {
          return this._refreshGraphData(args.period, args.crypto, args.fiat);
        }
      },
    );
  }

  private _refreshGraphData = async (
    period: RateHistoryPeriod,
    crypto: CryptoCurrencyCode,
    fiat: FiatCurrencyCode,
  ) => {
    runInAction(() => {
      this.isGraphLoading = true;
    });
    const responses = await Promise.all([
      this._root.currenciesRateHistoryStore.refreshLatestHistoryRes(
        crypto,
        fiat,
        period,
      ),
      // следует обновлять курс при обновлении данных графика,
      // иначе возможна ситуация, когда показывается устаревший курс
      this._root.currenciesRateStore.refreshRate(crypto, fiat),
    ]);
    runInAction(() => {
      this._isTimeoutError = responses.some(
        it => !it.success && it.left.kind === TIMEOUT_ERROR,
      );
      this.isGraphLoading = false;
    });
  };

  @computed get addressInfo(): AddressInfo | undefined {
    if (!this.wallet) {
      return undefined;
    }
    const currencyIn = this.getDefaultInCurrency();
    return (
      currencyIn &&
      getDefaultAddressInfo(
        this.wallet.addresses,
        currencyIn.network,
        currencyIn.default_type,
      )
    );
  }

  getDefaultInCurrency() {
    const currency =
      this.wallet &&
      this._root.currencyStore.getCryptoCurrency(this.wallet.currency);
    return currency && getDefaultInOutCurrency(currency, 'in');
  }

  getDefaultOutCurrency() {
    const currency =
      this.wallet &&
      this._root.currencyStore.getCryptoCurrency(this.wallet.currency);
    return currency && getDefaultInOutCurrency(currency, 'out');
  }

  getDefaultBuyCurrency() {
    const wallet = this.wallet;
    const currency =
      wallet && this._root.currencyStore.getCryptoCurrency(wallet.currency);
    return currency && _getDefaultBuyCurrency(currency);
  }

  getDefaultBuyAddressInfo() {
    const defaultCurrency = this.getDefaultBuyCurrency();
    return (
      this.wallet &&
      defaultCurrency &&
      getDefaultAddressInfo(
        this.wallet.addresses,
        defaultCurrency.network,
        defaultCurrency.default_type,
      )
    );
  }

  @computed
  private get _baseFiatRateResult() {
    if (!this._baseFiatTag || !this.wallet?.currency) {
      return undefined;
    }

    return this._root.currenciesRateStore.getRate(
      this.wallet.currency,
      this._baseFiatTag,
    );
  }
}
