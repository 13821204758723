import {
  type CurrencyDescription,
  formatCryptoValue,
  useStrings,
  useTheme,
  variance,
} from '@ncwallet-app/core';
import {
  CircleStatisticSvg,
  LgAffEarnings,
  LgAffEarningsMonth,
  LgAffUser,
} from '@ncwallet-app/ui/src/assets/svg/colored';
import {
  EarningMonthSvg,
  EarningsSVG,
  UserSvg,
} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import type {ReactNode} from 'react';
import React, {useMemo} from 'react';
import {StyleSheet, Text, View} from 'react-native';

import {
  BodyTag,
  HeaderTag,
  HeaderTitleTag,
  LgProfileCardContainer,
} from '../ProfileSettingsScreen/LgProfileCards/useCardStyles';

export type StatisticCardProps = {
  currency: CurrencyDescription | undefined;
  referrals: number;
  lastMonthEarnings: string;
  lastWeakEarnings: string;
};

export default observer(function StatisticCard(props: StatisticCardProps) {
  const theme = useTheme();
  const strings = useStrings();
  const {referrals, lastWeakEarnings, lastMonthEarnings} = props;

  const isEmptyStatistics = useMemo<boolean>(() => {
    const roundMonth = formatCryptoValue(
      lastMonthEarnings,
      props.currency?.fractionDigits,
    );
    const roundWeak = formatCryptoValue(
      lastWeakEarnings,
      props.currency?.fractionDigits,
    );

    const isZeroLastMonthEarningsNumber = !Number(roundMonth);
    const isZeroLastWeakhEarnings = !Number(roundWeak);

    return (
      !referrals || isZeroLastMonthEarningsNumber || isZeroLastWeakhEarnings
    );
  }, [
    referrals,
    lastWeakEarnings,
    lastMonthEarnings,
    props.currency?.fractionDigits,
  ]);

  return (
    <LgProfileCardContainer>
      <HeaderTag>
        <HeaderTitleTag>{strings['affiliateScreen.statistic']}</HeaderTitleTag>
      </HeaderTag>
      <BodyTag>
        {isEmptyStatistics ? (
          <EmptyCardStatistics />
        ) : (
          <>
            <Item
              Bg={<LgAffUser style={styles.bg} />}
              Icon={<UserSvg color={theme.palette.primary} />}
              label={strings['affiliateScreen.joined']}
              info={referrals}
            />
            <Item
              Bg={<LgAffEarnings style={styles.bg} />}
              Icon={<EarningsSVG color={theme.palette.primary} />}
              label={strings['affiliateScreen.earnWeek']}
              info={`${formatCryptoValue(lastWeakEarnings, props.currency?.fractionDigits)} CTC`}
            />
            <Item
              Bg={<LgAffEarningsMonth style={styles.bg} />}
              Icon={<EarningMonthSvg color={theme.palette.primary} />}
              label={strings['affiliateScreen.earnMonth']}
              info={`${formatCryptoValue(lastMonthEarnings, props.currency?.fractionDigits)} CTC`}
            />
          </>
        )}
      </BodyTag>
    </LgProfileCardContainer>
  );
});

type ItemProps = {
  label: string;
  Icon: ReactNode;
  Bg: ReactNode;
  info: string | number;
};

const Item = (props: ItemProps) => {
  const {Icon, label, info, Bg} = props;
  return (
    <Card>
      {Bg}
      <Content>
        <Top>
          {Icon}
          <Label>{label}</Label>
        </Top>
        <Info>{info}</Info>
      </Content>
    </Card>
  );
};

const EmptyCardStatistics = () => {
  const strings = useStrings();

  return (
    <EmptyCardContainer>
      <CircleStatisticSvg width={60} height={60}></CircleStatisticSvg>
      <EmptyCardTitle>{strings['statistics.title']}</EmptyCardTitle>
      <EmptyCardDescription>
        {strings['statistics.description']}
      </EmptyCardDescription>
    </EmptyCardContainer>
  );
};

const styles = StyleSheet.create({
  bg: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
});

const Card = variance(View)(theme => ({
  root: {
    marginBottom: 20,
    paddingTop: 15,
    height: 129,
    paddingLeft: 15,
    paddingBottom: 10,
    paddingRight: 20,
    backgroundColor: theme.palette.uiPrimary,
    overflow: 'hidden',
    borderRadius: 8,
  },
  noBottom: {
    marginBottom: 0,
  },
}));

const Content = variance(View)(() => ({
  root: {
    flex: 1,
  },
}));

const Top = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const Label = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 14,
    color: theme.palette.textAdditional1,
    marginLeft: 10,
    maxWidth: 168,
  },
}));

const Info = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textMain,
    fontSize: 26,
    lineHeight: 36,
    marginTop: 'auto',
    marginLeft: 'auto',
  },
}));

const EmptyCardContainer = variance(View)(() => ({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
}));

const EmptyCardTitle = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight(700),
    color: theme.palette.textPrimary,
    fontSize: 16,
    marginTop: 16,
    maxWidth: 248,
    textAlign: 'center',
  },
}));

const EmptyCardDescription = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight(400),
    color: theme.palette.textAdditional1,
    fontSize: 14,
    marginTop: 8,
    maxWidth: 248,
    textAlign: 'center',
  },
}));
