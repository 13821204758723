import {useStrings, useStyles, useTheme, variance} from '@ncwallet-app/core';
import {Input, InputVariant, TouchableOpacity} from '@ncwallet-app/ui';
import {QrSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import type {TextInput, TextInputProps} from 'react-native';
import {View} from 'react-native';

import LabelWithNetwork from './LabelWithNetwork';

export type SendScreenAddressFormProps = TextInputProps & {
  addressNameShown: boolean;
  addressName: string | undefined;
  networkChangeEnabled: boolean;
  onAddressNamePress: () => void;
  value?: string;
  inputRef?: React.Ref<TextInput>;
  onFocus?: () => void;
  onBlur?: () => void;
  onInputChange?: (value: string) => void;
  onActionPress?: () => void;
  showAction?: boolean;
  wideStyle?: boolean;
  error?: boolean;
  contractType: string | undefined | null;
  onScroll?:
    | ((options?: {x: number; y: number; animated: boolean}) => void)
    | undefined;
  networkCurrency: string | undefined;
};

export const SendAddressInput = observer(
  (props: SendScreenAddressFormProps) => {
    const {wideStyle, onActionPress, showAction = true, ...rest} = props;
    const strings = useStrings();
    const theme = useTheme();
    const styles = useStyles(() => ({inner: {marginRight: 0}}));

    const renderActionView = useCallback(() => {
      return (
        showAction && (
          <AddressActionView>
            <AddressActionTouchable
              onPress={onActionPress}
              testID="qr_scanner_btn">
              <QrSvg color={theme.palette.info} />
            </AddressActionTouchable>
          </AddressActionView>
        )
      );
    }, [showAction, onActionPress, theme]);
    return (
      <Root wideStyle={wideStyle}>
        <Input
          ref={props.inputRef}
          value={props.value}
          onBlur={props.onBlur}
          innerContainerStyle={styles.inner}
          LabelComponent={() => <LabelWithNetwork {...props} />}
          label={strings['sendCrypto.addressForm.label']}
          placeholder={strings['sendCrypto.addressForm.placeholder']}
          placeholderTextColor={theme.palette.textAdditional3}
          onFocus={props.onFocus}
          onChangeText={props.onInputChange}
          Slot={renderActionView}
          onPressIn={() => props.onScroll?.({x: 0, y: 10000, animated: true})}
          variant={props.error ? InputVariant.Error : InputVariant.Default}
          smartInsertDelete={false}
          lineBreakStrategyIOS="none"
          {...rest}
        />
      </Root>
    );
  },
);

const Root = variance(View)(() => ({
  root: {
    paddingVertical: 0,
    paddingHorizontal: 0,
  },
  wideStyle: {
    flex: 1,
  },
}));

const AddressActionView = variance(View)(theme => ({
  root: {
    justifyContent: 'center',
    borderLeftWidth: 1,
    borderLeftColor: theme.palette.uiSecondary,
  },
}));

const AddressActionTouchable = variance(TouchableOpacity)(theme => ({
  root: {
    padding: 14,
    backgroundColor: theme.palette.uiPrimary,
  },
}));
