import type {
  AppWindowState,
  ReadonlyNavigationContainerState,
  Service,
  Url,
} from '../..';
import type {AnalyticsNotification, Event} from '../AnalyticsNotification';
import {Domain, EventType} from '../AnalyticsNotification';
import type {AppStateHelper} from '../AppStateHelper';
import type {AuthStackNavigation} from '../AuthStackNavigation';
import type {ShallowCommonState} from '../CommonNavigationScheme';
import {stateToPath} from '../CommonNavigationScheme';
import type {Configuration} from '../Configuration';
import {batchDisposers} from '../structure';

class NavigationAnalyticsServiceImpl implements Service {
  private _timeStamp: number = 0;
  private _previousUrl?: Url | string;
  private _previousAuthRoute?: Url | string;

  constructor(
    private readonly _root: {
      readonly navigationContainerState: ReadonlyNavigationContainerState;
      readonly analyticsNotificationService: AnalyticsNotification;
      readonly authStackNavigation: AuthStackNavigation;
      readonly configuration: Configuration;
      readonly appStateHelper: AppStateHelper;
      readonly appWindowState: AppWindowState;
    },
  ) {}

  private _setAuthStackRoute = (authStackRoute?: string) => {
    if (authStackRoute) {
      this._addEvents(
        authStackRoute,
        this._previousAuthRoute ?? this._previousUrl,
      );
    } else if (this._previousUrl) {
      this._addEvents(this._previousUrl, this._previousAuthRoute);
    }

    this._previousAuthRoute = authStackRoute;
    this._timeStamp = Date.now();
  };

  private _addNavigationEvents(event: ShallowCommonState) {
    if (event.length === 0) {
      return;
    }
    const currentUrl = stateToPath(event).toString() as Url;
    if (this._previousAuthRoute) {
      this._previousUrl = currentUrl;
      return;
    }
    if (currentUrl === this._previousUrl) {
      return;
    }
    this._addEvents(currentUrl, this._previousUrl);
    this._previousUrl = currentUrl;
    this._timeStamp = Date.now();
  }

  private _addEvents(currentUrl: Url | string, previousUrl?: Url | string) {
    if (previousUrl) {
      const pageLeaveEvent: Event = {
        name: EventType.PageLeave,
        meta: {
          domain: Domain.Navigation,
          url: previousUrl,
          time_spend: Date.now() - this._timeStamp,
          host: this._root.configuration.values.ncWalletRestApiUrl,
        },
      };
      this._root.analyticsNotificationService.addEvent(pageLeaveEvent);
    }
    if (currentUrl) {
      const pageOpenEvent: Event = {
        name: EventType.PageOpen,
        meta: {
          domain: Domain.Navigation,
          url: currentUrl,
          from: previousUrl,
          host: this._root.configuration.values.ncWalletRestApiUrl,
        },
      };
      this._root.analyticsNotificationService.addEvent(pageOpenEvent);
    }
  }

  subscribe() {
    if (this._root.navigationContainerState.latestState) {
      this._addNavigationEvents(
        this._root.navigationContainerState.latestState,
      );
    }

    return batchDisposers(
      this._root.navigationContainerState.updates.listen(_ => {
        this._addNavigationEvents(_);
      }),
      this._root.authStackNavigation.updates.listen(_ => {
        this._setAuthStackRoute(_);
      }),
    );
  }
}

export default NavigationAnalyticsServiceImpl;
