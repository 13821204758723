import {variance} from '@ncwallet-app/core';
import {Separator, TouchableListItem} from '@ncwallet-app/ui';
import {ArrowRightSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import type {ReactNode} from 'react';
import React from 'react';
import {Text, View} from 'react-native';

export type LgUserSettingsScreenItemProps = {
  icon: ReactNode;
  text: string;
  withArrow?: boolean;
  last?: boolean;
  danger?: boolean;
  disabled?: boolean;
  onPress?: () => void;
  testID?: string;
};

export default observer(function LgUserSettingsScreenItem(
  props: LgUserSettingsScreenItemProps,
) {
  const {
    icon,
    text,
    withArrow = false,
    onPress,
    last,
    danger,
    disabled = false,
    testID,
  } = props;
  return (
    <>
      <Container
        onPress={onPress}
        Icon={withArrow ? ArrowRightSvg : null}
        disabled={disabled}
        testID={testID}>
        <Row>
          <ProfileBlock>
            {icon}
            <ProfileText danger={danger}>{text}</ProfileText>
          </ProfileBlock>
        </Row>
      </Container>
      {!last && <Separator />}
    </>
  );
});

const Container = variance(TouchableListItem)(() => ({
  root: {
    paddingHorizontal: 15,
  },
}));

const Row = variance(View)(() => ({
  root: {
    paddingVertical: 10,
  },
}));

const ProfileBlock = variance(View)(() => ({
  root: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const ProfileText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textMain,
    fontSize: 16,
    marginLeft: 15,
  },
  danger: {
    color: theme.palette.error,
  },
}));
