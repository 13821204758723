import {action, makeObservable, observable} from 'mobx';

import type {ShallowCommonState} from '../CommonNavigationScheme';
import {BusImpl, type BusSource} from '../structure';
import type {NavigationContainerState} from './NavigationContainerState';

export default class NavigationContainerStateImpl
  implements NavigationContainerState
{
  @observable.ref private _latestState?: ShallowCommonState;
  private readonly _updates = new BusImpl<
    (current: ShallowCommonState) => void
  >();

  constructor() {
    makeObservable(this);
  }

  get updates(): BusSource<(current: ShallowCommonState) => void> {
    return this._updates;
  }

  get latestState() {
    return this._latestState;
  }

  @action setState(state?: ShallowCommonState) {
    this._latestState = state;
    if (state) {
      this._updates.send(state);
    }
  }

  @action initialize(state: ShallowCommonState) {
    if (!this._latestState) {
      this._latestState = state;
    }
  }
}
