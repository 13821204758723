import {BottomSheet} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';

import type {ErrorModalProps} from './ErrorModal';
import FinalMessageContent from './ErrorModalContent';

export type SmallErrorModalProps = ErrorModalProps;

export default observer(function SmallErrorModal(props: SmallErrorModalProps) {
  const onChange = (index: number) => {
    if (index === -1) {
      props.onCancel();
    }
  };

  const handleCancel = () => {
    props.onCancel();
  };

  return (
    <BottomSheet
      index={0}
      onChange={onChange}
      animateOnMount
      enablePanDownToClose
      enableDynamicSizing
      enableAutoHeight
      backdropProps={{appearsOnIndex: 0, disappearsOnIndex: -1}}
      onClose={handleCancel}
      children={<FinalMessageContent {...props} />}
    />
  );
});
