import type {CryptoCurrencyCode, InOutCurrency} from '@ncwallet-app/core';
import {useStrings, useStyles, useTheme, variance} from '@ncwallet-app/core';
import {getCompositeId} from '@ncwallet-app/core/src/NCWalletServer/InOutCurrency';
import {
  Button,
  LG_BREAKPOINT,
  SafeAreaFlatList,
  TouchableListItem,
  useIsDimensions,
} from '@ncwallet-app/ui';
import {
  ArrowLeftWide,
  CheckSvg,
} from '@ncwallet-app/ui/src/assets/svg/colorless';
import formatCurrencyShortDescription from '@ncwallet-app/ui/src/util/formatCurrencyShortDescription';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

export type NetworkSelectionScreenProps = {
  network: string;
  currency: CryptoCurrencyCode;
  networks: InOutCurrency[] | undefined;
  onSelect: (output: InOutCurrency) => void;
  goBack?: () => void;
  title?: string;
};

export default observer(function NetworkSelectionScreen({
  network,
  currency,
  networks,
  onSelect,
  goBack,
  title,
}: NetworkSelectionScreenProps) {
  const theme = useTheme();
  const styles = useListStyles();
  const isLg = useIsDimensions('lg');
  const strings = useStrings();

  return (
    <Root>
      <Inner>
        {isLg && goBack && (
          <Header>
            <BackButton
              testID="back_scrn_btn"
              title={title || strings['promptOutputNetwork.title']}
              onPress={goBack}
              Icon={ArrowLeftWide}
              iconCustomColor={theme.palette.uiAdditional1}
            />
          </Header>
        )}
        <SafeAreaFlatList
          data={networks}
          contentContainerStyle={styles.largeList}
          ItemSeparatorComponent={Divider}
          keyExtractor={getCompositeId}
          renderItem={({item}) => (
            <Item
              testID={`blockchain_item_${item.name.replace(/\s+/g, '_')}`}
              onPress={() => {
                onSelect(item);
              }}>
              <ItemBody>
                <ItemNetwork>
                  {formatCurrencyShortDescription(
                    item.name,
                    item.contract_type,
                  )}
                </ItemNetwork>
                {item.network === network && item.currency === currency && (
                  <CheckSvg color={theme.palette.info} />
                )}
              </ItemBody>
            </Item>
          )}
        />
      </Inner>
    </Root>
  );
});

const useListStyles = () =>
  useStyles(theme => ({
    largeList: {
      ...theme.mediaQuery({
        [LG_BREAKPOINT]: {
          backgroundColor: theme.palette.uiPrimary,
          borderRadius: 8,
          borderWidth: 1,
          borderColor: theme.palette.uiSecondary,
        },
      }),
    },
  }));

const BackButton = variance(Button)(() => ({
  root: {
    marginBottom: 20,
    padding: 0,
    borderWidth: 0,
    justifyContent: 'flex-start',
    fontSize: 18,
    lineHeight: 22,
  },
}));

const Header = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const Item = variance(TouchableListItem)(() => ({
  root: {
    paddingVertical: 20,
    paddingHorizontal: 15,
  },
}));

const ItemBody = variance(View)(() => ({
  root: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const ItemNetwork = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 16,
    lineHeight: 19,
    color: theme.palette.textPrimary,
  },
}));

const Divider = variance(View)(theme => ({
  root: {
    width: '100%',
    height: 1,
    backgroundColor: theme.palette.uiSecondary,
  },
}));

const Root = variance(View)(theme => ({
  root: {
    flex: 1,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        backgroundColor: theme.palette.uiPrimary,
      },
    }),
  },
}));

const Inner = variance(View)(theme => ({
  root: {
    flex: 1,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        backgroundColor: theme.palette.background,
        padding: 30,
        borderRadius: 10,
      },
    }),
  },
}));
