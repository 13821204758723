import type {NavigationState} from '@react-navigation/routers';

import {BusImpl} from '../structure';
import type {BusSource} from '../structure';
import type {AuthStackNavigation} from './AuthStackNavigation';

export default class AuthStackNavigationImpl implements AuthStackNavigation {
  private _authStackRoute: string | undefined;
  private readonly _updates = new BusImpl<
    (current: string | undefined) => void
  >();

  get updates(): BusSource<(current: string | undefined) => void> {
    return this._updates;
  }

  getAuthStackRoute = () => {
    return this._authStackRoute;
  };

  setAuthStackRoute = (state?: NavigationState) => {
    this._authStackRoute = state?.routes.length
      ? state.routes[state.routes.length - 1].name
      : undefined;
    this._updates.send(this._authStackRoute);
  };
}
