/* eslint-disable @typescript-eslint/no-non-null-assertion,@typescript-eslint/unbound-method */
import type {CryptoAddress} from '@ncwallet-app/core';
import {urlLinks, useRoot, useStrings} from '@ncwallet-app/core';
import useSendAddressErrorTexts from '@ncwallet-app/core/src/AddressUriHelper/useSendAddressErrorTexts';
import {SafeAreaInset} from '@ncwallet-app/ui';
import {useHeaderHeight} from '@react-navigation/elements';
import {BigNumber} from 'bignumber.js';
import {noop} from 'lodash';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import {usePromptOutputAddressBindingState} from '../../../../../CommonNavigationContainers/hooks/PromptOutputAddressBindingState/usePromptOutputAddressBindingState';
import {useSendAmountErrorTexts} from '../../../../../CommonNavigationContainers/hooks/PromptOutputAddressBindingState/useSendAmountErrorTexts';
import {useRedirectToNotFoundIfNoWalletWithId} from '../../../../../CommonNavigationContainers/hooks/redirectToNotFoundOnWrongDataHooks';
import {isChromeExtension} from '../../../../../Navigation/hooks/isChromeExtension';
import {useGoToCurrencyScreenIfNotMoney} from '../../../../../Navigation/hooks/useGoToCurrencyScreenIfNotMoney';
import {EXTRA_BOTTOM_OFFSET} from '../../../../../screens/constants';
import {LgSendScreen} from '../../../../../screens/SendScreen';
import type {LargeSwitchBindingProps} from '../LargeSwitchBindingProps';

export type PromptOutputAddressBindingProps =
  LargeSwitchBindingProps<'PromptOutputAddress'>;

export default observer(function PromptOutputAddressBinding(
  props: PromptOutputAddressBindingProps,
) {
  const {route, navigation} = props;
  const {
    fee,
    isEditable,
    walletId,
    addressTo,
    amount,
    feeChangeDisabled,
    comment,
    addressNetwork,
    addressCurrency,
    minFreeWithdrawAmount,
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  } = route.params ?? {};
  const root = useRoot();
  const [state] = usePromptOutputAddressBindingState(
    walletId,
    addressNetwork,
    addressCurrency,
    addressTo as CryptoAddress,
    amount,
    comment,
    minFreeWithdrawAmount,
    fee,
  );

  useRedirectToNotFoundIfNoWalletWithId(walletId);

  useGoToCurrencyScreenIfNotMoney(state.wallet);

  const showAddressAction = !isChromeExtension() || root.appViewport.isPopup;
  const goToQrScreen = useCallback(async () => {
    await root.qrCodeScanner.open();
  }, [root]);

  const navigateToCommission = useCallback(() => {
    navigation.navigate('PromptCommissionForSending', {
      walletId,
      addressCurrency,
      addressNetwork: state.addressNetwork || addressNetwork,
      addressTo: state.addressTo,
      amount: state.amount!,
      fee: state.fees?.[0],
      currency: state.wallet!.currency,
      minFreeWithdrawAmount: route.params.minFreeWithdrawAmount,
      comment: state.comment,
    });
  }, [
    navigation,
    walletId,
    addressCurrency,
    state.addressNetwork,
    state.addressTo,
    state.amount,
    state.fees,
    state.wallet,
    state.comment,
    addressNetwork,
    route.params.minFreeWithdrawAmount,
  ]);

  const navigateToConfirm = useCallback(
    (fee_: string | undefined, feeChangeDisabled_: boolean | undefined) => {
      navigation.navigate('PromptConfirmationToSendCrypto', {
        feeChangeDisabled: feeChangeDisabled_,
        addressCurrency,
        addressNetwork: state.addressNetwork || addressNetwork,
        fee: fee_,
        walletId,
        comment: state.comment,
        addressTo: state.addressTo,
        amount: state.amount!,
        minFreeWithdrawAmount: route.params.minFreeWithdrawAmount,
        currency: state.wallet!.currency,
      });
    },
    [
      navigation,
      addressCurrency,
      state.addressNetwork,
      state.comment,
      state.addressTo,
      state.amount,
      state.wallet,
      addressNetwork,
      walletId,
      route.params.minFreeWithdrawAmount,
    ],
  );

  const addressErrorTexts = useSendAddressErrorTexts();
  const amountErrorTexts = useSendAmountErrorTexts(state.min, state.minFee);
  const goToFAQ = useCallback(() => {
    void root.location.open(urlLinks.internalComment);
  }, [root.location]);
  const handleSubmit = useCallback(async () => {
    if (state.isValidatingAddressTo || !state.wallet || !state.fees) {
      return;
    }
    state.validateAmount();
    await state.validateAddressTo();
    const error =
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      amountErrorTexts[state.amountError!] ??
      addressErrorTexts[state.addressToError!];
    if (error) {
      return false;
    }

    navigation.setParams({
      addressNetwork: state.addressNetwork,
      amount: state.amount,
      addressTo: state.addressTo,
      comment: state.comment,
      minFreeWithdrawAmount: route.params.minFreeWithdrawAmount,
    });

    const hasFee = fee && BigNumber(fee).isGreaterThan(0);

    if (isEditable && (hasFee || state.isEnoughAmountForFreeWithdrawal)) {
      navigateToConfirm(fee, feeChangeDisabled);
      return;
    }

    if (state.fees.length === 1 && state.isFreeWithdrawalUnavailable) {
      navigateToConfirm(state.fees[0], true);
    } else {
      navigateToCommission();
    }
    return true;
  }, [
    state,
    amountErrorTexts,
    addressErrorTexts,
    navigation,
    route.params.minFreeWithdrawAmount,
    fee,
    isEditable,
    navigateToConfirm,
    feeChangeDisabled,
    navigateToCommission,
  ]);

  const navigateToPromptNetwork = useCallback(() => {
    navigation.navigate('PromptOutputNetwork', {
      addressNetwork: state.addressNetwork || addressNetwork,
      addressCurrency: route.params.addressCurrency,
      walletId,
      fee,
      addressTo: state.addressTo,
      amount: state.amount!,
      minFreeWithdrawAmount: route.params.minFreeWithdrawAmount,
      currency: state.wallet!.currency,
    });
  }, [
    navigation,
    state.addressNetwork,
    state.addressTo,
    state.amount,
    state.wallet,
    addressNetwork,
    route.params.addressCurrency,
    route.params.minFreeWithdrawAmount,
    walletId,
    fee,
  ]);
  const strings = useStrings();
  const header = useHeaderHeight();

  const handleNavigateToCurrency = useCallback(() => {
    navigation.navigate('PromptCryptoToSend');
  }, [navigation]);

  return (
    <LgSendScreen
      onCurrencyPress={handleNavigateToCurrency}
      addressNameShown={state.networkShown}
      addressName={state.addressName}
      networkCurrency={state.networkCurrency}
      onAddressNamePress={navigateToPromptNetwork}
      networkChangeEnabled={state.networkChangeEnabled}
      cryptoCurrency={state.cryptoCurrency}
      walletTotal={state.wallet?.total}
      fiatCurrency={state.fiatCurrency}
      fiatValue={state.fiatTotal}
      onBlurAddressInput={state.onBlurAddressInput}
      max={state.max}
      maxRestrictedByWalletLimit={state.maxRestrictedByWalletLimit}
      onUserToChange={noop}
      walletName={strings['sendCrypto.walletName']}
      stepTotal={3}
      stepCurrent={1}
      userTo={''}
      addressToError={addressErrorTexts[state.addressToError!]}
      onUserActionPress={noop}
      showAddressAction={showAddressAction}
      onAddressActionPress={goToQrScreen}
      keyboardAvoiding
      keyboardVerticalOffset={header}
      insets={SafeAreaInset.BOTTOM}
      extra={EXTRA}
      isEditable={isEditable}
      comment={state.comment}
      onCommentChange={state.setComment}
      amount={state.amount}
      amountError={amountErrorTexts[state.amountError!]}
      goToFAQ={goToFAQ}
      maxError={
        state.isMinFeeMoreThanAvailableMax
          ? strings['sendCrypto.validation.networkFeeExceedsMax']
          : undefined
      }
      addressTo={state.addressTo}
      onAmountChange={state.setAmount}
      onAddressToChange={state.setAddressTo}
      onSubmit={handleSubmit}
      contractType={state.contractType}
      isBlockchainComment={state.isBlockchainComment}
    />
  );
});

const EXTRA = {bottom: EXTRA_BOTTOM_OFFSET};
