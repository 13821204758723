import type {AuthScreenFooterProps} from '@ncwallet-app/core/src/Components/AuthScreenFooter';
import {AuthScreenFooter} from '@ncwallet-app/core/src/Components/AuthScreenFooter';
import {ButtonChooseFileStub} from '@ncwallet-app/core/src/Components/ButtonChooseFile';
import type {ButtonPortalProps} from '@ncwallet-app/core/src/Components/ButtonPortal';
import {ButtonPortal} from '@ncwallet-app/core/src/Components/ButtonPortal';
import type {
  ContentLayoutProps,
  ScrollToEndRefType,
} from '@ncwallet-app/core/src/Components/ContentLayout';
import {ContentLayout} from '@ncwallet-app/core/src/Components/ContentLayout';
import type {LgProfileEmailButtonProps} from '@ncwallet-app/core/src/Components/LgProfileEmailButton';
import {LgProfileEmailButton} from '@ncwallet-app/core/src/Components/LgProfileEmailButton';
import type {LogButtonProps} from '@ncwallet-app/core/src/Components/LogButton';
import {LogButton} from '@ncwallet-app/core/src/Components/LogButton';
import type {PlatformSpecific} from '@ncwallet-app/core/src/Components/PlatformSpecificComponents';
import type {ProfileEmailButtonProps} from '@ncwallet-app/core/src/Components/ProfileEmailButton';
import {ProfileEmailButton} from '@ncwallet-app/core/src/Components/ProfileEmailButton';
import type {AnimatedBottomTabBarProps} from '@ncwallet-app/navigation/src/components';
import {AnimatedBottomTabBar} from '@ncwallet-app/navigation/src/components';
import type {DebugScreenProps} from '@ncwallet-app/navigation/src/screens/DebugScreen';
import {DebugScreen} from '@ncwallet-app/navigation/src/screens/DebugScreen';
import type {DetailsTelegramButtonProps} from '@ncwallet-app/navigation/src/screens/ProfileSettingsScreen/LgProfileCards/DetailsTelegramButton';
import DetailsTelegramButton from '@ncwallet-app/navigation/src/screens/ProfileSettingsScreen/LgProfileCards/DetailsTelegramButton';
import type {ProfileTelegramButtonProps} from '@ncwallet-app/navigation/src/screens/ProfileSettingsScreen/ProfileTelegramButton';
import {ProfileTelegramButton} from '@ncwallet-app/navigation/src/screens/ProfileSettingsScreen/ProfileTelegramButton';
import type {QrCodeScreenProps} from '@ncwallet-app/navigation/src/screens/QrCodeScreen';
import {QrCodeScreen} from '@ncwallet-app/navigation/src/screens/QrCodeScreen';
import React, {forwardRef} from 'react';

export default class WebPlatformSpecificComponents implements PlatformSpecific {
  AuthScreenFooter = (props: AuthScreenFooterProps) => {
    return <AuthScreenFooter {...props} />;
  };

  ProfileTelegramButton = (props: ProfileTelegramButtonProps) => {
    return <ProfileTelegramButton {...props} />;
  };

  ProfileEmailButton = (props: ProfileEmailButtonProps) => {
    return <ProfileEmailButton {...props} />;
  };

  LgProfileEmailButton = (props: LgProfileEmailButtonProps) => {
    return <LgProfileEmailButton {...props} />;
  };

  DebugScreen = (props: DebugScreenProps) => {
    return <DebugScreen {...props} />;
  };

  LogButton = (props: LogButtonProps) => {
    return <LogButton {...props} />;
  };

  AnimatedBottomTabBar = (props: AnimatedBottomTabBarProps) => {
    return <AnimatedBottomTabBar {...props} />;
  };

  QrCodeScreen = (props: QrCodeScreenProps) => {
    return <QrCodeScreen {...props} />;
  };

  DetailsTelegramButton = (props: DetailsTelegramButtonProps) => {
    return <DetailsTelegramButton {...props} />;
  };

  ContentLayout = forwardRef<ScrollToEndRefType, ContentLayoutProps>(
    (props, ref) => {
      return <ContentLayout {...props} ref={ref} />;
    },
  );

  ButtonPortal = (props: ButtonPortalProps) => {
    return (
      <ButtonPortal portalKey={props.portalKey}>{props.children}</ButtonPortal>
    );
  };
  ButtonChooseFile = () => {
    return <ButtonChooseFileStub />;
  };
}
