import {useStrings, variance} from '@ncwallet-app/core';
import {Button, ButtonVariant} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

export type DeleteAccountScreenBodyProps = {
  onClose: () => void;
  onDelete: () => void;
  onSupport: () => void;
  haveFounds: boolean;
};

export default observer(function DeleteAccountScreenBody({
  onClose,
  onDelete,
}: DeleteAccountScreenBodyProps) {
  const strings = useStrings();
  return (
    <Container>
      <Title>{strings['deleteAccountModal.description']}</Title>
      <>
        <Button
          testID="approve-remove-cancel"
          onPress={onClose}
          title={strings['deleteAccountModal.cancel']}
          variant={ButtonVariant.Primary}
        />
        <Divider />
        <Button
          testID="approve-remove-btn"
          onPress={onDelete}
          title={strings['deleteAccountModal.delete']}
          variant={ButtonVariant.Danger}
        />
      </>
    </Container>
  );
});

const Divider = variance(View)(() => ({
  root: {
    height: 20,
  },
}));

const Container = variance(View)(() => ({
  root: {
    paddingHorizontal: 15,
    paddingTop: 20,
  },
}));

const Title = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    color: theme.palette.textAdditional1,
    marginBottom: 20,
    fontSize: 14,
    lineHeight: 24,
  },
}));
