import {variance} from '@ncwallet-app/core';
import {TouchableOpacity} from '@ncwallet-app/ui';
import {CheckSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

export type TermsCheckboxProps = {
  label: string;
  isChecked: boolean;
  onPress: () => void;
  testID?: string;
};

export default observer(function TermsCheckbox(props: TermsCheckboxProps) {
  const {label, isChecked, onPress, testID} = props;
  return (
    <RootView onPress={onPress}>
      <CheckboxView checked={isChecked} testID={testID}>
        {isChecked && <CheckboxIcon />}
      </CheckboxView>
      <LabelText>{label}</LabelText>
    </RootView>
  );
});

const RootView = variance(TouchableOpacity)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const CheckboxIcon = variance(CheckSvg)(
  () => ({
    root: {},
  }),
  theme => ({
    color: theme.palette.whitey,
    width: 12,
    height: 12,
  }),
);

const CheckboxView = variance(View)(theme => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 16,
    height: 16,
    marginRight: 10,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: theme.palette.uiSecondary,
  },
  checked: {
    backgroundColor: theme.palette.info,
    borderColor: theme.palette.info,
  },
}));

const LabelText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    fontSize: 12,
    color: theme.palette.textAdditional2,
  },
}));
