import {useStrings, variance} from '@ncwallet-app/core';
import {IpType} from '@ncwallet-app/core/src/NCWalletServer/ipSettings/IpType';
import type {LocaleKeys} from '@ncwallet-app/localization/locale/LocaleStrings';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';
import {Text, View} from 'react-native';

export type IpListEmptyProps = {
  type: IpType;
};

export default observer(function IpListEmpty(props: IpListEmptyProps) {
  const {type} = props;
  const {title, text} = useGetIpEmptyListStrings(type);
  const strings = useStrings();
  return (
    <Container>
      <EmptyListTitle>{strings[title]}</EmptyListTitle>
      <EmptyListText>{strings[text]}</EmptyListText>
    </Container>
  );
});

type EmptyStrings = {
  title: LocaleKeys;
  text: LocaleKeys;
};

const useGetIpEmptyListStrings = (type: IpType): EmptyStrings => {
  return useMemo(
    () => ({
      title:
        type === IpType.Allowed
          ? 'allowedIpList.empty.title'
          : 'blockedIpList.empty.title',
      text:
        type === IpType.Allowed
          ? 'allowedIpList.empty.description'
          : 'blockedIpList.empty.description',
    }),
    [type],
  );
};

const Container = variance(View)(() => ({
  root: {
    paddingVertical: 20,
    paddingHorizontal: 15,
  },
}));

const EmptyListTitle = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 16,
    color: theme.palette.textPrimary,
  },
}));

const EmptyListText = variance(Text)(theme => ({
  root: {
    marginTop: 15,
    ...theme.fontByWeight('500'),
    fontSize: 13,
    lineHeight: 20,
    color: theme.palette.textPrimary,
  },
}));
