import {Portal} from '@gorhom/portal';
import {useRoot} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React, {useEffect} from 'react';
import {Keyboard} from 'react-native';

import type {BuyLinkError} from '../../../Navigation/HomeStack/BuyLinkBinding/BuyLinkBindingState';
import LargeErrorModal from './LargeErrorModal';
import SmallErrorModal from './SmallErrorModal';

export type ErrorModalProps = {
  code?: BuyLinkError;
  onCancel(): void;
};

export default observer(function ErrorModal(props: ErrorModalProps) {
  const {layoutHelperState} = useRoot();

  const isLarge = layoutHelperState.isLarge;

  useEffect(() => {
    if (props.code) {
      Keyboard.dismiss();
    }
  }, [props.code]);

  if (!props.code) {
    return null;
  }

  return (
    <Portal>
      {isLarge ? (
        <LargeErrorModal {...props} />
      ) : (
        <SmallErrorModal {...props} />
      )}
    </Portal>
  );
});
