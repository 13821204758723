import {reaction} from 'mobx';

import type {AppWindow} from '../AppWindow';
import {APP_WINDOW_ACTIVE} from '../AppWindow';
import type {AccountIdStore} from '../Auth';
import {LOG_OUT} from '../Auth';
import type {Configuration} from '../Configuration';
import type {FreshAccessTokenState} from '../FreshAccessTokenState';
import type {Service} from '../structure';
import {batchDisposers} from '../structure';
import type {Connection, ConnectionState} from './Connection';
import {ConnectionStatus} from './Connection';
import type {ConnectionSpawner} from './ConnectionSpawner';
import type {RetryStrategy, RetryStrategyState} from './RetryStrategy';
import {
  RETRY_STRATEGY_IDLE,
  RETRY_STRATEGY_PENDING,
  RETRY_STRATEGY_SUCCEEDED,
} from './RetryStrategy';

export default class ConnectionSpawnerService
  implements ConnectionSpawner, Service
{
  constructor(
    private readonly _root: {
      readonly appWindow: AppWindow;
      readonly retryStrategy: RetryStrategy;
      readonly retryStrategyState: RetryStrategyState;
      readonly accountIdStore: AccountIdStore;
      readonly connection: Connection;
      readonly configuration: Configuration;
      readonly connectionState: ConnectionState;
      readonly freshAccessTokenState: FreshAccessTokenState;
    },
  ) {}

  async tryConnection() {
    await this._root.connection.disconnect();
    this._root.retryStrategy.start();
  }

  enable() {
    void this.tryConnection();
  }

  async giveUpConnection() {
    this._root.retryStrategy.stop();
    await this._root.connection.disconnect();
  }

  disable() {
    void this.giveUpConnection();
  }

  private _disconnectOnLogOut() {
    return this._root.accountIdStore.events.listen(LOG_OUT, () =>
      this.giveUpConnection(),
    );
  }

  private _updateConnectionWhenAppBecomesActive() {
    return this._root.appWindow.updates.listen(APP_WINDOW_ACTIVE, async () => {
      await this._giveUpConnectionIfNeeded();
      await this._tryConnectionIfNeeded();
    });
  }

  private _reconnectWhenTokenBecomesFresh() {
    return reaction(this._shouldConnect, this._handleConnection);
  }

  private readonly _shouldConnect = () =>
    this._root.freshAccessTokenState.isFresh &&
    this._root.connectionState.latestStatus === ConnectionStatus.Closed &&
    this._root.retryStrategyState.latestStatus === RETRY_STRATEGY_IDLE &&
    (this._root.retryStrategyState.latestResult === RETRY_STRATEGY_SUCCEEDED ||
      this._root.retryStrategyState.latestResult === undefined);

  private readonly _handleConnection = async (shouldConnect: boolean) => {
    if (shouldConnect) {
      await this.tryConnection();
    }
  };

  private _reconnectOnEnvChange() {
    return reaction(
      () => this._root.configuration.current,
      this._tryConnectionIfNeeded,
    );
  }

  private readonly _tryConnectionIfNeeded = () =>
    this._handleConnection(this._shouldConnect());

  private _cancelConnectionAttemptWhenTokenBecomesStale() {
    return reaction(this._shouldDisconnect, this._handleDisconnection);
  }

  private readonly _shouldDisconnect = () =>
    this._root.freshAccessTokenState.isStale &&
    this._root.retryStrategyState.latestStatus === RETRY_STRATEGY_PENDING;

  private readonly _handleDisconnection = async (shouldDisconnect: boolean) => {
    if (shouldDisconnect) {
      this._root.retryStrategy.revertPending();
      await this._root.connection.disconnect();
    }
  };

  private readonly _giveUpConnectionIfNeeded = () =>
    this._handleDisconnection(this._shouldDisconnect());

  subscribe() {
    return batchDisposers(
      this._disconnectOnLogOut(),
      this._updateConnectionWhenAppBecomesActive(),
      this._reconnectWhenTokenBecomesFresh(),
      this._reconnectOnEnvChange(),
      this._cancelConnectionAttemptWhenTokenBecomesStale(),
    );
  }
}
