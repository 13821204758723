import {useStrings, useTheme, variance} from '@ncwallet-app/core';
import {
  Button,
  ButtonColor,
  ModalContainer,
  TouchableOpacity,
} from '@ncwallet-app/ui';
import {CheckSvg, CrossSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

import {
  NEXT_MONTH,
  PREV_MONTH,
  SET_NEW_DAY,
} from './CalendarModalActionsConstant';
import CalendarModalDays from './CalendarModalDays';
import CalendarModalMonthSwitcher from './CalendarModalMonthSwitcher';
import CalendarModalMonthView from './CalendarModalMonthView';
import {useCalendarState} from './CalendarModalReducer';
import {useCalendarInfo} from './CalendarModalSettings';

export type CalendarModalScreen = {
  onApply: (from: CalendarParams) => void;
  onBack: () => void;
  value?: Date;
};

export type CalendarParams = {
  year: number;
  day: number;
  month: number;
};

export default observer(function CalendarModalScreen({
  onApply,
  onBack,
  value,
}: CalendarModalScreen) {
  const strings = useStrings();
  const theme = useTheme();
  const [state, dispatch] = useCalendarState(value);
  const calendarBaseInfo = useCalendarInfo();

  const isLastMonth = state.month === new Date().getMonth();
  const onNextMonth = () => {
    dispatch({type: NEXT_MONTH});
  };
  const onPrevMonth = () => {
    dispatch({type: PREV_MONTH});
  };
  const setNewDay = (day: number, month: number) => {
    dispatch({type: SET_NEW_DAY, payload: {day: day, month: month}});
  };
  return (
    <CalendarBackdrop testID="calendar-modal">
      <Calendar>
        <CalendarHeader>
          <CalendarHeaderTitle>
            {todayDateFormat(state.date, calendarBaseInfo)}
          </CalendarHeaderTitle>
          <TouchableOpacity
            testID="close"
            onPress={() => {
              onBack();
            }}>
            <CrossSvg color={theme.palette.whitey} />
          </TouchableOpacity>
        </CalendarHeader>
        <CalendarBody>
          <CalendarModalMonthSwitcher
            date={`${calendarBaseInfo.month[state.month]} ${state.year}`}
            onNextMonth={onNextMonth}
            onPrevMonth={onPrevMonth}
            lastMonth={isLastMonth}
          />
          <CalendarModalDays />
          <CalendarModalMonthView
            setNewDay={setNewDay}
            state={state}
            onBack={() => {
              onApply({
                year: state.year,
                month: state.month,
                day: state.date.getDate(),
              });
            }}
          />
        </CalendarBody>
        <CalendarButtonApply
          testID="apply_calendar_btn"
          title={strings['historyPeriodFilterScreen.submit']}
          color={ButtonColor.Secondary}
          Icon={CheckSvg}
          onPress={() => {
            onApply({
              year: state.year,
              month: state.month,
              day: state.date.getDate(),
            });
          }}
        />
      </Calendar>
    </CalendarBackdrop>
  );
});

const todayDateFormat = (
  date: Date,
  calendarBaseInfo: ReturnType<typeof useCalendarInfo>,
) => {
  return `${calendarBaseInfo.days[date.getDay()]}, ${date.getDate()} ${
    calendarBaseInfo.month[date.getMonth()]
  } ${date.getFullYear()}`;
};

const CalendarBackdrop = variance(ModalContainer)(theme => ({
  root: {
    backgroundColor: theme.chroma('#000000').alpha(0.7).hex(),
  },
}));

const Calendar = variance(View)(theme => ({
  root: {
    borderRadius: 6,
    maxWidth: 300,
    width: '100%',
    backgroundColor: theme.palette.background,
  },
}));

const CalendarHeader = variance(View)(theme => ({
  root: {
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    backgroundColor: theme.palette.primary,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 15,
    paddingVertical: 20,
  },
}));

const CalendarHeaderTitle = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.whitey,
    fontSize: 18,
    lineHeight: 22,
  },
}));

const CalendarBody = variance(View)(() => ({
  root: {
    paddingHorizontal: 10,
    paddingBottom: 15,
    paddingTop: 15,
  },
}));

const CalendarButtonApply = variance(Button)(theme => ({
  root: {
    border: 'none',
    backgroundColor: theme.palette.uiPrimary,
    marginTop: 'auto',
  },
}));
