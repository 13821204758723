import {useTheme} from '@ncwallet-app/core';
import {Separator, Switch} from '@ncwallet-app/ui/src';
import {ArrowRightSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import {type SvgProps} from 'react-native-svg';

import type {SettingsItemProps} from '../UserSettingsScreens/SharedComponents/SettingsItem';
import {
  ItemCol,
  ItemTitle,
  ItemTouchable,
} from '../UserSettingsScreens/SharedComponents/SettingsItem';

export type SecuritySettingsItemProps = SettingsItemProps & {
  switched?: boolean;
  getIsActive?: () => boolean;
  onToggle?: () => void;
  testID?: string;
  Icon?: React.ComponentType<SvgProps>;
  last?: boolean;
};

export default observer(function SecuritySettingsItem(
  props: SecuritySettingsItemProps,
) {
  const title = props.getTitle?.() ?? props.title;
  const {Icon, last} = props;
  const theme = useTheme();

  const stylesIcon = StyleSheet.create({
    root: {
      marginRight: 16,
      width: 22,
      height: 22,
    },
  });

  return (
    <View>
      <ItemTouchable
        onPress={props.onPress}
        Icon={!props.switched ? ArrowRightSvg : null}
        disabled={props.disabled}
        bodyStyle={style.spaceBetween}
        testID={props.testID}>
        {Icon ? (
          <Icon color={theme.palette.primary} style={stylesIcon.root} />
        ) : null}

        <ItemCol>
          <ItemTitle>{title}</ItemTitle>
        </ItemCol>
        {props.switched && (
          <Switch
            onChange={props.onToggle}
            style={style.switch}
            getIsActive={props.getIsActive}
          />
        )}
      </ItemTouchable>
      {!last && (
        <Separator
          style={{
            marginLeft: 54,
            marginRight: 0,
          }}
        />
      )}
    </View>
  );
});

const style = StyleSheet.create({
  spaceBetween: {
    justifyContent: 'space-between',
    width: '100%',
  },
  switch: {
    marginTop: -6.5,
    marginBottom: -6.5,
  },
});
