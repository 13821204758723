import type {AdvertAction} from '@ncwallet-app/core';
import {useRoot} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo} from 'react';

import {PushAdvertSplashScreen} from '../../screens/PushAdvertSplash';

export default observer(function PushAdvertSplashContainer() {
  const {pushAdvert, location, advertHelper} = useRoot();

  const onClose = useCallback(() => {
    pushAdvert.close();
  }, [pushAdvert]);

  const onButtonActionPress = useCallback(
    async (action: AdvertAction) => {
      if (action.link) {
        await location.open(action.link);
      }
      onClose();
    },
    [location, onClose],
  );

  const context = useMemo(() => {
    return (
      pushAdvert.advert && advertHelper.localizeAndPickImages(pushAdvert.advert)
    );
  }, [pushAdvert.advert, advertHelper]);

  if (!pushAdvert.advert || !context) {
    return null;
  }

  return (
    <PushAdvertSplashScreen
      context={context}
      onButtonActionPress={onButtonActionPress}
      onClose={onClose}
    />
  );
});
