import {useStrings, variance} from '@ncwallet-app/core';
import {ModalContainer, TouchableOpacity} from '@ncwallet-app/ui';
import {CrossSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

import LinkToTelegramScreenBody from './LinkToTelegramScreenBody';

export type LinkToTelegramModalProps = {
  telegramBotId: string;
  telegramOauthOrigin: string;
  redirectUriForTelegramLinking: string;
  onClose: () => void;
  testId?: string;
};

export default observer(function LinkToTelegramModal(
  props: LinkToTelegramModalProps,
) {
  const strings = useStrings();
  return (
    <Backdrop>
      <Card>
        <CardHeader>
          <CardHeaderText>{strings['linkToTelegram.title']}</CardHeaderText>
          <TouchableOpacityStyled onPress={props.onClose} testID="close">
            <CrossIcon />
          </TouchableOpacityStyled>
        </CardHeader>
        <LinkToTelegramScreenBody {...props} inBottomSheet={false} />
      </Card>
    </Backdrop>
  );
});

const CrossIcon = variance(CrossSvg)(
  () => ({root: {}}),
  theme => ({
    color: theme.palette.whitey,
    width: 22,
    height: 22,
  }),
);

const Backdrop = variance(ModalContainer)(theme => ({
  root: {
    backgroundColor: theme.chroma('#000000').alpha(0.7).hex(),
  },
}));

const TouchableOpacityStyled = variance(TouchableOpacity)(theme => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    height: 32,
    borderRadius: 16,
    backgroundColor: theme.chroma('#000000').alpha(0.2).hex(),
  },
}));

const CardHeader = variance(View)(theme => ({
  root: {
    display: 'flex',
    padding: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    marginBottom: 20,
    borderBottomColor: theme.palette.uiSecondary,
  },
}));

const CardHeaderText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    fontSize: 18,
    lineHeight: 22,
  },
}));

const Card = variance(View)(theme => ({
  root: {
    borderRadius: 8,
    backgroundColor: theme.palette.background,
    width: 620,
  },
}));
