import type {Millisecond} from '@ncwallet-app/core';
import {sized, useTheme, variance} from '@ncwallet-app/core';
import {TouchableOpacity} from '@ncwallet-app/ui';
import {CrossSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';

export type PushAdvertSplashCloseProps = {
  onClose: () => void;
  deadline?: Millisecond;
};

export default observer(function PushAdvertSplashClose({
  onClose,
}: PushAdvertSplashCloseProps) {
  const theme = useTheme();

  return (
    <Container onPress={onClose}>
      <CloseIcon color={theme.palette.whitey} />
    </Container>
  );
});

const CloseIcon = sized(CrossSvg, 18, 18);

const Container = variance(TouchableOpacity)(theme => ({
  root: {
    padding: 7,
    backgroundColor: theme.palette.blackout,
    opacity: 0.4,
    borderRadius: 50,
    elevation: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  finished: {
    width: 30,
    height: 30,
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
}));
