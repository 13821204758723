import {useStrings, variance} from '@ncwallet-app/core';
import {TwoFaProviderKind} from '@ncwallet-app/core/src/TwoFaHelper';
import type {LocaleDict} from '@ncwallet-app/localization/locale/LocaleStrings';
import {LG_BREAKPOINT, TouchableListItem} from '@ncwallet-app/ui';
import {
  ArrowRightSvg,
  CheckSvg,
} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';
import {Text, View} from 'react-native';

export type TwoFaProviderListItemProps = {
  isCurrent: boolean;
  kind: TwoFaProviderKind;
  onPress: (() => void) | null;
};

export default observer(function TwoFaProviderListItem(
  props: TwoFaProviderListItemProps,
) {
  const {kind, isCurrent, onPress} = props;

  const testId = useMemo(() => {
    switch (kind) {
      case TwoFaProviderKind.Ga:
        return '2fa_google';
      case TwoFaProviderKind.Akm:
        return '2fa_apple';
      case TwoFaProviderKind.Telegram:
        return '2fa_tg';
    }
  }, [kind]);

  if (onPress) {
    return (
      <ProviderListItemPressable onPress={onPress} testID={testId}>
        <ProviderListItemBody kind={kind} />
      </ProviderListItemPressable>
    );
  }

  return (
    <ProviderListItem current={isCurrent} testID={testId}>
      <ProviderListItemBody kind={kind} isCurrent={isCurrent} />
    </ProviderListItem>
  );
});

const ProviderListItemBody = observer(
  ({kind, isCurrent}: {kind: TwoFaProviderKind; isCurrent?: boolean}) => {
    const strings = useStrings();

    return (
      <ProviderListItemBodyView>
        <ProviderListItemText>
          {mapTwoProviderKindToTitle(kind, strings)}
        </ProviderListItemText>
        {isCurrent ? <CheckIcon /> : <ProviderListItemIcon />}
      </ProviderListItemBodyView>
    );
  },
);

export const mapTwoProviderKindToTitle = (
  kind: TwoFaProviderKind,
  strings: Readonly<LocaleDict>,
) => {
  switch (kind) {
    case TwoFaProviderKind.Akm:
      return strings['twoFaSelectScreen.providers.akm'];
    case TwoFaProviderKind.Telegram:
      return strings['twoFaSelectScreen.providers.tg'];
    default:
      return strings['twoFaSelectScreen.providers.ga'];
  }
};

const ProviderListItem = variance(View)(theme => ({
  root: {
    gap: 5,
    paddingVertical: 29,
    paddingHorizontal: 15,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingVertical: 25,
      },
    }),
  },
  current: {
    backgroundColor: theme.palette.additional3,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingVertical: 23,
      },
    }),
  },
}));

const ProviderListItemPressable = variance(TouchableListItem)(theme => ({
  root: {
    gap: 5,
    paddingVertical: 30,
    paddingHorizontal: 15,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingVertical: 25,
      },
    }),
  },
}));

const ProviderListItemBodyView = variance(View)(() => ({
  root: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const ProviderListItemText = variance(Text)(theme => ({
  root: {
    color: theme.palette.textMain,
    fontSize: 16,
    ...theme.fontByWeight('700'),
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        fontSize: 14,
      },
    }),
  },
}));

const ProviderListItemIcon = variance(ArrowRightSvg)(
  () => ({
    root: {},
  }),
  theme => ({
    color: theme.palette.textAdditional1,
  }),
);

const CheckIcon = variance(CheckSvg)(
  () => ({
    root: {},
  }),
  theme => ({
    color: theme.palette.info,
  }),
);
