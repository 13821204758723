import {translateBreakpointToPixels} from '@ncwallet-app/ui/src';
import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx';
import {Platform} from 'react-native';

import type {AppStateHelper} from '../AppStateHelper';
import type {JsonRpcClient} from '../JsonRpc';
import type {LocationSource} from '../Location';
import type {
  Advert,
  AdvertId,
  NCWalletCallScheme,
  NCWalletNotificationScheme,
} from '../NCWalletServer';
import {AdvertType} from '../NCWalletServer';
import type {Service} from '../structure';
import {batchDisposers} from '../structure';
import type {Time} from '../Time';
import type {Url} from '../units';
import type {WindowDimensionsState} from '../WindowDimensions';
import type {PushAdvert} from './PushAdvert';

export default class PushAdvertService implements PushAdvert, Service {
  @observable private _advert: Advert | undefined;
  @observable private _timeoutId: NodeJS.Timeout | number | undefined;

  private static AD_ID_URL_PARAM = 'push_ad';

  constructor(
    private readonly _root: {
      readonly locationSource: LocationSource;
      readonly appStateHelper: AppStateHelper;
      readonly windowDimensionsState: WindowDimensionsState;
      readonly time: Time;
      readonly ncWalletJsonRpcClient: JsonRpcClient<
        NCWalletCallScheme,
        NCWalletNotificationScheme
      >;
    },
  ) {
    makeObservable(this);
  }

  @computed
  get advert() {
    return this._advert;
  }

  close = action(() => {
    this._advert = undefined;
    clearTimeout(this._timeoutId);
  });

  private _onAdShown() {
    if (Platform.OS === 'web') {
      const url = new URL(window.location.href);
      url.searchParams.delete(PushAdvertService.AD_ID_URL_PARAM);
      const updatedUrl = url.toString();
      history.replaceState(null, '', updatedUrl);
    }
  }

  private async _fetch(_: AdvertId) {
    const res = await this._root.ncWalletJsonRpcClient.call('ads.push.get', {});

    if (!res.success) {
      this.close();
      return;
    }

    const isDesktop =
      this._root.windowDimensionsState.window.width >=
      translateBreakpointToPixels('md');
    const type_ = isDesktop ? AdvertType.Desktop : AdvertType.Mobile;
    const ads_ = res.right.items;

    runInAction(() => {
      this._advert = ads_.find(({id, type}) => {
        return id === _ && type === type_;
      });
      if (this._advert) {
        this._onAdShown();
      }
    });
  }

  private _parseUrlSpots(url: Url): AdvertId | undefined {
    const queryString: string | undefined = url.split('?')[1] || '';
    const pairs = queryString.split('&');
    for (let i = 0; i < pairs.length; i++) {
      const pair = pairs[i].split('=');
      if (pair[0] === PushAdvertService.AD_ID_URL_PARAM) {
        return pair[1] as AdvertId;
      }
    }
    return undefined;
  }

  private _fetchOnReadyToMakeRequests() {
    return reaction(
      () => this._root.appStateHelper.isReadyToMakeRequests,
      async isReadyToMakeRequests => {
        if (isReadyToMakeRequests) {
          const initial_ = await this._root.locationSource.getInitial();
          if (!initial_.success) {
            return;
          }
          const spot = this._parseUrlSpots(initial_.right);
          if (spot) {
            await this._fetch(spot);
          }
        }
      },
      {fireImmediately: true},
    );
  }

  private _listenUpdateLocationSource() {
    return this._root.locationSource.updates.listen(async data => {
      const spot = this._parseUrlSpots(data);
      if (spot) {
        await this._fetch(spot);
      }
    });
  }

  subscribe() {
    return batchDisposers(
      this._fetchOnReadyToMakeRequests(),
      this._listenUpdateLocationSource(),
    );
  }
}
