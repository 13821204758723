import type {Palette} from './Coloring';

const palette: Palette = {
  background: '#1e1f21',
  foreground: '#ffffff',

  primary: '#F7931A',
  info: '#4285F4',
  error: '#F74249',
  success: '#37CC33',
  attention: '#F7C21A',
  blackout: '#000000',
  whitey: '#ffffff',

  textMain: '#ffffff',
  textPrimary: '#ededed',
  textSecondary: '#e3e3e3',
  textAdditional1: '#ABB0BB',
  textAdditional2: '#707279',
  textAdditional3: '#52555B',

  uiSystem: '#ffffff',
  uiMain: '#707279',
  uiAdditional1: '#434752',
  uiSecondary: '#3B3E47',
  uiPrimary: '#2A2C32',
  uiAdditional2: '#222325',

  mainGradientStart: '#000000',
  mainGradientEnd: '#494949',

  orangeGradientEnd: '#EC880F',

  blueGradientStart: '#085BE4',
  blueGradientEnd: '#4285F4',

  mainCardGradientStart: '#434448',
  mainCardGradientEnd: '#26292D',
  mainCardBackgroundStart: '#373E47',
  mainCardBackgroundEnd: '#35393D',
  mainCardAdditional1: '#383B43',
  mainCardAdditional2: '#4285F3',
  mainCardPrimary: '#2A2C32',
  mainCardSecondary: '#3B3E47',

  additional1: '#254A88',
  additional2: '#855C2e',
  additional3: '#2A2F3A',
  additional4: '#2d2825',
  additional5: '#362324',
  additional6: '#244A25',

  icon1: '#f7931a',
  icon2: '#ffffff',
  icon3: '#ededed',
};

export default palette;
