import {type FilterOption, variance} from '@ncwallet-app/core';
import {LG_BREAKPOINT, useIsDimensions} from '@ncwallet-app/ui';
import {
  UnknownCircleArrowSvg,
  UnknownCircleSvg,
} from '@ncwallet-app/ui/src/assets/svg/colored';
import {CheckSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, TouchableOpacity, View} from 'react-native';

export type ListWalletOptionsItemProps = {
  label: string;
  isActive: boolean;
  kind: FilterOption;
  onPress: () => void;
  testID?: string;
};

export default observer(function ListWalletOptionsItem(
  props: ListWalletOptionsItemProps,
) {
  const {isActive, kind, label, testID, onPress} = props;
  const isLg = useIsDimensions('lg');

  return (
    <TouchableOpacity testID={testID} onPress={!isActive ? onPress : undefined}>
      <RootView isActive={isActive}>
        <IconView green={kind === 'positive_funds'}>
          {kind === 'positive_funds' ? <PositiveIcon /> : <AllIcon />}
        </IconView>
        <LabelText isActive={isActive}>{label}</LabelText>
        {isActive && isLg && (
          <CheckIconView>
            <CheckIcon />
          </CheckIconView>
        )}
      </RootView>
    </TouchableOpacity>
  );
});

const RootView = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 15,
    padding: 15,
  },
  isActive: {
    backgroundColor: theme.palette.info,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        backgroundColor: theme.palette.additional4,
      },
    }),
  },
}));

const IconView = variance(View)(theme => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
    borderRadius: 40,
    backgroundColor: theme.palette.primary,
  },
  green: {
    backgroundColor: theme.palette.success,
  },
}));

const AllIcon = variance(UnknownCircleSvg)(
  () => ({
    root: {},
  }),
  theme => ({
    color: theme.palette.whitey,
  }),
);

const PositiveIcon = variance(UnknownCircleArrowSvg)(
  () => ({
    root: {},
  }),
  theme => ({
    color: theme.palette.whitey,
  }),
);

const LabelText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 16,
    color: theme.palette.textMain,
  },
  isActive: {
    color: theme.palette.whitey,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        color: theme.palette.primary,
      },
    }),
  },
}));

const CheckIconView = variance(View)(() => ({
  root: {
    marginLeft: 'auto',
  },
}));

const CheckIcon = variance(CheckSvg)(
  () => ({
    root: {},
  }),
  theme => ({
    color: theme.palette.primary,
  }),
);
