import {useTheme} from '@ncwallet-app/core';
import {Separator} from '@ncwallet-app/ui';
import {ArrowRightSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';
import {StyleSheet, View} from 'react-native';

import type {SettingsItemProps} from '../UserSettingsScreens/SharedComponents/SettingsItem';
import {
  ItemCol,
  ItemLabel,
  ItemTouchableWithLabel,
  TitleWithLabel,
} from '../UserSettingsScreens/SharedComponents/SettingsItem';

export default observer(function ProfileSettingsItem(props: SettingsItemProps) {
  const title = props.getTitle?.() ?? props.title;
  const error = props.getError?.() || props.error;
  const theme = useTheme();
  const {Icon, last} = props;

  const stylesIcon = StyleSheet.create({
    root: {
      marginRight: 16,
      width: 22,
      height: 22,
    },
  });

  const colorIcon = useMemo(() => {
    if (error) return theme.palette.error;
    else if (props.success) return theme.palette.success;
    else return theme.palette.primary;
  }, [error, props.success, theme.palette]);

  return (
    <View>
      <ItemTouchableWithLabel
        testID={props.testID}
        onPress={props.onPress}
        Icon={ArrowRightSvg}
        disabled={props.disabled}
        selected={props.selected}
        iconColor={props.selected ? theme.palette.primary : undefined}
        style={{borderBottomWidth: 0}}>
        {Icon ? <Icon color={colorIcon} style={stylesIcon.root} /> : null}
        <ItemCol>
          {!!props.label && (
            <ItemLabel selected={props.selected}>{props.label}</ItemLabel>
          )}
          {!!title && (
            <TitleWithLabel
              success={props.success}
              error={error}
              warning={props.warning}>
              {title}
            </TitleWithLabel>
          )}
        </ItemCol>
      </ItemTouchableWithLabel>
      {!last && (
        <Separator
          style={{
            marginLeft: 54,
            marginRight: 0,
          }}
        />
      )}
    </View>
  );
});
