import type {JwtHelper} from '../Auth/JwtHelper';
import type {JwtString} from '../Jwt';
import {Op} from '../Math';
import type {Millisecond} from '../Time';
import type {TimeState} from '../TimeState';
import type {FreshTokenState} from './FreshTokenState';

export default abstract class BaseFreshTokenStateImpl<T extends JwtString>
  implements FreshTokenState<T>
{
  protected constructor(
    protected readonly _root: {
      readonly timeState: TimeState;
      readonly jwtHelper: JwtHelper;
    },
    private readonly _interval = 1000 as Millisecond,
  ) {}

  protected abstract _getToken(): T | undefined;

  get token() {
    if (this.isFresh) {
      return this._getToken();
    }
    return undefined;
  }

  get isFresh() {
    const token = this._getToken();
    if (token === undefined) {
      return false;
    }
    return this._getIsFresh(token);
  }

  get isStale() {
    const token = this._getToken();
    if (token === undefined) {
      return false;
    }
    return !this._getIsFresh(token);
  }

  private _getIsFresh(token: T) {
    const now = this._root.timeState.getNow(this._interval);
    const compensated = Op.add(now, this._interval);
    const isExpired_ = this._root.jwtHelper.isExpired(token, compensated);
    return isExpired_.success && !isExpired_.right;
  }
}
