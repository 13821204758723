import {createStylesHook, useTheme, variance} from '@ncwallet-app/core';
import type {TouchableListItemProps} from '@ncwallet-app/ui/src';
import {TouchableListItem} from '@ncwallet-app/ui/src';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';
import {Platform, Text, View} from 'react-native';

import type {DocumentButtonBadgeVariant} from '../../../ListDocumentsScreen/DocumentButtonBadgeVariant';
import {mapBadgeVariantToColor} from '../../../ListDocumentsScreen/DocumentButtonBadgeVariant';

export type VerificationTouchableListItemProps = TouchableListItemProps & {
  label: string;
  isLabelLarge?: boolean;
  value?: string | React.ReactNode;
  large?: boolean;
  disabled?: boolean;
  isInlineValue?: boolean;
  badge?: {
    text: string;
    kind: DocumentButtonBadgeVariant;
  };
};

export default observer(function VerificationTouchableListItem(
  props: VerificationTouchableListItemProps,
) {
  const theme = useTheme();
  const {value, label, isLabelLarge, isInlineValue, badge, ...rest} = props;
  const badgeColor = mapBadgeVariantToColor(theme);
  const disableStyle = useStyleDisable();
  const styles = useMemo(
    () => ({
      touchable: {
        backgroundColor: theme.palette.uiPrimary,
        opacity: 1,
      },
      badge: {
        backgroundColor: badge && badgeColor.get(badge.kind)?.primary,
      },
    }),
    [badge, badgeColor, theme],
  );

  return (
    <VerificationTouchable
      {...rest}
      style={styles.touchable}
      disabledStyle={disableStyle.disabled}>
      <TouchableListItemBody row={isInlineValue}>
        <TouchableListItemLabel large={isLabelLarge}>
          {label}
        </TouchableListItemLabel>
        {value && (
          <TouchableListItemValue row={isInlineValue}>
            {value}
          </TouchableListItemValue>
        )}
        {badge && (
          <BadgeView
            style={styles.badge}
            testID={`${props.testID ?? 'documents'}_status`}>
            <BadgeText>{badge.text}</BadgeText>
          </BadgeView>
        )}
      </TouchableListItemBody>
    </VerificationTouchable>
  );
});

const useStyleDisable = createStylesHook(theme => ({
  disabled: {
    opacity: 1,
    backgroundColor: theme.palette.uiPrimary,
  },
}));

const VerificationTouchable = variance(TouchableListItem)(theme => ({
  root: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: theme.palette.uiPrimary,
  },
  large: {
    paddingVertical: 20,
  },
}));

const TouchableListItemBody = variance(View)(() => ({
  root: {
    flexDirection: 'column',
    flexGrow: 1,
    width: 268,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const TouchableListItemLabel = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('600'),
    fontSize: 13,
    lineHeight: 16,
    color: theme.palette.uiMain,
    overflow: 'hidden',
    ...Platform.select({
      web: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
    }),
  },
  large: {
    ...theme.fontByWeight('700'),
    fontSize: 14,
    color: theme.palette.textPrimary,
  },
}));

const TouchableListItemValue = variance(Text)(theme => ({
  root: {
    marginTop: 5,
    ...theme.fontByWeight('700'),
    fontSize: 14,
    lineHeight: 17,
    color: theme.palette.textPrimary,
    overflow: 'hidden',
    ...Platform.select({
      web: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
    }),
    maxWidth: '90%',
  },
  row: {
    marginTop: 0,
    marginLeft: 10,
  },
}));

const BadgeView = variance(View)(() => ({
  root: {
    marginLeft: 10,
    paddingHorizontal: 5,
    paddingVertical: 2,
    borderRadius: 4,
  },
}));

const BadgeText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 12,
    color: theme.palette.whitey,
  },
}));
