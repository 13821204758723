import {createStylesHook, variance} from '@ncwallet-app/core';
import type {AvailableLanguageCode} from '@ncwallet-app/core/src/Localization/constant';
import type {SafeAreaFlatListProps} from '@ncwallet-app/ui/';
import {SafeAreaFlatList, Separator} from '@ncwallet-app/ui/';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo} from 'react';
import type {FlatListProps, ListRenderItem} from 'react-native';
import type {ReadonlyDeep} from 'type-fest';

import {LANGUAGES} from './Languages';
import type {LanguageItem} from './LanguageSettingsListItem';
import LanguageSettingsListItem from './LanguageSettingsListItem';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type LanguageSettingsListProps<ItemT = any> = Partial<
  SafeAreaFlatListProps<ItemT>
> & {
  activeItem: AvailableLanguageCode;
  onLanguagePress: (locale: ItemT) => void;
};

export default observer(function LanguageSettingsList(
  props: LanguageSettingsListProps,
) {
  const {onLanguagePress, activeItem, ...rest} = props;

  const data = useMemo(
    () =>
      Object.entries(LANGUAGES).map(([key]) => {
        return {
          ...LANGUAGES[key as AvailableLanguageCode],
          locale: key as AvailableLanguageCode,
        };
      }),
    [],
  );

  const renderItem: ListRenderItem<LanguageItem> = useCallback(
    info => {
      const isLast = info.index === data.length - 1;
      const isFirst = info.index === 0;

      const active = activeItem === info.item.locale;
      return (
        <LanguageSettingsListItem
          isFirst={isFirst}
          isLast={isLast}
          item={info.item}
          onPress={() => {
            onLanguagePress(info.item.locale);
          }}
          selected={active}
        />
      );
    },
    [onLanguagePress, activeItem, data.length],
  );

  const style = useStyle();

  return (
    <SafeAreaFlatList
      data={data}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      ItemSeparatorComponent={SeparatorStyled}
      contentContainerStyle={style.root}
      {...rest}
    />
  );
});

const useStyle = createStylesHook(theme => ({
  root: {
    backgroundColor: theme.palette.uiPrimary,
    padding: 16,
  },
}));

const SeparatorStyled = variance(Separator)(() => ({
  root: {
    marginLeft: 54,
    marginRight: 0,
  },
}));

export type ListProps = FlatListProps<ReadonlyDeep<LanguageItem>>;

const keyExtractor: ListProps['keyExtractor'] = entry => entry.locale;
