import {variance} from '@ncwallet-app/core';
import {View} from 'react-native';

export default variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    position: 'absolute',
    bottom: -10,
    right: 0,
    width: '100%',
    height: 10,
    backgroundColor: theme.palette.uiPrimary,
  },
}));
