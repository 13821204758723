import type {CurrencyCode, DecimalString} from '@ncwallet-app/core';
import {useStrings, useTheme, variance} from '@ncwallet-app/core';
import {
  DecimalInput,
  IconPosition,
  InputVariant,
  TouchableOpacity,
} from '@ncwallet-app/ui';
import {ArrowDownWideSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import type {TextInput, TextInputProps} from 'react-native';
import {Text, View} from 'react-native';

import AnimatedTextError from './AnimatedTextError';

type BuyAmountInputProps = TextInputProps & {
  amount: DecimalString | undefined;
  fractionalDigits: number | undefined;
  onChangeAmount: (value: DecimalString | undefined) => void;
  currency: CurrencyCode | undefined;
  showKeyboard?: boolean;
  onFocus?: () => void;
  inputRef?: React.Ref<TextInput>;
  wideStyle?: boolean;
  errorText?: string;
  onCurrencyPress: () => void;
};

export default observer(function BuyAmountInput(props: BuyAmountInputProps) {
  const {
    amount,
    onFocus,
    showKeyboard,
    onChangeAmount,
    currency,
    fractionalDigits,
    wideStyle,
    errorText,
    inputRef,
    onCurrencyPress,
    ...rest
  } = props;
  const strings = useStrings();
  const theme = useTheme();

  const renderActionView = useCallback(() => {
    return (
      <AmountActionView error={Boolean(errorText)}>
        <AmountActionBtn onPress={onCurrencyPress}>
          {currency && <AmountCryptoCode>{currency}</AmountCryptoCode>}
          <ArrowDownWideImg />
        </AmountActionBtn>
      </AmountActionView>
    );
  }, [currency, errorText, onCurrencyPress]);

  const handleErrorPress = useCallback(
    (text: string) => {
      if (text) {
        onChangeAmount(text);
      }
    },
    [onChangeAmount],
  );

  return (
    <Root wideStyle={wideStyle}>
      <DecimalInput
        ref={inputRef}
        fractionDigits={fractionalDigits ?? 8}
        value={amount}
        label={strings['receiveCryptoScreen.fiat.amount']}
        placeholder={strings['sendCrypto.paymentForm.placeholder']}
        placeholderTextColor={theme.palette.textAdditional3}
        iconPosition={IconPosition.Right}
        onFocus={onFocus}
        showSoftInputOnFocus={showKeyboard}
        onChangeDecimal={onChangeAmount}
        Slot={renderActionView}
        variant={errorText ? InputVariant.Error : InputVariant.Default}
        {...rest}
      />
      <AnimatedTextError errorText={errorText} onPress={handleErrorPress} />
    </Root>
  );
});

const AmountActionView = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    backgroundColor: theme.palette.uiPrimary,
    borderLeftWidth: 1,
    borderColor: theme.palette.uiSecondary,
    alignItems: 'stretch',
  },
  error: {
    borderColor: theme.palette.error,
  },
}));

const AmountActionBtn = variance(TouchableOpacity)(() => ({
  root: {
    flex: 1,
    paddingHorizontal: 16,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
  },
}));

const AmountCryptoCode = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    fontSize: 14,
    textTransform: 'uppercase',
  },
}));

const Root = variance(View)(() => ({
  root: {
    paddingVertical: 10,
    paddingHorizontal: 15,
  },
  wideStyle: {
    paddingVertical: 0,
    paddingHorizontal: 0,
  },
}));

const ArrowDownWideImg = variance(ArrowDownWideSvg)(
  () => ({
    root: {},
  }),
  theme => ({
    width: 12,
    height: 12,
    color: theme.palette.textAdditional2,
  }),
);
