import {useRoot, variance} from '@ncwallet-app/core';
import {
  Button,
  ButtonColor,
  ButtonVariant,
  Input,
  InputType,
} from '@ncwallet-app/ui';
import {setStringAsync} from 'expo-clipboard';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useState} from 'react';
import {Platform, StyleSheet, Text, View} from 'react-native';
import {ScrollView} from 'react-native-gesture-handler';

import {useTabBarLayout} from '../../components';
import {ButtonErrorInComponent} from './ButtonErrorInComponent';

export type DebugScreenProps = {
  goToLogs: () => void;
  goToSignOutReasonLogs: () => void;
  goToEnvironmentList: () => void;
  goToEnvironmentForm: () => void;
  getBiometricsEnabled: () => boolean;
  toggleBiometrics: () => void;
  onRefreshTokenSet: (pin: string, token: string, deviceId: string) => void;
  onNativeCrashPress: () => void;
  onSignOut: () => void;
  onShowDemoMessage: () => void;
  copyLog: () => void;
  shareLog: () => void;
  saveLog: () => void;
  clearLocalStorage: () => void;
  clearSessionStorage: () => void;
  clearAsyncStorage: () => Promise<void>;
  clearSecureStorage: () => Promise<void>;
  showAsyncStorage: () => Promise<Record<string, string | null>>;
};

export default observer(function DebugScreen(props: DebugScreenProps) {
  const {
    goToLogs,
    goToSignOutReasonLogs,
    goToEnvironmentList,
    goToEnvironmentForm,
    onRefreshTokenSet,
    onNativeCrashPress,
    onShowDemoMessage,
    copyLog,
    shareLog,
    saveLog,
    clearLocalStorage,
    clearAsyncStorage,
    clearSessionStorage,
    clearSecureStorage,
    showAsyncStorage,
  } = props;
  const root = useRoot();
  const [installReferrer, setInstallReferrer] = useState('');
  const {authHelper, debug, installReferrerIdentification, alert} = root;
  const signOut = useCallback(
    () => authHelper.signOut({reason: 'DebugScreen'}),
    [authHelper],
  );
  const layout = useTabBarLayout();

  const [pin, setPin] = useState('');
  const [refreshToken, setRefreshToken] = useState('');
  const [deviceId, setDeviceId] = useState('');

  const onRefreshTokenSetPress = useCallback(() => {
    onRefreshTokenSet(pin, refreshToken, deviceId);
  }, [onRefreshTokenSet, pin, refreshToken, deviceId]);

  const raiseInternalError = useCallback(
    () => root.ncWalletJsonRpcClient.call('error', undefined),
    [root],
  );

  const [localPin, setLocalPin] = useState('');

  useEffect(() => {
    try {
      void installReferrerIdentification.getInstallReferrer().then(ref => {
        if (ref) {
          setInstallReferrer(ref);
        }
      });
    } catch {
      /* empty */
    }
  }, [installReferrerIdentification]);

  const copyInstallReferrer = useCallback(async () => {
    void setStringAsync(installReferrer);
    alert.alert(
      'Success',
      'The value has been copied to clipboard',
      [{style: 'default', text: 'OK'}],
      {cancelable: true},
    );
  }, [alert, installReferrer]);

  const [storage, setStorage] = useState<Record<string, string | null> | null>(
    null,
  );
  const handleAsyncStorage = async () => {
    const _storage = await showAsyncStorage();
    setStorage(_storage);
  };

  return (
    <Root
      contentContainerStyle={[
        styles.container,
        {paddingBottom: layout.height},
      ]}>
      <Section>
        <Button
          title={debug.logEnabled ? 'Disable logs' : 'Enable logs'}
          onPress={debug.toggleLog}
          color={ButtonColor.Secondary}
          variant={ButtonVariant.Text}
        />
        <Button title="COPY LOG" onPress={copyLog} />
        <Button
          title={`Install referrer: ${installReferrer ? installReferrer : '-'}`}
          onPress={copyInstallReferrer}
        />
        <Button title="SHARE LOG" onPress={shareLog} />
        <Button title="SAVE LOG" onPress={saveLog} />
        <Button
          title="Logs"
          onPress={goToLogs}
          color={ButtonColor.Secondary}
          variant={ButtonVariant.Text}
        />
        <Button
          title="Logs for sign out bug"
          onPress={goToSignOutReasonLogs}
          color={ButtonColor.Secondary}
          variant={ButtonVariant.Text}
        />

        <Button title="Create env" onPress={goToEnvironmentForm} />
        <Button title="Switch env" onPress={goToEnvironmentList} />
      </Section>

      <BiometricsSection {...props} />

      <Section>
        <Input
          value={pin}
          onChangeText={setPin}
          placeholder="pin"
          type={InputType.Numeric}
        />

        <Input
          value={refreshToken}
          onChangeText={setRefreshToken}
          placeholder="refresh token"
        />
        <Input
          value={deviceId}
          onChangeText={setDeviceId}
          placeholder="device id"
        />

        <Button
          title="Set credentials"
          onPress={onRefreshTokenSetPress}
          color={ButtonColor.Error}
        />
        <Button title="Show flash message" onPress={onShowDemoMessage} />
      </Section>

      <Section>
        <Input
          value={localPin}
          onChangeText={setLocalPin}
          placeholder="Local Pin"
          type={InputType.Numeric}
        />
      </Section>

      <Section>
        <Button
          title="Sign out"
          onPress={signOut}
          color={ButtonColor.Error}
          variant={ButtonVariant.Text}
        />
      </Section>
      <Section>
        <Button
          title="NATIVE CRASH"
          onPress={onNativeCrashPress}
          variant={ButtonVariant.Danger}
        />
        <Button
          title="Raise Internal error"
          onPress={raiseInternalError}
          variant={ButtonVariant.Danger}
        />
        <ButtonErrorInComponent />
      </Section>
      <Section>
        <Button title="Show AsyncStorage" onPress={handleAsyncStorage} />
        {storage && <PrettyJsonDisplay data={storage} />}
      </Section>
      <Section>
        <Button
          title="Disable debug"
          onPress={debug.disableDebug}
          variant={ButtonVariant.Danger}
        />
        {Platform.OS === 'web' ? (
          <>
            <Button
              title="Clear localStorage"
              onPress={clearLocalStorage}
              variant={ButtonVariant.Danger}
            />
            <Button
              title="Clear sessionStorage"
              onPress={clearSessionStorage}
              variant={ButtonVariant.Danger}
            />
          </>
        ) : (
          <>
            <Button
              title="Clear AsyncStorage"
              onPress={clearAsyncStorage}
              variant={ButtonVariant.Danger}
            />
            <Button
              title="Clear SecureStorage"
              onPress={clearSecureStorage}
              variant={ButtonVariant.Danger}
            />
          </>
        )}
      </Section>
      <Section>
        <Button
          title={'Disconnect WS'}
          onPress={async () => {
            await root.connection.disconnect();
          }}
        />
      </Section>
    </Root>
  );
});

type PrettyJsonDisplayProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Record<string, any>;
};

const PrettyJsonDisplay = ({data}: PrettyJsonDisplayProps) => {
  const renderJson = (obj: object, level = 0) => {
    return Object.entries(obj).map(([key, value]) => {
      return (
        <View key={key} style={{marginLeft: level * 10}}>
          <Text style={styles.key}>{key}:</Text>
          {typeof value === 'object' ? (
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            renderJson(value, level + 1)
          ) : (
            <Text style={styles.value}>{String(value)}</Text>
          )}
        </View>
      );
    });
  };

  return <ScrollView style={styles.json}>{renderJson(data)}</ScrollView>;
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    padding: 20,
  },
  json: {
    padding: 10,
  },
  key: {
    fontWeight: 'bold',
  },
  value: {
    marginLeft: 5,
  },
});

const Root = variance(ScrollView)(theme => ({
  root: {
    flex: 1,
    backgroundColor: theme.palette.background,
  },
}));

const Section = variance(View)(theme => ({
  root: {
    paddingVertical: 15,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.foreground,
    backgroundColor: theme.palette.background,
    gap: 10,
  },
}));

type BiometricsSectionProps = {
  getBiometricsEnabled: () => boolean;
  toggleBiometrics: () => void;
};

const BiometricsSection = observer((props: BiometricsSectionProps) => {
  const {getBiometricsEnabled, toggleBiometrics} = props;
  const enabled = getBiometricsEnabled();
  return (
    <Section>
      <Button
        title={enabled ? 'Disable biometrics' : 'Enable biometrics'}
        onPress={toggleBiometrics}
        color={enabled ? ButtonColor.Error : ButtonColor.Success}
      />
    </Section>
  );
});
