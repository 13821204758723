/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {useStrings, variance} from '@ncwallet-app/core';
import type {TotpActionOption} from '@ncwallet-app/core/src/NCWalletServer/AccountsTotpActions';
import {TwoFaProviderKind} from '@ncwallet-app/core/src/TwoFaHelper';
import type {BaseSafeAreaProps} from '@ncwallet-app/ui';
import {
  Button,
  ButtonColor,
  ButtonVariant,
  LG_BREAKPOINT,
  Pressable,
  SafeAreaScrollView,
} from '@ncwallet-app/ui';
import {ArrowDownWideSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

import {useIsExchangeAllowed} from '../../../Navigation/hooks/useIsExchangeAllowed';
import {mapTwoProviderKindToTitle} from '../SecurityTwoFaAuthScreen/TwoFaProviderListItem';
import {TotpActionSwitch} from './TotpActionSwitch';

export type TwoFaSettingsScreenProps = BaseSafeAreaProps & {
  isCardStyle?: boolean;
  actions: TotpActionOption[] | undefined;
  isEdited: boolean;
  onActionPress: (a: TotpActionOption) => void;
  onDisableTwoFaPress: () => void;
  currentTwoFaProvider?: TwoFaProviderKind;
  onSubmitPress: () => void;
  onTwoFaKindSelect: () => void;
  offsetBottom?: boolean;
};

export default observer(function TwoFaSettingsScreen(
  props: TwoFaSettingsScreenProps,
) {
  const {
    isEdited,
    actions,
    onDisableTwoFaPress,
    onActionPress,
    onSubmitPress,
    onTwoFaKindSelect,
    isCardStyle,
    currentTwoFaProvider,
    ...rest
  } = props;
  const strings = useStrings();
  const {isExchangeAllowed} = useIsExchangeAllowed();

  let actionsWithoutExchange = actions;

  if (!isExchangeAllowed) {
    actionsWithoutExchange = actions?.filter(
      action => action.type !== 'exchange',
    );
  }

  return (
    <ScrollRoot {...rest}>
      <TopView>
        <TopTitle>{strings['secureTwoFactorAuthScreen.final.text']}</TopTitle>
      </TopView>
      <TwoFaTypeView>
        <TwoFaTypeTitle>{strings['twoFaSelectScreen.title']}</TwoFaTypeTitle>
        <TwoFaSelectPressable
          onPress={onTwoFaKindSelect}
          testID="provider-2fa-list">
          <TwoFaSelectPressableBodyView>
            <TwoFaSelectPressableBodyText>
              {mapTwoProviderKindToTitle(
                currentTwoFaProvider ?? TwoFaProviderKind.Ga,
                strings,
              )}
            </TwoFaSelectPressableBodyText>
            <ArrowDownIcon />
          </TwoFaSelectPressableBodyView>
        </TwoFaSelectPressable>
      </TwoFaTypeView>
      <Container switch>
        {actionsWithoutExchange?.map((action, index) => (
          <TotpActionSwitch
            key={action.type}
            action={action}
            onPress={() => {
              onActionPress(action);
            }}
            last={index === actions!.length - 1}
          />
        ))}
      </Container>
      <Container last cardStyle={isCardStyle}>
        <ConfirmTitle cardStyle={isCardStyle}>
          {strings['secureTwoFactorAuthScreen.confirmTitle']}
        </ConfirmTitle>
      </Container>

      <ButtonsContainer offsetBottom={props.offsetBottom}>
        {isEdited && (
          <>
            <ConfirmButton
              variant={ButtonVariant.Primary}
              color={ButtonColor.Primary}
              title={strings['secureTwoFactorAuthScreen.submit']}
              onPress={onSubmitPress}
            />
            <SizedBox />
          </>
        )}

        <ConfirmButton
          variant={ButtonVariant.Danger}
          color={ButtonColor.Error}
          title={strings['secureTwoFactorAuthScreen.disable2FAButton']}
          onPress={onDisableTwoFaPress}
          testID="disable-2fa-btn"
        />
      </ButtonsContainer>
    </ScrollRoot>
  );
});

const ScrollRoot = variance(SafeAreaScrollView)(() => ({
  root: {
    flex: 1,
  },
}));

const TopView = variance(View)(() => ({
  root: {
    gap: 10,
    padding: 15,
    paddingBottom: 5,
  },
}));

const TopTitle = variance(Text)(theme => ({
  root: {
    color: theme.palette.textMain,
    ...theme.fontByWeight('700'),
    fontSize: 16,
    lineHeight: 24,
  },
}));

const TwoFaTypeView = variance(View)(() => ({
  root: {
    gap: 10,
    padding: 15,
  },
}));

const TwoFaTypeTitle = variance(Text)(theme => ({
  root: {
    color: theme.palette.textMain,
    ...theme.fontByWeight('700'),
    fontSize: 16,
  },
}));

const TwoFaSelectPressable = variance(Pressable)(
  () => ({
    root: {
      padding: 15,
    },
  }),
  theme => ({
    borderStyle: {
      borderRadius: 6,
      borderWidth: 1,
      borderColor: theme.palette.uiSecondary,
    },
  }),
);

const TwoFaSelectPressableBodyView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const TwoFaSelectPressableBodyText = variance(Text)(theme => ({
  root: {
    color: theme.palette.textMain,
    ...theme.fontByWeight('700'),
    fontSize: 16,
  },
}));

const ArrowDownIcon = variance(ArrowDownWideSvg)(
  () => ({
    root: {},
  }),
  theme => ({
    color: theme.palette.uiAdditional1,
  }),
);

const Container = variance(View)(theme => ({
  root: {
    paddingHorizontal: 15,
    paddingBottom: 20,
  },
  last: {
    marginTop: 'auto',
    paddingBottom: 0,
  },

  switch: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        backgroundColor: theme.palette.uiPrimary,
        borderWidth: 1,
        borderRadius: 8,
        borderColor: theme.palette.uiSecondary,
        paddingBottom: 0,
        paddingVertical: 0,
        paddingHorizontal: 0,
        marginHorizontal: 20,
        marginTop: 20,
      },
    }),
  },

  cardStyle: {
    paddingHorizontal: 0,
    paddingBottom: 0,
    paddingTop: 0,
  },
}));

const ConfirmTitle = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    color: theme.palette.textAdditional2,
    fontSize: 14,
    lineHeight: 20,
    textAlign: 'center',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        textAlign: 'left',
      },
    }),
  },

  cardStyle: {
    paddingTop: 20,
    paddingHorizontal: 20,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.uiSecondary,
    lineHeight: 19,
  },
}));

const ButtonsContainer = variance(View)(() => ({
  root: {
    marginTop: 25,
    marginHorizontal: 15,
  },
  cardStyle: {
    marginHorizontal: 20,
    marginBottom: 20,
  },
  offsetBottom: {
    marginBottom: 15,
  },
}));

const ConfirmButton = variance(Button)(theme => ({
  root: {
    borderColor: theme.palette.uiSecondary,
    marginTop: 0,
  },
}));

const SizedBox = variance(View)(() => ({
  root: {
    height: 10,
  },
}));
