import {useRoot, useStrings} from '@ncwallet-app/core';
import {TransactionReportSvg} from '@ncwallet-app/ui/src/assets/svg/colored';
import {DownloadSvg, ShareSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo} from 'react';
import {Platform} from 'react-native';

import {
  ShowTransactionReportBottomSheetScreen,
  ShowTransactionReportModalScreen,
} from '../../screens/ShowTransactionReportScreen';
import type {TransactionReportAction} from '../../screens/ShowTransactionReportScreen/ShowTransactionReportProps';

export type ShowTransactionReportContainerProps = {
  onClose: () => void;
};

export default observer(function ShowTransactionReportContainer(
  props: ShowTransactionReportContainerProps,
) {
  const {onClose} = props;
  const {transactionReport, layoutHelperState} = useRoot();

  const strings = useStrings();
  const isLarge = layoutHelperState.isLarge;

  const handleDownload = useCallback(async () => {
    await transactionReport.downloadReport();
    onClose();
  }, [onClose, transactionReport]);

  const handleShare = useCallback(async () => {
    await transactionReport.shareReport();
    onClose();
  }, [transactionReport, onClose]);

  const actions: TransactionReportAction[] = useMemo(() => {
    switch (Platform.OS) {
      case 'ios':
        return [
          {
            title: strings['walletStatement.ShareDownloadButton'],
            Icon: ShareSvg,
            onPress: handleShare,
          },
        ];
      case 'android':
        return [
          {
            title: strings['walletStatement.shareButton'],
            Icon: ShareSvg,
            onPress: handleShare,
          },
          {
            title: strings['walletStatement.downloadButton'],
            Icon: DownloadSvg,
            onPress: handleDownload,
          },
        ];
      default:
        return [
          {
            title: strings['walletStatement.downloadButton'],
            Icon: DownloadSvg,
            onPress: handleDownload,
          },
        ];
    }
  }, [handleDownload, handleShare, strings]);

  const description =
    Platform.OS === 'web'
      ? strings['walletStatement.download.description']
      : strings['walletStatement.description'];

  return !isLarge ? (
    <ShowTransactionReportBottomSheetScreen
      testID="download_statement_btn"
      Icon={TransactionReportSvg}
      title={strings['walletStatement.title']}
      description={description}
      onClose={onClose}
      isLoading={transactionReport.isLoading}
      actions={actions}
    />
  ) : (
    <ShowTransactionReportModalScreen
      testID="download_statement_btn"
      Icon={TransactionReportSvg}
      title={strings['walletStatement.title']}
      description={description}
      onClose={onClose}
      isLoading={transactionReport.isLoading}
      actions={actions}
    />
  );
});
