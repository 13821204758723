import {useStrings, variance} from '@ncwallet-app/core';
import type {LocaleKeys} from '@ncwallet-app/localization/locale/LocaleStrings';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text} from 'react-native';

import {TouchableOpacity} from '../../atoms';

export type TabProps<T> = {
  label: T;
  active: boolean;
  nextActiveTab: boolean;
  onPress: () => void;
  isLast: boolean;
  testID?: string;
};

export const Tab = observer(<T,>(props: TabProps<T>) => {
  const strings = useStrings();
  return (
    <Root
      {...props}
      activeOpacity={0.7}
      onPress={props.onPress}
      active={props.active}
      nextActiveTab={props.nextActiveTab}
      isLast={props.isLast}
      testID={props.testID}>
      <TabLabel active={props.active}>
        {strings[props.label as LocaleKeys]}
      </TabLabel>
    </Root>
  );
});

const Root = variance(TouchableOpacity)(
  theme => ({
    root: {
      height: 28,
      justifyContent: 'center',
      alignItems: 'center',
      borderRightWidth: 1,
      borderRightColor: theme.palette.uiSecondary,
      padding: 4,
    },
    active: {
      justifyContent: 'center',
      borderRadius: 6,
      borderRightWidth: 0,
      backgroundColor: theme.palette.primary,
    },
    isLast: {
      borderRightColor: 'transparent',
    },
    nextActiveTab: {
      borderRightColor: 'transparent',
    },
    container: {
      flex: 1,
    },
  }),
  (theme, styles) => ({
    containerStyle: styles.container,
  }),
);

const TabLabel = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    color: theme.palette.textAdditional3,
    fontSize: 13,
    textAlign: 'center',
  },
  active: {
    ...theme.fontByWeight('700'),
    color: '#fff',
  },
}));
