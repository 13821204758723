import {observer} from 'mobx-react-lite';
import React, {useEffect} from 'react';
import {StyleSheet} from 'react-native';
import {PanGestureHandler} from 'react-native-gesture-handler';
import type {SharedValue} from 'react-native-reanimated';
import Animated, {
  // eslint-disable-next-line import-x/no-deprecated
  useAnimatedGestureHandler,
  // eslint-disable-next-line import-x/no-deprecated
  useWorkletCallback,
} from 'react-native-reanimated';
import type {Path, Vector} from 'react-native-redash';
import {clamp, getYForX} from 'react-native-redash';

export type GestureHandlerProps = {
  showInfo: SharedValue<boolean>;
  cursorTranslation: Vector<SharedValue<number>>;
  currentPath: Path;
  width: number;
};

export default observer(function GestureHandler(props: GestureHandlerProps) {
  const {showInfo, cursorTranslation, currentPath, width} = props;

  useEffect(() => {
    const startX = cursorTranslation.x.value || clamp(width - 1, 0, width);
    cursorTranslation.x.value = startX;
    cursorTranslation.y.value = getYForX(currentPath, startX) || 0;
  }, [width, cursorTranslation, currentPath]);

  // eslint-disable-next-line @typescript-eslint/no-deprecated,import-x/no-deprecated
  const processEvent = useWorkletCallback(
    (event: {readonly x: number}) => {
      showInfo.value = true;
      const x = clamp(event.x, 0, width);
      cursorTranslation.x.value = x;
      cursorTranslation.y.value = getYForX(currentPath, x) || 0;
    },
    [currentPath, cursorTranslation, showInfo, width],
  );

  // eslint-disable-next-line @typescript-eslint/no-deprecated,import-x/no-deprecated
  const onGestureEvent = useAnimatedGestureHandler(
    {
      onStart: event => {
        processEvent(event);
      },
      onActive: event => {
        processEvent(event);
      },
    },
    [processEvent],
  );

  return (
    <PanGestureHandler {...{onGestureEvent}}>
      <Animated.View style={StyleSheet.absoluteFill} />
    </PanGestureHandler>
  );
});
