import type {OptionalGetter} from '@ncwallet-app/core/src/mobx-toolbox';
import {use} from '@ncwallet-app/core/src/mobx-toolbox';
import {observer} from 'mobx-react-lite';
import React from 'react';
import type {SvgProps} from 'react-native-svg';

import ProfileSettingsItem from '../ProfileSettingsItem';

export type ProfileTelegramButtonProps = {
  $disabled?: OptionalGetter<boolean | undefined>;
  getTitle: () => string | undefined;
  onPress: () => void;
  testID?: string;
  Icon?: React.ComponentType<SvgProps>;
};

const ProfileTelegramButton = observer((props: ProfileTelegramButtonProps) => {
  const {$disabled, getTitle, onPress, Icon} = props;
  const disabled = use($disabled);
  return (
    <ProfileSettingsItem
      Icon={Icon}
      testID={props.testID}
      disabled={disabled}
      label="Telegram"
      getTitle={getTitle}
      onPress={onPress}
    />
  );
});

export default ProfileTelegramButton;
