import {useStrings, variance} from '@ncwallet-app/core';
import type {SafeAreaScrollViewProps} from '@ncwallet-app/ui';
import {SafeAreaScrollView} from '@ncwallet-app/ui';
import {
  AuthLockSvg,
  DevicesSvg,
  FileErrorSvg,
  FileTimeSvg,
  FileUploadSvg,
  FingerprintSvg,
  PinCodeSvg,
} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';

import {SettingsGroupTitle} from '../UserSettingsScreens/SharedComponents/SettingsGroupTitle';
import SecuritySettingsItem from './SecuritySettingsItem';

export type SecuritySettingsListProps = SafeAreaScrollViewProps & {
  onSessionHistoryPress: () => void;
  onActiveSessionsPress: () => void;
  onAllowedIpPress: () => void;
  onBlockedIpPress: () => void;
  onTwoFaSettingsPress: () => void;
  onPinPress: () => void;
  onBiometricPress: () => void;
};

export default observer(function SecuritySettingsList(
  props: SecuritySettingsListProps,
) {
  const {
    onSessionHistoryPress,
    onActiveSessionsPress,
    onAllowedIpPress,
    onBlockedIpPress,
    onTwoFaSettingsPress,
    onPinPress,
    onBiometricPress,
    ...rest
  } = props;
  const strings = useStrings();

  return (
    <SafeAreaScrollViewStyled {...rest}>
      <SettingsGroupTitle
        title={strings['secureSettingsScreen.protectionTitle']}
        first
      />
      <Group>
        <SecuritySettingsItem
          Icon={PinCodeSvg}
          title={strings['secureSettingsScreen.pinCode']}
          onPress={onPinPress}
          testID="mobile-secure-pin"
        />
        <SecuritySettingsItem
          Icon={FingerprintSvg}
          title={strings['secureSettingsScreen.biometric']}
          onPress={onBiometricPress}
          testID="mobile-secure-bio"
          last
        />
      </Group>
      <SettingsGroupTitle
        title={strings['secureSettingsScreen.twoFactorAuth']}
      />
      <Group>
        <SecuritySettingsItem
          Icon={AuthLockSvg}
          title={strings['secureSettingsScreen.twoFactorAuth']}
          onPress={onTwoFaSettingsPress}
          testID="mobile-secure-2fa"
          last
        />
      </Group>
      <SettingsGroupTitle
        title={strings['secureSettingsScreen.sessionsTitle']}
      />
      <Group>
        <SecuritySettingsItem
          Icon={FileUploadSvg}
          title={strings['secureSettingsScreen.whiteListIp']}
          onPress={onAllowedIpPress}
          testID="allow-ip-btn"
        />
        <SecuritySettingsItem
          Icon={FileErrorSvg}
          title={strings['secureSettingsScreen.blackListIp']}
          onPress={onBlockedIpPress}
          testID="block-ip-btn"
        />
        <SecuritySettingsItem
          Icon={FileTimeSvg}
          title={strings['secureSettingsScreen.history']}
          onPress={onSessionHistoryPress}
          testID="session-history-btn"
        />
        <SecuritySettingsItem
          Icon={DevicesSvg}
          title={strings['lgSecureSettingsScreen.sessionsActive']}
          onPress={onActiveSessionsPress}
          testID="active-sessions-btn"
          last
        />
      </Group>
    </SafeAreaScrollViewStyled>
  );
});

const SafeAreaScrollViewStyled = variance(SafeAreaScrollView)(theme => ({
  root: {
    backgroundColor: theme.palette.uiPrimary,
    padding: 16,
  },
}));

const Group = variance(View)(theme => ({
  root: {
    backgroundColor: theme.palette.uiAdditional2,
    borderRadius: 16,
    overflow: 'hidden',
  },
}));
