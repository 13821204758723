import {variance} from '@ncwallet-app/core';
import {ModalContainer, TouchableOpacity} from '@ncwallet-app/ui';
import {CrossSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import type {PropsWithChildren} from 'react';
import React from 'react';
import {Text, View} from 'react-native';

type ShowQrModalScreenProps = PropsWithChildren & {
  title: string;
  onClose: () => void;
};

export const ShowQrModalScreen = (props: ShowQrModalScreenProps) => {
  const {children, title, onClose} = props;
  return (
    <ModalContainer>
      <ModalBody>
        <ModalClose onPress={onClose} testID="close">
          <CloseIcon />
        </ModalClose>
        <Title>{title}</Title>
        {children}
      </ModalBody>
    </ModalContainer>
  );
};

export const ModalBody = variance(View)(theme => ({
  root: {
    borderRadius: 6,
    backgroundColor: theme.palette.background,
    minWidth: 620,
    padding: 40,
    paddingBottom: 0,
    ...theme.bar(11),
  },
}));

export const ModalClose = variance(TouchableOpacity)(
  theme => ({
    root: {},
    container: {
      position: 'absolute',
      top: 15,
      right: 15,
      zIndex: 1,
      borderRadius: 50,
      width: 32,
      height: 32,
      backgroundColor: theme.palette.uiSecondary,
      color: theme.palette.textPrimary,
    },
  }),
  (_, styles) => ({
    containerStyle: styles.container,
  }),
);

export const CloseIcon = variance(CrossSvg)(theme => ({
  root: {
    padding: 5,
    color: theme.palette.whitey,
  },
}));

export const Title = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    textAlign: 'center',
    marginBottom: 5,
    fontSize: 18,
    color: theme.palette.textPrimary,
  },
}));
