import {useRoot, variance} from '@ncwallet-app/core';
import {LG_BREAKPOINT, TouchableOpacity} from '@ncwallet-app/ui';
import {round} from 'lodash';
import {observer} from 'mobx-react-lite';
import {expr} from 'mobx-utils';
import React, {useMemo} from 'react';
import {Text, View} from 'react-native';

import {PinMask} from './PinMask';

export type PinMaskProps = {
  title: string;
  getFilledLength: () => number;
  fullPinLength: number;
  hasError?: boolean;
  errorText?: string;
  errorAction?: string;
  onErrorActionPress?: () => void;
  smallText?: boolean;
};

export const PinForm = observer((props: PinMaskProps) => {
  const {windowDimensionsState} = useRoot();

  const marginTop = expr(() => {
    const {width} = windowDimensionsState.window;
    if (width >= LG_BREAKPOINT) {
      return 0;
    }
    const {height} = windowDimensionsState.window;
    const aspectRatio = round(height / width, 2);
    return aspectRatio < 2 ? 20 : 40;
  });

  const style = useMemo(() => ({marginTop}), [marginTop]);

  return (
    <PinMaskContainer style={style}>
      <PinModeText small={props.smallText} testID="pin_change_status">
        {props.title}
      </PinModeText>
      <PinMask
        getFilledLength={props.getFilledLength}
        fullPinLength={props.fullPinLength}
        smallDots={props.smallText}
      />
      {props.hasError && (!!props.errorText || !!props.errorAction) ? (
        <PinErrorContainer>
          {!!props.errorText && (
            <PinErrorText testID="pin_error_text">
              {props.errorText}
            </PinErrorText>
          )}
          {!!props.errorAction && (
            <PinErrorTouchable onPress={props.onErrorActionPress}>
              <PinErrorTouchableText testID="pin_forgot_code">
                {props.errorAction}
              </PinErrorTouchableText>
            </PinErrorTouchable>
          )}
        </PinErrorContainer>
      ) : (
        <ErrorPlaceholder />
      )}
    </PinMaskContainer>
  );
});

const PinMaskContainer = variance(View)(theme => ({
  root: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flex: 1,
        justifyContent: 'flex-start',
      },
    }),
  },
}));

const PinErrorContainer = variance(View)(() => ({
  root: {
    minHeight: 44,
    maxWidth: '75%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

const ErrorPlaceholder = variance(View)(() => ({
  root: {
    height: 44,
  },
}));

const PinErrorText = variance(Text)(theme => ({
  root: {
    textAlign: 'center',
    marginBottom: 10,
    ...theme.fontByWeight('600'),
    color: theme.palette.error,
    fontSize: 14,
  },
}));

const PinErrorTouchable = variance(TouchableOpacity)(() => ({
  root: {},
}));

const PinErrorTouchableText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('600'),
    color: theme.palette.info,
    textAlign: 'center',
  },
}));

const PinModeText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('bold'),
    color: theme.palette.textMain,
    textAlign: 'center',
    fontSize: 16,

    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        fontSize: 24,
        lineHeight: 36,
      },
    }),
  },
  small: {
    fontSize: 16,
  },
}));
