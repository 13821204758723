import {useRoot} from '@ncwallet-app/core';
import {
  PROMPT_LANGUAGE_ROUTE,
  PROMPT_THEME_ROUTE,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import type {SettingsRouteName} from '@ncwallet-app/core/src/LargeNavigationScheme/LargeHomeStack/LargeSwitch/LargeSwitchParamList';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo} from 'react';

import useScreenHeaderWideProps from '../hooks/useScreenHeaderWideProps';
import type {LargeSwitchBindingProps} from '../LargeSwitchBindingProps';
import HeaderModals from './HeaderFrame/HeaderModals';
import SettingsHeader from './SettingsHeader';
import SettingsLayout from './SettingsLayout';

export type SettingsLayoutContainerProps = {
  isReady: boolean;
  children: React.ReactNode;
} & LargeSwitchBindingProps<SettingsRouteName>;

export default observer(function SettingsLayoutContainer(
  props: SettingsLayoutContainerProps,
) {
  const {isReady, children, route, navigation} = props;
  const {authHelper} = useRoot();
  const headerProps = useScreenHeaderWideProps(props);
  const {focusedMenu, toggleMenu} = headerProps;
  const isThemeMenuVisible = focusedMenu === PROMPT_THEME_ROUTE;
  const isLanguageMenuVisible = focusedMenu === PROMPT_LANGUAGE_ROUTE;
  const onThemePress = useCallback(() => {
    toggleMenu(PROMPT_THEME_ROUTE);
  }, [toggleMenu]);
  const onLanguagePress = useCallback(() => {
    toggleMenu(PROMPT_LANGUAGE_ROUTE);
  }, [toggleMenu]);
  const onClose = useCallback(() => {
    toggleMenu();
  }, [toggleMenu]);
  const signOut = useCallback(() => {
    void authHelper.signOut({reason: 'PromptSignOutBinding'});
    navigation.reset({index: 0, routes: []});
  }, [authHelper, navigation]);

  const areLimitsFocused = route.name === 'ListLimits';
  const areNotificationsFocused = route.name === 'ListNotificationSettings';
  const isSecurityFocused = route.name === 'ListSecuritySettings';
  const isProfileFocused = route.name === 'ShowProfile';
  const isAffiliateFocused = route.name === 'PromptAffiliateProgram';

  const Modals = useMemo(
    () => isReady && <HeaderModals hideProfileMenu {...headerProps} />,
    [headerProps, isReady],
  );

  return (
    <SettingsLayout
      Header={isReady && <SettingsHeader {...headerProps} />}
      Modals={Modals}
      onNotificationSettings={headerProps.onNotificationSettings}
      onProfileSettings={headerProps.onProfileSettings}
      onHelpPress={headerProps.onHelpPress}
      onSecurityPress={headerProps.onSecurityPress}
      onLimitsPress={headerProps.onLimitsPress}
      isThemeMenuVisible={isThemeMenuVisible}
      isLanguageMenuVisible={isLanguageMenuVisible}
      onThemeMenuPress={onThemePress}
      onLanguageMenuPress={onLanguagePress}
      onSignOut={signOut}
      onMenuClose={onClose}
      onAffiliatePress={headerProps.onAffiliatePress}
      isAffiliateFocused={isAffiliateFocused}
      areLimitsFocused={areLimitsFocused}
      areNotificationsFocused={areNotificationsFocused}
      isSecurityFocused={isSecurityFocused}
      isProfileFocused={isProfileFocused}>
      {children}
    </SettingsLayout>
  );
});
