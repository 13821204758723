import type {Bound} from '../fp';
import type {BusSource} from '../structure';
import type {Theme} from '../styling';

export interface Appearance {
  readonly updates: BusSource<(current: AppearanceEvent) => void>;
  readonly systemThemeKind: SystemThemeKind;
  readonly preferredThemeKind: PreferredThemeKind;
  readonly isDark: boolean;
  togglePreferredThemeKind: Bound<() => Promise<void>, Appearance>;
  toggleThemeKind: Bound<() => Promise<void>, Appearance>;
  setThemeMode: Bound<(next: ThemeKind) => Promise<void>, Appearance>;
  readonly actualThemeKind: ActualThemeKind;
  readonly theme: Theme;
}

export type AppearanceEvent = {
  theme: ThemeKind;
  bg_color: string;
};

export enum ThemeKind {
  Unknown = 'unknown',
  Light = 'light',
  Dark = 'dark',
  Auto = 'auto',
}

export type SystemThemeKind =
  | ThemeKind.Light
  | ThemeKind.Dark
  | ThemeKind.Unknown;
export type PreferredThemeKind = ThemeKind;
export type ActualThemeKind = ThemeKind.Light | ThemeKind.Dark;
