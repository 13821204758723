import type {BottomSheetMethods} from '@gorhom/bottom-sheet/src/types';
import {useStrings, variance} from '@ncwallet-app/core';
import {BottomSheet, Button, ButtonVariant} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useRef} from 'react';
import {Text, View} from 'react-native';

import {useGetPermissionTexts} from './useGetPermissionTexts';

export type PromptDocumentCameraPermissionScreenProps = {
  permissionKind: 'camera' | 'gallery';
  onClose: () => void;
  onConfirm: () => void;
};

export default observer(function PromptDocumentCameraPermissionScreen(
  props: PromptDocumentCameraPermissionScreenProps,
) {
  const {permissionKind, onClose, onConfirm} = props;
  const permissionTexts = useGetPermissionTexts(permissionKind);
  const strings = useStrings();
  const ref = useRef<BottomSheetMethods>(null);

  // isConfirmedRef и все что с ним связано - костыль над миганием
  // боттом шита при открытии следующего скрина
  const isConfirmedRef = useRef(false);
  const handleConfirm = useCallback(() => {
    isConfirmedRef.current = true;
    ref.current?.close();
    onConfirm();
  }, [onConfirm]);

  const onChange = useCallback(
    (index: number) => {
      if (index === -1 && !isConfirmedRef.current) {
        onClose();
      }
    },
    [onClose],
  );

  return (
    <BottomSheet
      ref={ref}
      index={0}
      onChange={onChange}
      animateOnMount
      snapPoints={[300]}
      enablePanDownToClose
      backdropProps={{appearsOnIndex: 0, disappearsOnIndex: -1}}
      title={permissionTexts.title}
      onClose={onClose}
      children={
        <Container>
          <DeleteIpText>{permissionTexts.text}</DeleteIpText>
          <Button
            onPress={handleConfirm}
            title={
              strings[
                'deleteIdentityUploadedDocument.permissions.actions.toSettings'
              ]
            }
            variant={ButtonVariant.PrimaryLight}
          />
          <Divider />
          <Button
            onPress={onClose}
            title={
              strings[
                'deleteIdentityUploadedDocument.permissions.actions.cancel'
              ]
            }
            variant={ButtonVariant.Danger}
          />
        </Container>
      }
    />
  );
});

const Container = variance(View)(() => ({
  root: {
    paddingHorizontal: 15,
    paddingTop: 20,
  },
}));

const Divider = variance(View)(() => ({
  root: {
    height: 20,
  },
}));

const DeleteIpText = variance(Text)(theme => ({
  root: {
    marginBottom: 20,
    ...theme.fontByWeight('400'),
    fontSize: 14,
    color: theme.palette.textAdditional1,
  },
}));
