import {
  createStylesHook,
  type CurrencyDescription,
  formatCryptoValue,
  useStrings,
  useTheme,
  variance,
} from '@ncwallet-app/core';
import {
  EarningMonthSvg,
  EarningsSVG,
  UserSvg,
} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

export type AffiliateStatisticCardProps = {
  currency: CurrencyDescription | undefined;
  referrals: number;
  lastMonthEarnings: string;
  lastWeakEarnings: string;
};
export default observer(function AffiliateStatisticCard(
  props: AffiliateStatisticCardProps,
) {
  const theme = useTheme();
  const strings = useStrings();
  const {referrals, lastWeakEarnings, lastMonthEarnings} = props;
  const styles = useStyles();

  return (
    <Container>
      <Title>{strings['affiliateScreen.statistic']}</Title>
      <Desc>{strings['affiliateScreen.description']}</Desc>
      <Row out>
        <UserSvg style={styles.icon} color={theme.palette.uiMain} />
        <Row inner>
          <Label>{strings['affiliateScreen.joined']}</Label>
          <Value>{referrals}</Value>
        </Row>
      </Row>
      <Space />
      <View style={styles.weekAndMonth}>
        <Col>
          <EarningsSVG style={styles.icon} color={theme.palette.uiMain} />
          <Label>{strings['affiliateScreen.earnWeek']}</Label>
          <Value>
            {formatCryptoValue(
              lastWeakEarnings,
              props.currency?.fractionDigits,
            )}{' '}
            CTC
          </Value>
        </Col>
        <Col>
          <EarningMonthSvg style={styles.icon} color={theme.palette.uiMain} />
          <Label>{strings['affiliateScreen.earnMonth']}</Label>
          <Value>
            {formatCryptoValue(
              lastMonthEarnings,
              props.currency?.fractionDigits,
            )}{' '}
            CTC
          </Value>
        </Col>
      </View>
    </Container>
  );
});

const useStyles = createStylesHook(theme => ({
  icon: {
    flexShrink: 0,
    marginRight: 14,
    color: theme.palette.primary,
  },
  weekAndMonth: {
    flex: 1,
    flexDirection: 'row',
    gap: 8,
  },
}));

const Desc = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    fontSize: 16,
    lineHeight: 26,
    textAlign: 'left',
    color: theme.palette.textAdditional1,
  },
}));

const Container = variance(View)(theme => ({
  root: {
    padding: 15,
    marginBottom: 20,
    borderRadius: 16,
    backgroundColor: theme.palette.background,
  },
}));

const Title = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 16,
    marginBottom: 20,
    color: theme.palette.textMain,
  },
}));

const Space = variance(View)(() => ({
  root: {
    height: 8,
  },
}));

const Row = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.palette.additional4,
    borderRadius: 8,
  },
  out: {
    padding: 12,
    marginTop: 16,
  },
  inner: {
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    flex: 1,
  },
}));

const Col = variance(View)(theme => ({
  root: {
    flexDirection: 'column',
    backgroundColor: theme.palette.additional4,
    padding: 12,
    borderRadius: 8,
    flex: 1,
    gap: 10,
  },
}));

const Label = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textMain,
    fontSize: 14,
    lineHeight: 22,
    marginRight: 10,
  },
}));

const Value = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    fontSize: 14,
    lineHeight: 22,
    color: theme.palette.textAdditional2,
    textAlign: 'left',
  },
}));
