import type {LocaleKeys} from '@ncwallet-app/localization/locale/LocaleStrings';

import type {BaseError} from '../Error';
import {GENERAL_JSON_RPC_ERROR} from '../Error';
import type {DecimalString} from '../Money';

export enum CommonErrorCode {
  InvalidToken = 1000,
  TokenExpired = 1001,
  Unauthorized = 2000,
  UserNotFound = 2001,
  UserBanned = 2002,
  TwoFaRequired = 2003,
  BalanceMustBeEmpty = 2010,
  TwoFaIncorrect = 2004,
  InvalidClientTime = 2012,
  InvalidIpAddress = 2013,
  IpAddressNotFound = 2014,
  BiometryAlreadySet = 2028,
  AccountVerificationRequired = 2029,
  IpAddressAlreadyExists = 2015,
  WalletInsufficientFunds = 3002,
  LimitExceeded = 3006,
  InvalidAddress = 3007,
  LinkCodeAlreadyExist = 3010,
  WalletTransactionBlocked = 4000,
  ExchangeRateChanged = 4001,
  AmountTooSmall = 4002,
  ExchangeTokenExpired = 4003,
  ExchangeTokenInvalid = 4004,
  AccountSuspended = 5001,
  ExchangeRateNotFound = 5002,
  CurrencyActionUnavailable = 5003,
  EstimatedFeeChanged = 6004,
}

type ErrorCodesWithoutData = Exclude<
  CommonErrorCode,
  CommonErrorCode.AmountTooSmall | CommonErrorCode.EstimatedFeeChanged
>;

export type AmountTooSmallError = {
  code: CommonErrorCode.AmountTooSmall;
  message: string;
  data: {
    min_from_amount: DecimalString;
    min_to_amount: DecimalString;
  };
};

export type EstimatedFeeChangedError = {
  code: CommonErrorCode.EstimatedFeeChanged;
  message: string;
  data: {
    old_fee: DecimalString;
    new_fee: DecimalString;
    diff: DecimalString;
  };
};

export type CommonError =
  | {code: ErrorCodesWithoutData; message: string}
  | AmountTooSmallError
  | EstimatedFeeChangedError;

export type CommonErrorResponse = {
  kind: typeof GENERAL_JSON_RPC_ERROR;
  description: string;
  raw: unknown;
  body: CommonError;
};

export const isCommonErrorResponse = (
  error: BaseError,
): error is CommonErrorResponse => {
  if (error.kind !== GENERAL_JSON_RPC_ERROR) {
    return false;
  }
  const code = Reflect.get(Reflect.get(error, 'body') ?? {}, 'code') as
    | number
    | undefined;
  return !!(code && CommonErrorCode[code]);
};

export const COMMON_ERROR_TO_LOCALE: Record<number, LocaleKeys> = {
  [CommonErrorCode.InvalidToken]: 'errors.invalidToken',
  [CommonErrorCode.TokenExpired]: 'errors.tokenExpired',
  [CommonErrorCode.Unauthorized]: 'errors.unauthorized',
  [CommonErrorCode.UserNotFound]: 'errors.userNotFound',
  [CommonErrorCode.UserBanned]: 'errors.userBanned',
  [CommonErrorCode.TwoFaIncorrect]: 'errors.twoFAIncorrect',
  [CommonErrorCode.BalanceMustBeEmpty]:
    'errors.accountDeletionProhibitedIfBalanceNotEmpty',
  [CommonErrorCode.InvalidClientTime]: 'errors.incorrectSystemTime',
  [CommonErrorCode.WalletInsufficientFunds]: 'errors.walletInsufficientFunds',
  [CommonErrorCode.WalletTransactionBlocked]: 'errors.walletTransactionBlocked',
  [CommonErrorCode.ExchangeRateChanged]: 'errors.exchangeRateChanged',
  [CommonErrorCode.ExchangeRateNotFound]:
    'errors.exchangeTemporarilyUnavailable',
  [CommonErrorCode.AmountTooSmall]: 'errors.amountTooSmall',
  [CommonErrorCode.ExchangeTokenExpired]: 'errors.exchangeTokenExpired',
  [CommonErrorCode.ExchangeTokenInvalid]: 'errors.exchangeTokenInvalid',
  [CommonErrorCode.LimitExceeded]: 'errors.limitExceeded',
  [CommonErrorCode.InvalidIpAddress]: 'errors.invalidIpAddress',
  [CommonErrorCode.IpAddressAlreadyExists]: 'errors.ipAddressAlreadyExists',
  [CommonErrorCode.IpAddressNotFound]: 'errors.ipAddressNotFound',
  [CommonErrorCode.TwoFaRequired]: 'errors.twoFaRequired',
  [CommonErrorCode.EstimatedFeeChanged]: 'errors.estimatedFeeChanged',
  [CommonErrorCode.AccountSuspended]: 'errors.exchangeNotAllowed',
  [CommonErrorCode.LinkCodeAlreadyExist]: 'errors.linkCodeAlreadyExist',
  [CommonErrorCode.BiometryAlreadySet]: 'errors.BiometryAlreadySet',
  [CommonErrorCode.CurrencyActionUnavailable]: 'error.actionUnavailable',
  [CommonErrorCode.AccountVerificationRequired]:
    'error.accountVerificationRequired',
  [CommonErrorCode.InvalidAddress]:
    'sendCrypto.validation.Networkandaddressmismatch',
};

export const COMMON_REST_ERROR_TO_LOCALE: Record<string, LocaleKeys> = {
  token_invalid: 'errors.invalidToken',
  token_expired: 'errors.tokenExpired',
  token_not_found: 'errors.tokenExpired',
  '2fa_mismatch': 'errors.2faMismatch',
};
