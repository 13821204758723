import {variance} from '@ncwallet-app/core/src';
import type {LocaleKeys} from '@ncwallet-app/localization/locale/LocaleStrings';
import {observer} from 'mobx-react-lite';
import type {Key} from 'react';
import React from 'react';
import type {StyleProp, ViewStyle} from 'react-native';
import {View} from 'react-native';

import {LG_BREAKPOINT} from '../../grid';
import {Tab} from './Tab';

export type TabRowProps<T> = {
  items: T[];
  textKey: (item: T) => LocaleKeys | undefined;
  active: (item: T) => boolean;
  onPress: (item: T) => void;
  idKey: (item: T) => Key | null | undefined;
  style?: StyleProp<ViewStyle>;
};

export type TabRowBaseItem<P> = {
  key: P;
  text: LocaleKeys;
};

export default observer(function TabRow<T>(props: TabRowProps<T>) {
  const activeTabIndex = props.items.findIndex(props.active);
  return (
    <TabRowContainer style={props.style}>
      {props.items.map((item, _idx) => (
        <Tab
          label={props.textKey(item)}
          key={props.idKey(item)}
          active={props.active(item)}
          nextActiveTab={_idx === activeTabIndex - 1}
          onPress={() => {
            props.onPress(item);
          }}
          isLast={_idx === props.items.length - 1}
          testID={`${props.idKey(item) ?? ''}-limit`}
        />
      ))}
    </TabRowContainer>
  );
});

const TabRowContainer = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 3,
    borderRadius: 8,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.uiSecondary,
    backgroundColor: theme.palette.uiPrimary,

    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        maxWidth: 345,
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: 20,
      },
    }),
  },
}));
