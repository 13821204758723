import {useStrings, useTheme, variance} from '@ncwallet-app/core';
import {
  Button,
  ButtonColor,
  LG_BREAKPOINT,
  SafeAreaScrollView,
  useIsDimensions,
} from '@ncwallet-app/ui';
import {ArrowLeftWide} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import type {PropsWithChildren} from 'react';
import React from 'react';
import {View} from 'react-native';

export type HistoryFilterKindContainerProps = PropsWithChildren<{
  onBack: () => void;
}>;

export default observer(function HistoryFilterKindContainer({
  children,
  onBack,
  ...rest
}: HistoryFilterKindContainerProps) {
  const isLg = useIsDimensions('lg');
  const theme = useTheme();
  const strings = useStrings();

  if (isLg) {
    return (
      <LgRoot>
        <Inner>
          <BackButton
            testID="back_scrn_btn"
            title={strings['historyOperationFilterScreen.title']}
            onPress={onBack}
            color={ButtonColor.Default}
            Icon={ArrowLeftWide}
            iconCustomColor={theme.palette.uiAdditional1}
          />
          <ListContainer>{children}</ListContainer>
        </Inner>
      </LgRoot>
    );
  }

  return (
    <Root stickyHeaderIndices={[0]} {...rest}>
      {children}
    </Root>
  );
});

const Inner = variance(View)(theme => ({
  root: {
    flex: 1,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        borderRadius: 10,
        padding: 30,
        backgroundColor: theme.palette.background,
      },
    }),
  },
}));

const Root = variance(SafeAreaScrollView)(() => ({
  root: {
    flex: 1,
  },
}));

const ListContainer = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
}));

const LgRoot = variance(View)(theme => ({
  root: {
    flex: 1,
    borderRadius: 10,
    backgroundColor: theme.palette.uiPrimary,
    ...theme.bar(10),
  },
}));

const BackButton = variance(Button)(() => ({
  root: {
    marginBottom: 20,
    padding: 0,
    borderWidth: 0,
    justifyContent: 'flex-start',
    fontSize: 18,
    lineHeight: 22,
  },
}));
