import {useRoot} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import LinkToTelegramModal from '../../../screens/LinkToTelegramScreen/LinkToTelegramModal';
import type {LargeHomeStackBindingProps} from '../LargeHomeStackBindingProps';

export type PromptLinkToTelegramBindingProps =
  LargeHomeStackBindingProps<'PromptLinkToTelegram'>;

export default observer(function PromptLinkToTelegramBinding({
  navigation,
}: PromptLinkToTelegramBindingProps) {
  const {
    configuration: {
      values: {telegramBotId, telegramOauthOrigin},
    },
  } = useRoot();

  const onClose = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
      return;
    }
    navigation.reset({index: 0, routes: [{name: 'Root'}]});
  }, [navigation]);

  return (
    <LinkToTelegramModal
      testId="telegram_list_btn"
      telegramBotId={telegramBotId}
      telegramOauthOrigin={telegramOauthOrigin}
      redirectUriForTelegramLinking={telegramOauthOrigin}
      onClose={onClose}
    />
  );
});
