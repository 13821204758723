import {action, makeObservable, observable, runInAction} from 'mobx';

import type {AccountIdStore} from '../Auth';
import {LOG_IN, LOG_OUT, SWITCH} from '../Auth';
import type {GeneralJsonRpcError, GlobalError, UnknownError} from '../Error';
import {GENERAL_JSON_RPC_ERROR, UNKNOWN_ERROR} from '../Error';
import type {ErrorRepository} from '../ErrorRepository';
import type {JsonSerializable} from '../Json';
import type {Tunnel} from '../JsonRpc';
import {isError, isResponse, TUNNEL_INCOMING_MESSAGE} from '../JsonRpc';
import {CommonErrorCode} from '../NCWalletServer';
import type {Service} from '../structure';
import {batchDisposers} from '../structure';
import type {CriticalErrorState} from './CriticalErrorState';
import {InternalError, MaintenanceError, UnknownServerError} from './errors';

export default class CriticalErrorStateService
  implements CriticalErrorState, Service
{
  @observable.ref private _latest: GlobalError | undefined;

  constructor(
    private readonly _root: {
      readonly accountIdStore: AccountIdStore;
      readonly errorRepository: ErrorRepository;
    },
    private readonly _tunnel: Tunnel<JsonSerializable, JsonSerializable>,
  ) {
    makeObservable(this);
  }

  get latest(): GlobalError | undefined {
    return this._latest;
  }

  @action clear() {
    this._latest = undefined;
  }

  subscribe() {
    return batchDisposers(
      this._tunnel.io.listen(TUNNEL_INCOMING_MESSAGE, async _ => {
        if (isResponse(_) && isError(_)) {
          switch (_.error.code) {
            case -32603:
            case 9999:
            case 10000: {
              const Constructor = ERROR_MAP[_.error.code];
              const raw = new Constructor(undefined, {cause: _.error});
              const latest = this._root.errorRepository.create<UnknownError>({
                kind: UNKNOWN_ERROR,
                raw,
              });
              runInAction(() => {
                this._latest = latest;
              });
              break;
            }
            // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
            case CommonErrorCode.UserBanned: {
              const latest =
                this._root.errorRepository.create<GeneralJsonRpcError>({
                  kind: GENERAL_JSON_RPC_ERROR,
                  body: _.error,
                  description: `${_.error.code} ${_.error.message}`,
                });
              runInAction(() => {
                this._latest = latest;
              });
              break;
            }
          }
        }
      }),

      this._root.accountIdStore.events.listen(LOG_IN, this._clear),
      this._root.accountIdStore.events.listen(LOG_OUT, this._clear),
      this._root.accountIdStore.events.listen(SWITCH, this._clear),
    );
  }

  private readonly _clear = () => {
    this.clear();
  };
}

const ERROR_MAP = Object.freeze({
  10000: MaintenanceError,
  [-32603]: InternalError,
  9999: UnknownServerError,
});
