import {useStrings, variance} from '@ncwallet-app/core';
import type {LocaleKeys} from '@ncwallet-app/localization/locale/LocaleStrings';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

import {LG_BREAKPOINT} from '../grid';

export type SelectionCryptoTopMessageProps = {
  texts:
    | {
        title: LocaleKeys;
        message: LocaleKeys;
      }
    | undefined;
};

export default observer(function SelectionCryptoTopMessage(
  props: SelectionCryptoTopMessageProps,
) {
  const strings = useStrings();
  const {texts} = props;
  if (!texts) {
    return null;
  }

  return (
    <TopMessageContainer>
      <TopMessageTitle>{strings[texts.title]}</TopMessageTitle>
      <TopMessageText>{strings[texts.message]}</TopMessageText>
    </TopMessageContainer>
  );
});

const TopMessageContainer = variance(View)(theme => ({
  root: {
    paddingTop: 30,
    paddingBottom: 15,
    paddingHorizontal: 15,

    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingTop: 0,
      },
    }),
  },
}));

const TopMessageTitle = variance(Text)(theme => ({
  root: {
    marginBottom: 10,
    ...theme.fontByWeight('700'),
    fontSize: 16,
    color: theme.palette.textPrimary,
  },
}));

const TopMessageText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    fontSize: 13,
    color: theme.palette.textAdditional2,
    lineHeight: 20,
  },
}));
