import {observer} from 'mobx-react-lite';
import type {ReactNode} from 'react';

export type ButtonPortalProps = {
  portalKey: string;
  children: ReactNode;
};

const ButtonPortal = observer(({children}: ButtonPortalProps) => {
  return children;
});

export default ButtonPortal;
