import {useRoot, useStrings, variance} from '@ncwallet-app/core';
import {BottomSheet, Button, ButtonVariant} from '@ncwallet-app/ui';
import {HeartDarkSvg, HeartSvg} from '@ncwallet-app/ui/src/assets/svg/colored';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo} from 'react';
import {Text, View} from 'react-native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

export type PromptSignOutScreenProps = {
  onConfirm: () => void;
  onCancel: () => void;
};

export default observer(function PromptSignOutScreen(
  props: PromptSignOutScreenProps,
) {
  const {onCancel, onConfirm} = props;
  const {appearance, layoutHelperState} = useRoot();
  const strings = useStrings();
  const onChange = useCallback(
    (index: number) => {
      if (index === -1) {
        onCancel();
      }
    },
    [onCancel],
  );
  const {bottom} = useSafeAreaInsets();
  const isLarge = layoutHelperState.isLarge;

  const containerStyle = useMemo(() => {
    return isLarge ? {} : {paddingBottom: bottom};
  }, [bottom, isLarge]);

  return (
    <BottomSheet
      index={0}
      onChange={onChange}
      animateOnMount
      enablePanDownToClose
      enableAutoHeight
      snapPoints={[420]}
      backdropProps={{appearsOnIndex: 0, disappearsOnIndex: -1}}
      onClose={onCancel}
      children={
        <Container style={containerStyle}>
          {!appearance.isDark ? (
            <IconHeartDark width={120} height={120} />
          ) : (
            <IconHeart width={120} height={120} />
          )}

          <Title>{strings['userSettings.logOut']}?</Title>
          <Divider />
          <Divider />
          <Button
            onPress={onConfirm}
            title={strings['yes']}
            variant={ButtonVariant.Primary}
          />
          <Divider />
          <Button
            onPress={onCancel}
            title={strings['no']}
            variant={ButtonVariant.DangerFill}
          />
          <Divider />
          <Divider />
          <Divider />
        </Container>
      }
    />
  );
});

const Divider = variance(View)(() => ({
  root: {
    height: 20,
  },
}));

const Title = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight(700),
    color: theme.palette.textPrimary,
    textAlign: 'center',
    fontSize: 26,
    marginTop: 20,
  },
}));

const Container = variance(View)(() => ({
  root: {
    paddingHorizontal: 15,
    paddingTop: 5,
  },
}));

const IconHeart = variance(HeartSvg)(() => ({
  root: {
    margin: 'auto',
  },
}));

const IconHeartDark = variance(HeartDarkSvg)(() => ({
  root: {
    margin: 'auto',
  },
}));
