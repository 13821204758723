import type {BottomSheetMethods} from '@gorhom/bottom-sheet/src/types';
import {useStrings, variance} from '@ncwallet-app/core';
import {
  BottomSheet,
  Button,
  ButtonColor,
  ButtonVariant,
} from '@ncwallet-app/ui/src';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useRef} from 'react';
import {Text, View} from 'react-native';

type RemoveLimitBottomSheetProps = {
  onCancelPress: () => void;
  onDeletePress: () => void;
  isCancel?: boolean;
};

export default observer(function RemoveLimitBottomSheet(
  props: RemoveLimitBottomSheetProps,
) {
  const {onCancelPress, onDeletePress, isCancel} = props;
  const strings = useStrings();
  const ref = useRef<BottomSheetMethods>(null);

  const isConfirmedRef = useRef(false);
  const handleDelete = useCallback(() => {
    isConfirmedRef.current = true;
    ref.current?.close();
    onDeletePress();
  }, [onDeletePress]);

  const onChange = useCallback(
    (index: number) => {
      if (index === -1 && !isConfirmedRef.current) {
        onCancelPress();
      }
    },
    [onCancelPress],
  );

  return (
    <BottomSheet
      ref={ref}
      index={0}
      title={
        isCancel
          ? strings['currencyLimitScreen.cancelChange']
          : strings['currencyLimitScreen.disableLimit']
      }
      snapPoints={[275]}
      onChange={onChange}
      onClose={onCancelPress}
      animateOnMount
      enablePanDownToClose
      backdropProps={{appearsOnIndex: 0, disappearsOnIndex: -1}}
      children={
        <BottomSheetBody>
          {!isCancel && (
            <BottomSheetText>
              {strings['currencyLimitScreen.warningLabel']}
            </BottomSheetText>
          )}
          <BottomSheetButton
            title={strings['currencyLimitScreen.yesButton']}
            onPress={handleDelete}
            variant={ButtonVariant.PrimaryLight}
            color={ButtonColor.Secondary}
          />

          <BottomSheetButton
            title={strings['currencyLimitScreen.cancelButton']}
            onPress={onCancelPress}
            variant={ButtonVariant.Danger}
            color={ButtonColor.Secondary}
          />
        </BottomSheetBody>
      }
    />
  );
});

const BottomSheetBody = variance(View)(() => ({
  root: {
    flex: 1,
    paddingVertical: 20,
    paddingHorizontal: 15,
  },
}));

const BottomSheetText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    fontSize: 14,
    lineHeight: 24,
    color: theme.palette.textAdditional1,
  },
}));

const BottomSheetButton = variance(Button)(theme => ({
  root: {
    marginTop: 20,
    borderColor: theme.palette.uiSecondary,
  },
}));
