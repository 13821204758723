import {useStyles} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';
import type {
  OverriddenProps,
  PartialStyleSheetRecord,
} from 'react-native-awesome-markdown';
import MarkdownComponent, {
  mergeNamedStyles,
} from 'react-native-awesome-markdown';

export type MarkdownProps = {
  children: string;
  styles?: PartialStyleSheetRecord;
  rules?: OverriddenProps['rules'];
  onPress: (url: string) => void;
};

export default observer(function Markdown({
  children,
  styles,
  rules,
  onPress,
}: MarkdownProps) {
  const themedStyles = useStyles(theme => ({
    paragraph: {
      ...theme.fontByWeight('500'),
      fontSize: 13,
      textAlign: 'center',
      color: theme.palette.error,
      marginTop: 0,
      marginBottom: 0,
    },
    link: {
      color: theme.palette.error,
      textDecorationLine: 'none',
    },
  }));

  const _styles = useMemo(
    () => mergeNamedStyles<PartialStyleSheetRecord>(themedStyles, styles),
    [styles, themedStyles],
  );

  return (
    <MarkdownComponent
      text={children}
      onLinkPress={onPress}
      overridden={{
        styles: _styles,
        rules,
      }}
    />
  );
});
