import deCountries from '@ncwallet-app/localization/countries/de.json';
import enCountries from '@ncwallet-app/localization/countries/en.json';
import esCountries from '@ncwallet-app/localization/countries/es.json';
import frCountries from '@ncwallet-app/localization/countries/fr.json';
import itCountries from '@ncwallet-app/localization/countries/it.json';
import ptCountries from '@ncwallet-app/localization/countries/pt.json';
import ruCountries from '@ncwallet-app/localization/countries/ru.json';
import de from '@ncwallet-app/localization/locale/dictionaries/de.json';
import en from '@ncwallet-app/localization/locale/dictionaries/en.json';
import es from '@ncwallet-app/localization/locale/dictionaries/es.json';
import fr from '@ncwallet-app/localization/locale/dictionaries/fr.json';
import it from '@ncwallet-app/localization/locale/dictionaries/it.json';
import ptBR from '@ncwallet-app/localization/locale/dictionaries/pt_BR.json';
import ru from '@ncwallet-app/localization/locale/dictionaries/ru.json';
import type {LocaleDict} from '@ncwallet-app/localization/locale/LocaleStrings';

import type {Opaque} from '../Opaque';

/**
 * Language CODES with translations available.
 * 'pt', not 'pt-BR'
 */
export const AVAILABLE_LANGUAGE_CODES = new Set([
  'de',
  'en',
  'es',
  'fr',
  'it',
  'pt',
  'ru',
] as const);

export type AvailableLanguageCode = ValueOfSet<typeof AVAILABLE_LANGUAGE_CODES>;
export type ValueOfSet<T> = T extends Set<infer TT> ? TT : never;

export const TRANSLATION_SETS = {
  de: de,
  en: en,
  es: es,
  fr: fr,
  it: it,
  pt: ptBR,
  ru: ru,
} as Record<AvailableLanguageCode, LocaleDict>;

export const COUNTRY_ID = Symbol();
export type CountryId = Opaque<number, typeof COUNTRY_ID>;

export type CountryNameData = {
  id: CountryId;
  alpha2: string;
  alpha3: string;
  name: string;
};

export const COUNTRIES_SETS = {
  de: deCountries,
  en: enCountries,
  es: esCountries,
  fr: frCountries,
  it: itCountries,
  pt: ptCountries,
  ru: ruCountries,
} as Record<AvailableLanguageCode, CountryNameData[]>;
