import {useStrings} from '@ncwallet-app/core';
import type {
  Message,
  MessageVariant,
} from '@ncwallet-app/core/src/FlashMessage';
import type {Theme} from '@ncwallet-app/core/src/styling';
import {useStyles, useTheme, variance} from '@ncwallet-app/core/src/styling';
import {sized} from '@ncwallet-app/core/src/Svg';
import type {LocaleKeys} from '@ncwallet-app/localization/locale/LocaleStrings';
import {observer} from 'mobx-react-lite';
import type {PropsWithChildren} from 'react';
import React, {useCallback} from 'react';
import type {Insets} from 'react-native';
import {Linking, StyleSheet, Text, View} from 'react-native';

import {
  CheckMessageSvg,
  CrossGraySvg,
  ErrorMessageSvg,
  HelpSvg,
  InfoMessageSvg,
  WarningMessageSvg,
} from '../../../assets/svg/colorless';
import {Pressable, TouchableOpacity} from '../../atoms';

export type MessageHeight = {id: string; height: number};

export type MessageViewProps = {
  message: Message;
  onDismiss: (id: string) => void;
  messageHeights: MessageHeight[];
  setHeight: (l: MessageHeight) => void;
};

export default observer(function MessageView({
  message,
  onDismiss,
  setHeight,
}: MessageViewProps) {
  const {id, title, text, variant = 'primary', link, onPress} = message;
  const styles = useStyles(theme => ({
    default: {
      backgroundColor: theme.palette.background,
      borderColor: theme.palette.background,
      shadowColor: theme.palette.blackout,
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.3,
      shadowRadius: 6,
    },
  }));
  const theme = useTheme();
  const strings = useStrings();
  return (
    <View
      style={[rootStyles.root, styles.default]}
      testID="toast-message"
      onLayout={event => {
        setHeight({id, height: event.nativeEvent.layout.height});
      }}>
      <Container link={link} onPress={onPress}>
        <TopRow>
          <View style={rootStyles.iconView}>
            {renderIconByType(variant, theme)}
          </View>
          <MessageTitle>
            {strings[title as LocaleKeys] || title}{' '}
            {(!!link || !!onPress) && (
              <HelpIconMobile translateY={4} color={theme.palette.uiMain} />
            )}
          </MessageTitle>
        </TopRow>
        {text && <MessageText>{text}</MessageText>}
        <TouchableOpacity
          onPress={() => {
            onDismiss(message.id);
          }}
          activeOpacity={0.9}
          containerStyle={rootStyles.crossView}
          hitSlop={HIT_SLOP}>
          <CrossIcon
            color={theme.palette.uiAdditional1}
            style={rootStyles.crossIcon}
          />
        </TouchableOpacity>
      </Container>
    </View>
  );
});

const HIT_SLOP: Insets = {top: 16, right: 16, bottom: 16, left: 16};

const Container = observer(
  (props: PropsWithChildren<{link?: string; onPress?: () => void}>) => {
    const {children, link, onPress} = props;
    const handlePress = useCallback(() => {
      if (link) {
        void Linking.openURL(link);
      }
      onPress?.();
    }, [onPress, link]);
    return link || onPress ? (
      <Pressable onPress={handlePress} style={rootStyles.contentView}>
        {children}
      </Pressable>
    ) : (
      <View style={rootStyles.contentView}>{children}</View>
    );
  },
);

const rootStyles = StyleSheet.create({
  root: {
    padding: 16,
    flexDirection: 'row',
    alignItems: 'flex-start',
    borderRadius: 8,
    flex: undefined,
    borderWidth: 1,
  },
  iconView: {
    marginRight: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentView: {
    position: 'relative',
    flex: 1,
  },
  crossView: {
    position: 'absolute',
    right: 0,
    top: 3,
  },
  crossIcon: {
    position: 'absolute',
    right: 0,
    top: 1,
  },
});

const TopRow = variance(View)(() => ({
  root: {
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const MessageTitle = variance(Text)(theme => ({
  root: {
    flex: 1,
    paddingRight: 20,
    flexDirection: 'row',
    alignItems: 'center',
    ...theme.fontByWeight('bold'),
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.textPrimary,
  },
}));

const MessageText = variance(Text)(theme => ({
  root: {
    paddingLeft: 40,
    paddingRight: 15,
    marginTop: 4,
    ...theme.fontByWeight('500'),
    lineHeight: 20,
    fontSize: 12,
    color: theme.palette.textPrimary,
  },
}));

function renderIconByType(variant: MessageVariant, theme: Theme) {
  switch (variant) {
    case 'primary':
      return <WarningIcon color={theme.palette.primary} />;
    case 'success':
      return <CheckIcon color={theme.palette.success} />;
    case 'info':
      return <InfoIcon color={theme.palette.info} />;
    case 'danger':
      return <ErrorIcon color={theme.palette.error} />;
  }
}

const CrossIcon = sized(CrossGraySvg, 16);

const CheckIcon = sized(CheckMessageSvg, 24);
const InfoIcon = sized(InfoMessageSvg, 24);
const WarningIcon = sized(WarningMessageSvg, 24);
const ErrorIcon = sized(ErrorMessageSvg, 24);
const HelpIconMobile = sized(HelpSvg, 16);
