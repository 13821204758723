import type {BarcodeSettings, CameraViewProps} from 'expo-camera';
import {CameraView, Camera as ExpoCamera} from 'expo-camera';
import React, {forwardRef} from 'react';
import {StyleSheet} from 'react-native';

import type {
  Camera,
  DocCameraComponentType,
  QrCameraComponentProps,
} from './Camera';

const baseComponentProps = {
  facing: 'back',
  ratio: '16:9',
  style: StyleSheet.absoluteFillObject,
} satisfies CameraViewProps;

const BAR_CODE_SCANNER_SETTINGS = {
  barcodeTypes: ['qr'],
} satisfies BarcodeSettings;

export default class CommonCameraImpl implements Camera {
  getCameraPermissionsAsync = ExpoCamera.getCameraPermissionsAsync;
  requestCameraPermissionsAsync = ExpoCamera.requestCameraPermissionsAsync;
  DocCameraComponent = forwardRef<CameraView>((_, ref) => {
    return <CameraView {...baseComponentProps} ref={ref} />;
  }) as DocCameraComponentType;
  QrCameraComponent = (props: QrCameraComponentProps) => {
    return (
      <CameraView
        {...baseComponentProps}
        barcodeScannerSettings={BAR_CODE_SCANNER_SETTINGS}
        onBarcodeScanned={({data}) => {
          props.onBarCodeScanned(data);
        }}
      />
    );
  };
}
