import {Platform} from 'react-native';

// eslint-disable-next-line import-x/prefer-default-export
export function isChromeExtension() {
  if (Platform.OS !== 'web') {
    return false;
  }

  return 'chrome' in globalThis && 'extension' in chrome;
}
