import {useRoot} from '@ncwallet-app/core';
import {Domain, EventType} from '@ncwallet-app/core/src/AnalyticsNotification';
import {stateToPath} from '@ncwallet-app/core/src/CommonNavigationScheme';
import type {Url} from '@ncwallet-app/core/src/units/Uri';
import {useEffect} from 'react';

// eslint-disable-next-line import-x/prefer-default-export
export const useErrorAnalytics = (error: unknown) => {
  const root = useRoot();

  useEffect(() => {
    if (!error) return;

    const stackTrace =
      error instanceof Error && error.stack
        ? error.stack
        : 'No stack trace available';
    const authStackRoute = root.authStackNavigation.getAuthStackRoute();
    const navigationRoute = root.navigationContainerState.latestState
      ? stateToPath(root.navigationContainerState.latestState).toString()
      : undefined;

    root.analyticsNotificationService.addEvent({
      name: EventType.FrontendError,
      meta: {
        domain: Domain.UnexpectedError,
        stack: stackTrace,
        url: (authStackRoute ?? navigationRoute ?? '') as Url,
      },
    });
  }, [error, root]);
};
