import {useStrings, variance} from '@ncwallet-app/core';
import {Button, ButtonIconPosition, ButtonVariant} from '@ncwallet-app/ui';
import {OpenLinkSVG} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import type {ImageSourcePropType} from 'react-native';
import {Image, Text, View} from 'react-native';

import {
  HeaderTag,
  HeaderTitleTag,
  LgProfileCardContainer,
} from '../ProfileSettingsScreen/LgProfileCards/useCardStyles';

export type MoreCardProps = {
  onMore: () => void;
};

export default observer(function MoreCard(props: MoreCardProps) {
  const {onMore} = props;
  const strings = useStrings();
  return (
    <LgProfileCardContainer>
      <HeaderTag>
        <HeaderTitleTag>{strings['userSettings.affiliate']}</HeaderTitleTag>
      </HeaderTag>
      <Body>
        <Img
          source={
            require('../../../../ui/src/assets/images/affiliate-transparent.png') as ImageSourcePropType
          }
        />
        <Title>{strings['affiliateScreen.title']}</Title>
        <Desc>{strings['affiliateScreen.description']}</Desc>
        <Btn
          title={strings['affiliateScreen.btn.more']}
          Icon={OpenLinkSVG}
          onPress={onMore}
          iconPosition={ButtonIconPosition.Right}
          variant={ButtonVariant.PrimaryLight}
        />
      </Body>
    </LgProfileCardContainer>
  );
});

const Body = variance(View)(() => ({
  root: {
    padding: 20,
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
}));

const Img = variance(Image)(() => ({
  root: {
    width: 224,
    height: 174,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 25,
    marginTop: 44,
  },
}));

const Title = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 16,
    marginBottom: 10,
    color: theme.palette.textMain,
    textAlign: 'center',
  },
}));

const Desc = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    textAlign: 'center',
    fontSize: 14,
    lineHeight: 20,
    marginBottom: 25,
    color: theme.palette.textAdditional1,
  },
}));

const Btn = variance(Button)(() => ({
  root: {
    width: '100%',
    marginTop: 'auto',
  },
}));
