import {useStrings, variance} from '@ncwallet-app/core';
import {InputVariant, TouchableWithoutFeedback} from '@ncwallet-app/ui';
import {ArrowDownWideSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

export type SelectCountryProps = {
  value: string;
  variant: InputVariant;
  onPress: () => void;
  testID?: string;
};

export default observer(function SelectCountry(props: SelectCountryProps) {
  const {value, variant, onPress, testID} = props;
  const strings = useStrings();
  const textValue = value.length
    ? value
    : strings['profileSettingsScreen.verification.address.country'];

  return (
    <TouchableWithoutFeedback onPress={onPress} testID={testID}>
      <SelectCountryTouchableInner error={variant === InputVariant.Error}>
        <SelectCountryText asPlaceholder={!value.length}>
          {textValue}
        </SelectCountryText>
        <SelectCountyIcon />
      </SelectCountryTouchableInner>
    </TouchableWithoutFeedback>
  );
});

const SelectCountryTouchableInner = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 17,
    paddingHorizontal: 10,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: theme.palette.uiSecondary,
  },
  error: {
    borderColor: theme.palette.error,
  },
}));

const SelectCountyIcon = variance(ArrowDownWideSvg)(
  () => ({
    root: {},
  }),
  theme => ({
    width: 18,
    color: theme.palette.uiAdditional1,
  }),
);

const SelectCountryText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    fontSize: 16,
    lineHeight: 16,
    color: theme.palette.textPrimary,
  },
  asPlaceholder: {
    color: theme.palette.uiAdditional1,
  },
}));
