import type {ComponentType, ReactNode} from 'react';
import type {StyleProp, ViewStyle} from 'react-native';
import type {SvgProps} from 'react-native-svg';

export type TouchableListItemProps = {
  onPress?: () => void;
  children?: ReactNode;
  style?: StyleProp<ViewStyle>;
  bodyStyle?: StyleProp<ViewStyle>;
  variant?: TouchableListItemVariant;
  Icon?: ComponentType<SvgProps> | null;
  iconColor?: string;
  disabled?: boolean;
  testID?: string;
  disabledStyle?: StyleProp<ViewStyle>;
};

export enum TouchableListItemVariant {
  Primary = 'primary',
  Secondary = 'info',
  Success = 'success',
  Danger = 'error',
}
