import {sized, useTheme, variance} from '@ncwallet-app/core';
import {useKeyboardDismiss} from '@ncwallet-app/core/src/hooks';
import type {InsetsProp} from '@ncwallet-app/ui';
import {
  SafeAreaInset,
  SafeAreaLayout,
  TouchableOpacity,
} from '@ncwallet-app/ui';
import {ArrowLeftSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {getHeaderTitle} from '@react-navigation/elements';
import type {ParamListBase} from '@react-navigation/native';
import type {
  StackHeaderProps,
  StackNavigationProp,
} from '@react-navigation/stack';
import {noop} from 'lodash';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo} from 'react';
import {Text, View} from 'react-native';
import type {SvgProps} from 'react-native-svg';

import {DEFAULT_ROUTE_NAME} from '../Navigation/constants';

export type NavigationBarProps = {
  title?: string;
  leftButton?: React.ReactNode;
  rightButton?: React.ReactNode;
  titleIcon?: React.ReactNode;
  withoutBorder?: boolean;
  insets?: InsetsProp | undefined;
  withLargeTitle?: boolean;
};

const NavigationBar = observer((props: NavigationBarProps) => {
  const {
    title,
    leftButton,
    rightButton,
    titleIcon,
    withoutBorder,
    withLargeTitle,
    insets = SafeAreaInset.TOP,
  } = props;
  return (
    <Root insets={insets}>
      <Content withoutBorder={withoutBorder}>
        <LeftView>{leftButton}</LeftView>
        {title ? (
          <Title
            title={title}
            icon={titleIcon}
            withLargeTitle={withLargeTitle}
          />
        ) : null}
        <RightView>{rightButton}</RightView>
      </Content>
    </Root>
  );
});
export default NavigationBar;

const Root = variance(SafeAreaLayout)(theme => ({
  root: {
    backgroundColor: theme.palette.background,
  },
}));

const Content = variance(View)(theme => ({
  root: {
    paddingHorizontal: 10,
    maxWidth: '100%',
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.uiSecondary,
    flexDirection: 'row',
    alignItems: 'center',
    height: 60,
  },
  withoutBorder: {
    borderBottomWidth: 0,
  },
}));

const LeftView = variance(View)(() => ({
  root: {width: 40},
}));

const RightView = variance(View)(() => ({
  root: {width: 40},
}));

type BackButtonProps = {
  Icon?: React.ComponentType<SvgProps> | false;
  onPress?: () => void;
  testID?: string;
};

export const BackButton = observer(
  ({onPress, Icon, testID}: BackButtonProps) => {
    const theme = useTheme();
    const Icon_ = Icon || ArrowLeftIcon;
    return (
      <ArrowLeftButton testID={testID} onPress={onPress}>
        <Icon_ color={theme.palette.uiMain} />
      </ArrowLeftButton>
    );
  },
);

const ArrowLeftButton = variance(TouchableOpacity)(() => ({
  root: {
    height: 40,
    width: 40,
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const ArrowLeftIcon = sized(ArrowLeftSvg, 22);

export type HeaderTitleProps = {
  title: string;
  icon?: React.ReactNode;
  withLargeTitle?: boolean;
};

const Title = observer(({title, icon, withLargeTitle}: HeaderTitleProps) => (
  <HeaderTitleView forLargeTitle={withLargeTitle}>
    {icon}
    <TitleText isIcon={!!icon} forLargeTitle={withLargeTitle}>
      {title}
    </TitleText>
  </HeaderTitleView>
));

const HeaderTitleView = variance(View)(() => ({
  root: {
    flexGrow: 1,
    flexShrink: 0,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 10,
  },
  forLargeTitle: {
    flex: 1,
  },
}));

const TitleText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 18,
    lineHeight: 22,
    color: theme.palette.textMain,
  },
  isIcon: {
    marginLeft: 5,
  },
  forLargeTitle: {
    flex: 1,
  },
}));

type NavigationBarConf<T extends ParamListBase> = NavigationBarProps & {
  screenTitle?: string;
  withBackButton?: boolean;
  withLargeTitle?: boolean;
  BackButton?: React.ComponentType<{onPress?: () => void}>;
  headerTitleVisible?: boolean;
  onEmptyHistoryBack?: (navigation: StackNavigationProp<T, string>) => void;
};

type createNavigationBarProps<S extends ParamListBase> =
  | {
      navigation: StackNavigationProp<S, string>;
      options: {title?: string};
    }
  | StackHeaderProps;

export const createNavigationBar =
  <S extends ParamListBase>(conf: NavigationBarConf<S> = {}) =>
  (props: createNavigationBarProps<S>) => {
    const {titleIcon = null, onEmptyHistoryBack = noop} = conf;
    let leftButton;

    const goBack = useCallback(() => {
      if (props.navigation.canGoBack()) {
        props.navigation.goBack();
      } else {
        onEmptyHistoryBack(props.navigation);
      }
    }, [onEmptyHistoryBack, props.navigation]);

    const handleGoBack = useKeyboardDismiss(goBack);

    if (conf.withBackButton) {
      const Component = conf.BackButton ?? BackButton;

      leftButton = <Component testID="back_scrn_btn" onPress={handleGoBack} />;
    } else {
      leftButton = null;
    }
    const title = useMemo(
      () =>
        conf.headerTitleVisible === false
          ? undefined
          : conf.screenTitle
            ? conf.screenTitle
            : getHeaderTitle(props.options, DEFAULT_ROUTE_NAME),
      [props.options],
    );
    return (
      <NavigationBar
        title={title}
        titleIcon={titleIcon}
        leftButton={leftButton}
        withoutBorder={conf.withoutBorder}
        {...conf}
      />
    );
  };
