import {observer} from 'mobx-react-lite';
import React, {type ForwardedRef} from 'react';
import {View} from 'react-native';
import {ScrollView} from 'react-native-gesture-handler';

import {variance} from '../../styling';

export type ContentLayoutProps = {
  buttonContent?: React.ReactNode;
  children?: React.ReactNode;
};

export type ScrollToEndRefType = {scrollToEnd: () => void};

const ContentLayout = observer(
  React.forwardRef(
    (
      {children}: ContentLayoutProps,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ref: ForwardedRef<ScrollToEndRefType>,
    ) => {
      return (
        <Root>
          <ScrollViewStyled>{children}</ScrollViewStyled>
        </Root>
      );
    },
  ),
);

export default ContentLayout;

export const Root = variance(View)(() => ({
  root: {
    flex: 1,
  },
}));

export const ScrollViewStyled = variance(ScrollView)(
  () => ({
    root: {},
  }),
  () => ({
    contentContainerStyle: {
      flexGrow: 1,
    },
  }),
);
