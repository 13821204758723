import {useLogButton, useRoot, useStrings} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import {PendingRequestScreen} from '../../screens/PendingRequestScreen';
import type {AuthStackBindingProps} from './AuthStackBindingProps';

export type PendingProcessBindingProps = AuthStackBindingProps<
  'PendingAuth' | 'PendingConnection'
>;

export default observer(function PendingProcessBinding(
  props: PendingProcessBindingProps,
) {
  const {route} = props;
  const routeName = route.name;
  const {authHelper, connectionSpawner, deviceInfo} = useRoot();
  const logButtonComponent = useLogButton();
  const onCancel = useCallback(async () => {
    if (routeName === 'PendingAuth') {
      await authHelper.signOut({reason: 'PendingAuthBinding'});
    } else {
      await connectionSpawner.giveUpConnection();
    }
  }, [authHelper, connectionSpawner, routeName]);
  const strings = useStrings();
  const title =
    routeName === 'PendingAuth'
      ? strings['waitingAuthScreen.title']
      : strings['waitingAuthScreen.pending'];
  return (
    <PendingRequestScreen
      title={title}
      cancelText={strings['waitingAuthScreen.cancelButton']}
      onCancel={onCancel}
      LogButton={logButtonComponent}
      appVersion={deviceInfo.getAppVersionWithBuildNumber()}
    />
  );
});
