import type {DeviceIdentification} from '../DeviceIdentification';
import type {DeviceInfo} from '../DeviceInfo';
import type {SessionContextProvider} from '../SessionContext/SessionContextProvider';
import type {
  AnalyticRestParams,
  AnalyticRestParamsProvider,
} from './AnalyticRestParamsProvider';

class AnalyticRestParamsProviderImpl implements AnalyticRestParamsProvider {
  constructor(
    private readonly _root: {
      readonly deviceInfo: DeviceInfo;
      readonly deviceIdentification: DeviceIdentification;
      readonly sessionContextProvider: SessionContextProvider;
    },
  ) {}

  getRestParams = async () => {
    const deviceId_ = await this._root.deviceIdentification.getDeviceId();
    if (!deviceId_.success) {
      return undefined;
    }
    const context = this._root.sessionContextProvider.getContext();
    const params: AnalyticRestParams = {
      av: this._root.deviceInfo.getAppVersionWithBuildNumber(),
      bid: deviceId_.right,
      app_id: this._root.deviceInfo.getBundleId(),
      platform: context.platform.toLowerCase(),
    };

    if (context.platform_version) {
      params.v = context.platform_version.toLowerCase();
    }
    return params;
  };
}

export default AnalyticRestParamsProviderImpl;
