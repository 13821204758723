import {createStylesHook, variance} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';
import {FlatList} from 'react-native-gesture-handler';

import EmptyWalletList from '../../../shared/EmptyWalletList';
import type {WalletItemData} from '../../../shared/WalletItem';
import WalletItem from '../../../shared/WalletItem';
import WalletListSkeleton from './WalletListSkeleton';

export type CurrencyListProps = {
  data: WalletItemData[] | undefined;
  onSelect: (c: WalletItemData) => void;
  onAddPress: () => void;
};

export default observer(function WalletList(props: CurrencyListProps) {
  const {data, onSelect, onAddPress} = props;
  const styles = useStyles();
  return (
    <FlatList
      style={{
        borderWidth: 0,
        borderRadius: 0,
      }}
      data={data}
      renderItem={({item, index}) => (
        <WalletItem
          style={styles.walletItemStyle}
          bodyStyle={styles.walletItemBodyStyle}
          data={item}
          onPress={() => {
            onSelect(item);
          }}
          testID={`${index + 1}-coin-item`}
        />
      )}
      contentContainerStyle={data === undefined ? {} : styles.emptyList}
      ListEmptyComponent={
        data === undefined ? (
          <WalletListSkeleton />
        ) : (
          <EmptyWalletList onAddPress={onAddPress} />
        )
      }
      ItemSeparatorComponent={ListSeparator}
    />
  );
});

const useStyles = createStylesHook(theme => ({
  emptyList: {
    flex: 1,
  },
  walletItemStyle: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: theme.palette.uiSecondary,
  },
  walletItemBodyStyle: {
    backgroundColor: theme.palette.uiPrimary,
  },
}));

const ListSeparator = variance(View)(() => ({
  root: {
    height: 4,
    backgroundColor: 'none',
  },
}));
