import {useStrings, variance} from '@ncwallet-app/core';
import type {TotpActionOption} from '@ncwallet-app/core/src/NCWalletServer/AccountsTotpActions';
import {LG_BREAKPOINT, Switch} from '@ncwallet-app/ui';
import React from 'react';
import {Text, View} from 'react-native';

import {getLocaleKeyByTotpActionType} from './getLocaleKeyByTotpActionType';

interface ActionItemProps {
  action: TotpActionOption;
  onPress: () => void;
  last: boolean;
}

// eslint-disable-next-line import-x/prefer-default-export
export const TotpActionSwitch = (props: ActionItemProps) => {
  const strings = useStrings();
  const {action, last} = props;
  const textKey = getLocaleKeyByTotpActionType(action.type);
  return (
    <SwitchBlock last={last}>
      <SwitchText>{textKey ? strings[textKey] : ''}</SwitchText>
      <Switch
        active={action.enabled}
        onPress={props.onPress}
        testID={`${action.type}_2fa_switch`}
      />
    </SwitchBlock>
  );
};
const SwitchBlock = variance(View)(theme => ({
  root: {
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.uiSecondary,
    paddingVertical: 15,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingHorizontal: 20,
        paddingVertical: 10,
      },
    }),
  },
  last: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        borderBottomWidth: 0,
      },
    }),
  },
  sw: {
    marginHorizontal: 20,
    marginBottom: 20,
  },
}));
const SwitchText = variance(Text)(theme => ({
  root: {
    fontSize: 16,
    flex: 1,
    lineHeight: 20,
    ...theme.fontByWeight('600'),
    color: theme.palette.textPrimary,
  },
}));
