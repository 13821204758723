import {useLayoutEffect} from 'react';
import {Platform} from 'react-native';

export default function useGlobalWebNumberInput(
  handleNumberPress: (n: number) => void,
  handleBackspacePress: () => void,
) {
  useLayoutEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (/[0-9]/g.test(event.key)) {
        console.log('event.key', event.key);
        handleNumberPress(+event.key);
      }

      if (/Backspace/i.test(event.key)) {
        handleBackspacePress();
      }
    };

    if (Platform.OS === 'web') {
      window.addEventListener('keydown', handleKeyDown);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [handleNumberPress, handleBackspacePress]);
}
