import type {AnalyticRestParamsProvider} from '../AnalyticRestParamsProvider';
import {BaseRestClientImpl} from '../BaseRestClient';
import {CachedConsumableImpl} from '../CachedConsumable';
import type {Configuration} from '../Configuration';
import type {DeviceIdentification} from '../DeviceIdentification';
import type {ErrorRepository} from '../ErrorRepository';
import type {Http} from '../Http';
import type {Json} from '../Json';
import type {Url} from '../units';
import type {
  LauidProvider,
  LaunchApplicationUniqueIdResponse,
} from './LauidProvider';

class LauidProviderImpl extends BaseRestClientImpl implements LauidProvider {
  private readonly _link = new CachedConsumableImpl(() =>
    this._fetchLaunchApplicationUniqueId(),
  );

  constructor(
    protected readonly _root: {
      readonly configuration: Configuration;
      readonly json: Json;
      readonly deviceIdentification: DeviceIdentification;
      readonly errorRepository: ErrorRepository;
      readonly http: Http;
      readonly analyticRestParamsProvider: AnalyticRestParamsProvider;
    },
  ) {
    super(_root);
  }

  private async _fetchLaunchApplicationUniqueId(): Promise<LaunchApplicationUniqueIdResponse> {
    try {
      const params =
        await this._root.analyticRestParamsProvider.getRestParams();

      if (!params) {
        return null;
      }

      const searchParams = new URLSearchParams(params);

      const response = await this._call<LaunchApplicationUniqueIdResponse>(
        'POST',
        `api/v1/app_stats?${searchParams.toString()}` as Url,
        params,
      );

      if (!response.success) {
        return null;
      }

      return response.right && response.right.lauid ? response.right : null;
    } catch {
      return null;
    }
  }

  getLauid = async () => {
    return this._link.getCachedConsumable();
  };

  protected get _base() {
    return this._root.configuration.current.values.ncWalletRestApiUrl;
  }

  protected get _timeout() {
    return this._root.configuration.current.values.ncWalletRestApiTimeout;
  }
}

export default LauidProviderImpl;
