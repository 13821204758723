import {useTheme} from '@ncwallet-app/core';
import {TwoFaProviderKind} from '@ncwallet-app/core/src/TwoFaHelper';
import {TouchableOpacity} from '@ncwallet-app/ui';
import {ArrowLeftSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useRef} from 'react';

import {useNavigationGetIsFocused} from '../../Navigation/hooks';
import {
  BodyTag,
  HeaderTag,
  HeaderTitleTag,
} from '../ProfileSettingsScreen/LgProfileCards/useCardStyles';
import {
  CARD,
  SubmitOtpScreen,
} from '../UserSettingsScreens/SecurityTwoFaAuthScreen';

export type TwoFaCardSetCodeProps = {
  onBack?: () => void;
  getError: () => string | undefined;
  headerText?: string;
  titleText: string;
  submitText: string;
  currentTwoFaProvider?: TwoFaProviderKind | undefined;
  onSubmit: (code: string) => void;
  onTwoFaResendCode?: (withSecret?: boolean) => Promise<void>;
  resendCodeInterval?: number;
  resetInterval?: () => void;
  resetError: () => void;
};

export default observer(function TwoFaCardSetCode({
  onBack,
  getError,
  titleText,
  submitText,
  onSubmit,
  resetError,
  headerText,
  currentTwoFaProvider,
  onTwoFaResendCode,
  resetInterval,
  resendCodeInterval,
}: TwoFaCardSetCodeProps) {
  const theme = useTheme();
  const getIsFocused = useNavigationGetIsFocused();
  const once = useRef(false);

  useEffect(() => {
    if (getIsFocused()) {
      if (!once.current) {
        if (currentTwoFaProvider === TwoFaProviderKind.Telegram) {
          void onTwoFaResendCode?.();
          once.current = true;
        }
      }
    }
  }, [currentTwoFaProvider, onTwoFaResendCode, getIsFocused]);

  return (
    <>
      {headerText && onBack && (
        <HeaderTag>
          <TouchableOpacity
            onPress={() => {
              resetError();
              onBack();
            }}>
            <ArrowLeftSvg color={theme.palette.uiMain} />
          </TouchableOpacity>
          <HeaderTitleTag offsetRight>{headerText}</HeaderTitleTag>
        </HeaderTag>
      )}
      <BodyTag noPadding={!(headerText && onBack)}>
        <SubmitOtpScreen
          resetError={resetError}
          getError={getError}
          titleText={titleText}
          submitText={submitText}
          onSubmit={onSubmit}
          twoFaProvider={currentTwoFaProvider}
          onTwoFaResendCode={onTwoFaResendCode}
          resendCodeInterval={resendCodeInterval}
          resetInterval={resetInterval}
          layout={CARD}
        />
      </BodyTag>
    </>
  );
});
